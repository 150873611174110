import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageComponent } from 'src/app/message/message.component';
import { DashboardService } from 'src/app/service/dashboard.service';
import { RegistrationService } from 'src/app/service/registration.service';

@Component({
  selector: 'app-register-sibling-widget',
  templateUrl: './register-sibling-widget.component.html',
  styleUrls: ['./register-sibling-widget.component.scss']
})
export class RegisterSiblingWidgetComponent implements OnInit {
  schoolList: any[]=[];
  contactDetails: any;
  public addPaymentClass: boolean = false;
  public fileErrClass: boolean = false;
  token: any;
  regClosedMessage: any;

  constructor(public router:Router,public registerService:RegistrationService,public dashboardService:DashboardService, private dialog: MatDialog) { }

  ngOnInit(): void {

//     setTimeout(() => {    
    
//     var transportinfo= localStorage.transportChildInfo;
//   var studenttransinfo= JSON.parse(transportinfo).filter(a=>a.studentNo==localStorage.selectedstudentid);
// if(studenttransinfo)
// {

// this.regClosedMessage =  studenttransinfo[0].reG_MSG;

// }
// }, 1500);
    this.token = JSON.parse(localStorage.getItem('currentToken'))
   // this.regClosedMessage =  localStorage.getItem('regClosedMessage');
  
    this.dashboardService.isPaymentMessage.subscribe(res=>{
      this.addPaymentClass = res;
      this.dashboardService.fileErrMessage.subscribe(val=>{
       this.fileErrClass=val;
       
      });
    });

    if (localStorage.getItem('schoolList') === null) {
      this.registerService.getSchoolList(this.token?.access_token).subscribe(res => {
        this.schoolList = res;
        localStorage.setItem('schoolList', JSON.stringify(this.schoolList));
        // should have the selected student details.
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let school = this.schoolList.find(x => x.bsU_ID == currentUser?.schooId)
       // localStorage.setItem('bsU_ID', school?.bsU_ID ?currentUser?.schooId:);
       localStorage.setItem('bsU_ID', currentUser?.schooId);
       // localStorage.setItem("schoolName", school?.schoolName)
       localStorage.setItem("schoolName", currentUser?.schoolName)
        localStorage.bsu_currency = school?.bsu_currency && school?.bsu_currency != null && school?.bsu_currency != undefined ? school?.bsu_currency : "AED"
        let schoolDetails = {
          city: school?.city,
          bsu_city: school?.citycode,
          provider: school?.provider,
          country: school?.country,
          currency: school?.bsU_CURRENCY
        }
        localStorage.setItem('schoolDetails', JSON.stringify(schoolDetails));
      });
    }
  }

registerNow()
{
  if(!(this.regClosedMessage != null && this.regClosedMessage != undefined && this.regClosedMessage != "")){
    this.dashboardService.getContactDetails('').subscribe((res:any)=>{
      this.contactDetails = res?.data;
      this.registerService.getParentsList(this.contactDetails?.primaryContactNumber, this.token).subscribe(res=>{
        let parentDetails= res?.data;
        localStorage.removeItem('photopath')
    localStorage.removeItem('studentDetails');
    localStorage.setItem('parentDetails',JSON.stringify(parentDetails));
    localStorage.setItem('studParentDetails',JSON.stringify(parentDetails));
    this.registerService.sendStudentDetails.next(parentDetails);
    localStorage.setItem("proceed","false");
    localStorage.setItem("showSchool","true")
    this.router.navigate(['/schoolbus-registration'],{queryParams:{isFromDashboard:true,tab:1}});
    })
  }) 
  }else{
    this.openMessageDialog(this.regClosedMessage,true,'');
  }
}

openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
  const dialogRef = this.dialog.open(MessageComponent, {
    width: '500px',
    panelClass: 'custom-modalbox',
    data: {
      msg: msg,
      isError: iseror,
      errmessage: errormessage,
    },
  });

  dialogRef.afterClosed().subscribe(result => {
  });
}
}
