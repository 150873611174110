import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NotificationRoutingModule } from './notification-routing.module';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NotificationAlertsComponent } from './notification-alerts/notification-alerts.component';
import { NotificationStarredComponent } from './notification-starred/notification-starred.component';
import { NotificationMessagesComponent } from './notification-messages/notification-messages.component';
import { NotificationComponent } from './notification.component';
import {MatTabsModule} from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
@NgModule({
  declarations: [
    NotificationAlertsComponent,
    NotificationStarredComponent,
    NotificationMessagesComponent,
    NotificationComponent
  ],
  imports: [
    CommonModule,
    NotificationRoutingModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule, MatInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, 'assets/i18n/', '.json'),
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    })
  ],
  exports: [[NotificationRoutingModule],],providers:[DatePipe]
})
export class NotificationModule { }
