import { Component, OnInit, TemplateRef, Renderer2,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  MatDialog, MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from 'src/app/service/registration.service';
import { TokenService } from 'src/app/service/token.service';
import { encryptionService } from 'src/app/service/encryptionService.service';

@Component({
  selector: 'app-schools-list',
  templateUrl: './schools-list.component.html',
  styleUrls: ['./schools-list.component.scss']
})
export class SchoolsListComponent implements OnInit {
  searchText: any;
  digopen: any;
  display: any;
  center: google.maps.LatLngLiteral = {
    lat: 25.276987,
    lng: 55.296249
  };
  zoom = 17;
  schoolList:any[]=[];
  dialogBox:any
  markerOptions: google.maps.MarkerOptions = {
        draggable: false
    };
  mapOption={
    disableDefaultUI: true
  }
  defaultToken: any;
  tempSchoolList:any[]=[];
  selectedSchool:any;
  isEmpty:boolean=false;
  filterSchoolId:any;
  filterCity:any;
  openchatBot: boolean=false;
    constructor(private dialog: MatDialog, private router: Router, public registerService: RegistrationService,private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,private tokenService: TokenService,private activatedRoute:ActivatedRoute, private encryptionService: encryptionService) { }

  ngOnInit(): void {
    let lang = localStorage.getItem("lang") != null ? localStorage.getItem('lang') : 'en';
    let direction = lang == "en" ? 'ltr' : 'rtl';
    document.body.dir = direction;

    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
  });
    this.renderer.addClass(this.document.body, 'rm-body-height');
    var root = document.getElementsByTagName( 'html' )[0];
    this.renderer.addClass(root, 'rm-body-height');
    localStorage.removeItem('parentDetails');
    localStorage.removeItem('bsU_ID');
    localStorage.removeItem('studParentDetails');
    localStorage.removeItem('studentDetails');
    localStorage.removeItem('disableIds');
    localStorage.removeItem('termsConditions');
   
    this.activatedRoute.queryParams.subscribe(params=>{
      if(Object.keys(params).length == 0){
        this.getToken();
      }else{
        if(params?.schoolid || params?.city)
        {
          if(params?.schoolid){
            let schoolId = params.schoolid;
            if(schoolId.includes(' ')){
              schoolId = schoolId.replace(' ', '+');
            }
            this.filterSchoolId = this.encryptionService.decryptDES(schoolId);
          }else {
            this.filterCity = this.encryptionService.decryptDES(params.city);
          }
          this.getToken();
        }else {
          this.schoolList = [];
          this.tempSchoolList = [];
          this.isEmpty = true;
        }
      }
    })
  }

  getToken() {
    this.tokenService.GetToken(null).subscribe(
      res => {
        this.defaultToken = res?.access_token;
      this.getSchoolList();
      }
    )
  }

  getSchoolList()
  {
    this.registerService.getSchoolList(this.defaultToken).subscribe(res=>{
    this.schoolList=res;
    
    if(this.filterSchoolId != null){
      this.schoolList = this.schoolList.filter((x:any) => x.bsU_ID == this.filterSchoolId);
    }else if(this.filterCity != null){
      this.schoolList = this.schoolList.filter((x:any) => x.city?.toLowerCase() == this.filterCity.toLowerCase());
    }

    this.isEmpty= this.schoolList.length==0?true:false;
    this.tempSchoolList = [...this.schoolList];
    localStorage.setItem('schoolList',JSON.stringify(this.tempSchoolList));
    localStorage.setItem("proceed","false");
    localStorage.setItem("showSchool","false")
    this.schoolList.forEach(x=>{
      this.markerPositions.push({lat:x?.latitude,lng:x?.longitude})
    })
    })
  }

  searchSchools(search:any)
  {
   if(search?.value.length >1 || search?.value.length ==0)
   {
    if(search?.value.length >1)
    {
       this.schoolList=this.tempSchoolList.filter(x=>x.schoolName.toLowerCase().includes(search?.value.toLowerCase()))
    }else{
       this.schoolList=this.tempSchoolList;
    }
   }else{
    this.schoolList=this.tempSchoolList;
  }
  this.isEmpty= this.schoolList.length==0?true:false;
}

markerPositions: google.maps.LatLngLiteral[] = [];
// addMarker(event: google.maps.MapMouseEvent) {
//     if (event.latLng != null) this.markerPositions.push(event.latLng.toJSON());
// }
  Later(type:any) {
   this.dialogBox.close();
   if(type == 'registerDialog')
   this.router.navigate(['/']);
  }
  continue(type:any,template?: TemplateRef<any>)
  {
     this.dialogBox.close();
    // if(type=='selectedDialog')
    // {
    //   this.resiterDialog(template);
    // }
   //else
   this.router.navigate(['/schoolbus-registration']);
  }

  resiterDialog(template: TemplateRef<any>) {
   this.dialogBox= this.dialog.open(template, {
      disableClose: true,
      width: '700px',
      panelClass: 'custom-modalbox'
    });
  }
  selectedSchoolDialog(template: TemplateRef<any>,selectedSchool:any){
    localStorage.setItem('bsU_ID', selectedSchool.bsU_ID);
    localStorage.setItem('schoolName', selectedSchool.schoolName);
    localStorage.setItem('ishybrid',selectedSchool.ishybrid);
    localStorage.setItem('isLeased',selectedSchool.isLeasedSchool);
    let schoolDetails ={
      city:selectedSchool.city,
      bsu_city:selectedSchool.citycode,
      provider:selectedSchool.provider,
      country:selectedSchool.country,
      currency:selectedSchool?.bsU_CURRENCY
    }
    localStorage.setItem('schoolDetails', JSON.stringify(schoolDetails));
    this.selectedSchool=selectedSchool;
   this.dialogBox= this.dialog.open(template, {
      disableClose: true,
      width: '700px',
      panelClass: 'custom-modalbox'
    });
  }
  goToLanding(){
    this.router.navigate(['/login']);
  }
  openDialog(template: TemplateRef<any>) {
    this.digopen = this.dialog.open(template, {
      disableClose: true,
      width: '380px',
      panelClass: ['custom-modalbox', 'searchPopup']
    });
    if (this.digopen['_overlayRef'].overlayElement) {
      this.digopen['_overlayRef'].overlayElement.parentElement.className += ' search-wrapper';
    }
  }

  openChatBot(){
    this.openchatBot = !this.openchatBot;
  }
}
