import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './common-components/breadcrumb/breadcrumb.component';
import { ChatModule } from '@progress/kendo-angular-conversational-ui';
import { ChatbotComponent } from '../features/chatbot/chatbot.component';
import { ServiceAvailabilityFormComponent } from '../features/chatbot/service-availability-form/service-availability-form.component';
import { RecoverLoginFormComponent } from '../features/chatbot/recover-login-form/recover-login-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MessageService } from '@progress/kendo-angular-l10n';
import { interval } from 'rxjs';
import { LanguageTranslateService } from '../service/translatelanguageservice.service';



@NgModule({
  declarations: [
    BreadcrumbComponent,
    ChatbotComponent,
    ServiceAvailabilityFormComponent,
    RecoverLoginFormComponent
  ],
  imports: [
    CommonModule,
    ChatModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    NgxMatIntlTelInputModule,    
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, 'assets/i18n/', '.json'),
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    })
  ],
  providers:[TranslateService,MessageService],
  exports:[
    CommonModule,
    ChatbotComponent,
    ServiceAvailabilityFormComponent,
    RecoverLoginFormComponent
  ]
})
export class CommonModulesModule {
  constructor(private messages: MessageService,private translate:TranslateService){
    
    translate.addLangs(['en', 'ar']);
    interval(1000).subscribe(() => {
      let currentLanguage:any = localStorage.getItem('lang');
      currentLanguage?translate.use(currentLanguage):translate.setDefaultLang('en');
      const svc: any = <LanguageTranslateService>this.messages;
      svc.language = currentLanguage?currentLanguage:'en';
    });
  }
 }
