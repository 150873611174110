import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import {
  MatDialog, MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { RegistrationService } from 'src/app/service/registration.service';
import { TokenService } from 'src/app/service/token.service';
import * as CryptoJS from 'crypto-js';
import { OtherDetails, PrimaryDetails, SecondaryDetails } from 'src/app/models/registration.model';
import { MessageComponent } from 'src/app/message/message.component';
import { Form, Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { FileService } from 'src/app/service/file.service';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { ConfigService } from 'src/app/service/config.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-parent-information',
  templateUrl: './parent-information.component.html',
  styleUrls: ['./parent-information.component.scss']
})
export class ParentInformationComponent implements OnInit {
  checked = false;
  token: any;
  IsMobileVerified: boolean = false;
  enableOTP: boolean = false;
  interval: any;
  countdown: number = 0;
  isResendOtp: boolean = false;
  isInvalidOTP: boolean = false;
  encOTP: any;
  enteredOTP: any = '';
  isOtpEntered: boolean = false;
  IsEmailMatch: boolean = false;
  studParentDetails: any;
  isOtherRelationship: boolean = false;
  isOtherFullName: boolean = false;
  isOtherMobile: boolean = false;
  studentDialog: any
  statusDialog: any
  @Output() onTabChange: EventEmitter<any> = new EventEmitter();
  details: any;
  primaryRel: any = '';
  secondaryRel: any = '';
  guardianRel: any = '';
  selectedCountries: string;
  disabledContact: boolean=true;
  nationalnumber: any;
  isVerifyOTP: boolean=false;
  count: any=0;
  resendcount: any=0;
  isUAENoValidPrimary: boolean=false;
  isUAENoValidOther: boolean=false;
  isUAENoValidSecondary: boolean=false;
  hasLoginUser:boolean=false;
  @ViewChild('editStudentInfo')editStudent:TemplateRef<any>;
  @ViewChild('statusMsg')statusMsg:TemplateRef<any>;
  parentDetailResponse: any[]=[];
  disableAddSibling: boolean=false;
  studentStatus: any;
  showloader:boolean=false;
  @Input() isReactivate: boolean = false;
  successfullyVerifiedMsg: string = "Mobile number successfully verified.";
  proceedBtn: string = 'Proceed';
  requestSavedSuccessfully: string = "Request saved successfully.";

  constructor(private dialog: MatDialog, private registrationService: RegistrationService, private tokenService: TokenService, private router: Router
    ,private fileService: FileService, private configService: ConfigService, private translate: TranslateService) { }

  @ViewChild("otp1") otp1!: ElementRef;
  @ViewChild("otp2") otp2!: ElementRef;
  @ViewChild("otp3") otp3!: ElementRef;
  @ViewChild("otp4") otp4!: ElementRef;
  @ViewChild("otp5") otp5!: ElementRef;
  @ViewChild("otp6") otp6!: ElementRef;
  @ViewChild('primaryMobile') primaryMobile: NgxMatIntlTelInputComponent;
  @ViewChild('secondaryMobile') secondaryMobile: NgxMatIntlTelInputComponent;
  @ViewChild('otherMobile') otherMobile: NgxMatIntlTelInputComponent;

  public otpValue1: any = '';
  public otpValue2: any = '';
  public otpValue3: any = '';
  public otpValue4: any = '';
  public otpValue5: any = '';
  public otpValue6: any = '';

  public primaryDetails: FormGroup;
  public secondaryDetails: SecondaryDetails = new SecondaryDetails();
  public otherDetails: OtherDetails = new OtherDetails();
  public countries=['ae', 'qa']


  ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    }, 500);

    let schooldata:any;
    schooldata = JSON.parse(localStorage.getItem('schoolDetails'));
    console.log(schooldata);
    let loginUser = localStorage.getItem("loginuser");
    this.hasLoginUser=loginUser?true:false
if(schooldata.country == "UAE")  {
  this.selectedCountries = this.countries[0]
}  else{
  this.selectedCountries = this.countries[1]
}

this.getToken();

    this.primaryDetails = new FormGroup({
      primaryRelationShip: new FormControl('', Validators.required),
      primaryFname: new FormControl('', Validators.required),
      primaryMname: new FormControl(''),
      primaryLname: new FormControl('', Validators.required),
      primaryMobile: new FormControl('', [Validators.required]),
      primaryEmail: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]),
      primaryReEmail: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]),
      secondaryRelationShip: new FormControl('', Validators.required),
      secondaryFname: new FormControl('', Validators.required),
      secondaryMname: new FormControl(''),
      secondaryLname: new FormControl('', Validators.required),
      secondaryMobile: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(13)]),
      otherRelationShip: new FormControl(''),
      otherFullName: new FormControl(''),
      otherMobile: new FormControl(''),
      otpValue1: new FormControl(''),
      otpValue2: new FormControl(''),
      otpValue3: new FormControl(''),
      otpValue4: new FormControl(''),
      otpValue5: new FormControl(''),
      otpValue6: new FormControl(''),
    });

    this.translate.stream('registration').subscribe(async (data: any) => {
      if (data != null && data != undefined) {
        this.successfullyVerifiedMsg = data.label.successfullyVerifiedMsg;
        this.proceedBtn = data.label.proceed;
        this.requestSavedSuccessfully = data.label.requestSavedSuccessfully;
      }
    });
  }

  // editInfoDialog(template: TemplateRef<any>) {
  //  this.studentDialog= this.dialog.open(template, {
  //     disableClose: true,
  //     width: '510px',
  //     panelClass: 'custom-modalbox'
  //   });
  // }
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getToken() {
    this.tokenService.GetToken(null).subscribe(
      res => {
        this.token = res?.access_token;
        let data: any = '';
        data = JSON.parse(localStorage.getItem('parentDetails'));
        if (data != '' && data != null) {
          this.phone = data?.MOBILEPHONE;
          this.IsMobileVerified = true;
          // this.verifyOTP()
          this.gotoPrevious()
        } else {
          this.IsMobileVerified = false;
          this.primaryDetails = new FormGroup({
            primaryRelationShip: new FormControl('', Validators.required),
            primaryFname: new FormControl('', Validators.required),
            primaryMname: new FormControl(''),
            primaryLname: new FormControl('', Validators.required),
            primaryMobile: new FormControl('', [Validators.required]),
            primaryEmail: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]),
            primaryReEmail: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]),
            secondaryRelationShip: new FormControl('', Validators.required),
            secondaryFname: new FormControl('', Validators.required),
            secondaryMname: new FormControl(''),
            secondaryLname: new FormControl('', Validators.required),
            secondaryMobile: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(13)]),
            otherRelationShip: new FormControl(''),
            otherFullName: new FormControl(''),
            otherMobile: new FormControl(''),
            otpValue1: new FormControl(''),
            otpValue2: new FormControl(''),
            otpValue3: new FormControl(''),
            otpValue4: new FormControl(''),
            otpValue5: new FormControl(''),
            otpValue6: new FormControl(''),
          });
        }
      }
    )
  }
  addSibling()
  {
    this.studentDialog.close();
    setTimeout(() => {
      // alert('scrolled up')
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 200);
    this.gotoPrevious();
    localStorage.setItem("addSiblingFromParent","true")
    let data: any;
    data = JSON.parse(localStorage.getItem('parentDetails'))
    let index:any
          if (!this.hasLoginUser) {
            index = data?.length == 1 ? 0 : data.findIndex((x: any) => x.disableRegistration == 0)
            index = index == -1 ? 0 : index;
          }
          else {
            index = data?.length == 1 ? 0 : data.findIndex((x: any) => x.transportStatus == "Service is already Active")
            index = index == -1 ? 0 : index;
          }
    let studentData={
      "stU_ID": 0,
      "stU_FIRSTNAME":  '',
      "stU_MIDNAME": '',
      "stU_LASTNAME": '',
      "stU_GENDER":  '',
      "stU_GRD_ID":  '',
      "stU_GRM_ID": 0,
      "stU_DOB": '',
      "stU_SCHOOL_ID":  '',
      "stU_PHOTOPATH":  '',
      "stS_MED":  0,
      "stU_MED_CONDITION":  '',
      "stS_MED_STAFF_REQUIRED":  0,
      "stS_MED_STAFF_REQUIRED_DESC": '',
      "stU_PRIMARYCONTACT": this.primaryDetails.value.primaryRelationShip,
      "stS_FFIRSTNAME":this.primaryDetails.value.primaryRelationShip=="F"?this.primaryDetails.value.primaryFname:this.primaryDetails.value.secondaryFname,
      "stS_FMIDNAME": this.primaryDetails.value.primaryRelationShip=="F"?this.primaryDetails.value.primaryMname:this.primaryDetails.value.secondaryMname,
      "stS_FLASTNAME":this.primaryDetails.value.primaryRelationShip=="F"?this.primaryDetails.value.primaryLname:this.primaryDetails.value.secondaryLname,
      "stS_FMOBILE": this.primaryDetails.value.primaryRelationShip=="F"?this.phone:this.primaryDetails.value.secondaryMobile,
      "stS_FEMAIL": this.primaryDetails.value.primaryRelationShip=="F"?this.primaryDetails.value.primaryEmail:'',
      "stS_MFIRSTNAME": this.primaryDetails.value.primaryRelationShip=="M"?this.primaryDetails.value.primaryFname:this.primaryDetails.value.secondaryFname,
      "stS_MMIDNAME": this.primaryDetails.value.primaryRelationShip=="M"?this.primaryDetails.value.primaryMname:this.primaryDetails.value.secondaryMname,
      "stS_MLASTNAME": this.primaryDetails.value.primaryRelationShip=="M"?this.primaryDetails.value.primaryLname:this.primaryDetails.value.secondaryLname,
      "stS_MMOBILE": this.primaryDetails.value.primaryRelationShip=="M"?this.phone:this.primaryDetails.value.secondaryMobile,
      "stS_MEMAIL": this.primaryDetails.value.primaryRelationShip=="M"?this.primaryDetails.value.primaryEmail:'',
      "stS_GFULLNAME": this.primaryDetails.value.otherFullName,
      "stS_GMOBILE": this.primaryDetails.value.otherMobile,
      "stS_GEMAIL": this.primaryDetails.value.primaryRelationShip=="G"?this.primaryDetails.value.primaryEmail:'',
      "stU_SECONDARY_CONTACT":this.primaryDetails.value.secondaryRelationShip,
      "disableRegistration": 0,
      "city": data?.city ?? '',
      "house": data?.house ?? '',
      "street": data?.street ?? '',
      "transportType": data?.transportType ?? '',
      "pickupArea": data?.pickupArea ?? '',
      "pickupPoint": data?.pickupPoint ?? '',
      "pickupPointLandMark": data?.pickupPointLandMark ?? '',
      "dropoffArea": data?.dropoffArea ?? '',
      "dropoffPoint": data?.dropoffPoint ?? '',
      "dropoffPointLandMark": data?.dropoffPointLandMark ?? '',
      "busServiceRequestedDate": data?.busServiceRequestedDate ?? '',
      "picklat": data?.picklat ?? 0,
      "picklog": data?.picklog ?? 0,
      "droplat": data?.droplog ?? 0,
      "droplog": data?.droplog ?? 0,
      "stU_BSU_ID":data?.stU_BSU_ID?? '',
      "SBL_ID_DROPOFF":data?.DROPOFFAREA ?? 0,
      "PNT_ID_DROPOFFPOINT":data?.DROPOFFPOINT?? 0,

    }
    let json=JSON.stringify(studentData)
    localStorage.setItem('studentDetails',json);
    this.sendStudentDetails(this.studParentDetails);
  }

  editInfoDialog(template: TemplateRef<any>) {
    this.studentDialog= this.dialog.open(template, {
       disableClose: true,
       width: '575px',
       panelClass: 'custom-modalbox'
     });
   }

   msgDialog(template: TemplateRef<any>) {
    this.statusDialog= this.dialog.open(template, {
       disableClose: true,
       width: '575px',
       panelClass: 'custom-modalbox'
     });
   }

  getNumber(event) {

    this.phone = event;


  }
  sendotp() {

    if (this.primaryDetails.get('primaryMobile').valid && !this.isUAENoValidPrimary) {

      this.verifyMobile(this.phone, this.token, event);

    }
  }
  phone: string = '';
  verifyMobile(data: any, token: any, event: any) {
    this.registrationService.getOTPfromMobile(data,"REGISTRATION", token).subscribe(
      res => {
        this.phone = data;
        if (res?.success == 'true') {

          this.enableOTP = true;
          clearInterval(this.interval);
          this.isResendOtp = false;
          this.countdown = this.configService.otpTimerExpiry;
          this.startCountdown(data);
          this.encOTP = res?.data[0].otp
        } else {
          this.IsMobileVerified = false;
          this.enableOTP = false;
        }
      }
    )
  }
  keytab(event: any, value: any) {
    this.otpValue1 = this.primaryDetails.get('otpValue1').value;
    this.otpValue2 = this.primaryDetails.get('otpValue2').value;
    this.otpValue3 = this.primaryDetails.get('otpValue3').value;
    this.otpValue4 = this.primaryDetails.get('otpValue4').value;
    this.otpValue5 = this.primaryDetails.get('otpValue5').value;
    this.otpValue6 = this.primaryDetails.get('otpValue6').value;
    //this.isInvalidOTP=false;
    if (event.code == 'Backspace' || event.code == undefined || event.code == 'Delete') {
      if (event.srcElement.name == 'otpValue1') {
        this.otp1.nativeElement.focus();
        if (event.code == undefined && this.otpValue1 == "")
          this.otpValue1 = '';
      }
      if (event.srcElement.name == 'otpValue2') {
        this.otp2.nativeElement.focus();
      }
      if (event.srcElement.name == 'otpValue3') {
        this.otp3.nativeElement.focus();
      }
      if (event.srcElement.name == 'otpValue4') {
        this.otp4.nativeElement.focus();
      }
      if (event.srcElement.name == 'otpValue5') {
        this.otp5.nativeElement.focus();
      }
      if (event.srcElement.name == 'otpValue6') {
        this.otp6.nativeElement.focus();
      }
    }
    else {
      if (value == 1) {
        if (this.otpValue1 != '') {
          this.otp2.nativeElement.focus();
        }

      }

      if (value == 2) {
        if (this.otpValue2 != '') {
          this.otp3.nativeElement.focus();
        }
      }

      if (value == 3) {
        if (this.otpValue3 != '') {
          this.otp4.nativeElement.focus();
        }
      }
      if (value == 4) {
        if (this.otpValue4 != '') {
          this.otp5.nativeElement.focus();
        }
      }
      if (value == 5) {
        if (this.otpValue5 != '') {
          this.otp6.nativeElement.focus();
        }
      }
      if (value == 6) {
        if (this.otpValue6 != '') {
          this.otp6.nativeElement.focus();
        }
      }
    }

  }
  private startCountdown(event: any) {

    this.isResendOtp = false;
    this.countdown = this.configService.otpTimerExpiry; // 60 seconds
    this.interval = setInterval(() => {
      if (this.countdown === 0) {
        clearInterval(this.interval);
        this.isResendOtp = true;
        this.isVerifyOTP = true;
        if(this.resendcount > 2){
          this.isResendOtp = false
        }else{
          this.isResendOtp = true

        }
      } else {
        this.countdown -= 1;

        this.isResendOtp = false;
        if(this.count > 2){
          this.isVerifyOTP = true;
          clearInterval(this.interval);
          this.countdown = 0;
          this.isResendOtp = true;
          if(this.resendcount > 2){
            this.isResendOtp = false
          }else{
            this.isResendOtp = true

          }
        }else{
          this.isVerifyOTP = false;

        }
        //event.preventDefault();
      }
    }, 1000); // Update every second
  }
  reSend(event) {
    this.resendcount++;
    if(this.resendcount > 2){
      this.isResendOtp = false
    }else{
      this.isResendOtp = true

    }
    this.verifyMobile(this.primaryDetails.get('primaryMobile').value, this.token, event);
    this.isInvalidOTP = false;
    this.countdown = 0;
    clearInterval(this.interval);
    this.countdown = this.configService.otpTimerExpiry;
    this.startCountdown(event);
    this.otpValue1 = "";
    this.otpValue2 = ""
    this.otpValue3 = "";
    this.otpValue4 = "";
    this.otpValue5 = "";
    this.otpValue6 = "";
    this.count = 0;
    this.isVerifyOTP = false;

    // this.primaryDetails.setValue({
    //   otpValue1: new FormControl(''),
    //   otpValue2: new FormControl(''),
    //   otpValue3: new FormControl(''),
    //   otpValue4: new FormControl(''),
    //   otpValue5: new FormControl(''),
    //   otpValue6: new FormControl(''),
    // });
    this.primaryDetails.get('otpValue1').reset();
    this.primaryDetails.get('otpValue2').reset();
    this.primaryDetails.get('otpValue3').reset();
    this.primaryDetails.get('otpValue4').reset();
    this.primaryDetails.get('otpValue5').reset();
    this.primaryDetails.get('otpValue6').reset();
  }
  getEnteredOTP() {

    if (this.otpValue1 != '' && this.otpValue2 != '' && this.otpValue3 != '' && this.otpValue4 != '' && this.otpValue5 != '' && this.otpValue6 != '') {
      this.enteredOTP = this.otpValue1 + this.otpValue2 + this.otpValue3 + this.otpValue4 + this.otpValue5 + this.otpValue6;
      this.isOtpEntered = false;
      this.isInvalidOTP = false;
    } else {
      this.isOtpEntered = true;
      this.isInvalidOTP = false;

    }
  }
  sha512Enc(val: string) {
    const hash = CryptoJS.SHA512(val);
    return hash.toString(CryptoJS.enc.Hex);
  }
  verifyOTP() {
    this.showloader=true
    this.fileService.clearFile();
    let otp: any = '';
    let data: any = '';
    data = JSON.parse(localStorage.getItem('parentDetails'));
    if (data == '' || data == undefined) {
      this.getEnteredOTP();
      let schooldata:any;
      schooldata = JSON.parse(localStorage.getItem('schoolDetails'));
      console.log(schooldata);
      this.selectedCountries=''
  if(schooldata.country == "UAE")  {
    this.selectedCountries = this.countries[0]
  }  else{
    this.selectedCountries = this.countries[1]
  }
      otp = this.sha512Enc(this.enteredOTP);
      if (this.encOTP == otp) {
        this.isInvalidOTP = false;
        this.disabledContact = false
        localStorage.setItem('primaryMobileNo', this.primaryDetails.get('primaryMobile').value)
        this.getParentDEtails()
      } else {
        this.isInvalidOTP = true;
        this.showloader=false
        this.count++;
        if(this.count > 2){
          this.isVerifyOTP = true
        }else{
          this.isVerifyOTP = false
        }
        this.disabledContact = false

      }

    } else {

      this.getParentDEtails()
    }



  }
  getParentDEtails() {

    this.registrationService.getParentsList(this.phone, this.token).subscribe(
      res => {
        if (res?.success == 'true') {
          this.IsMobileVerified = true;
          this.enableOTP = false;
          this.showloader=false;
          this.parentDetailResponse=res?.data;
          this.openMessageDialog(this.successfullyVerifiedMsg, false, "",'mobile',true);
        } else {
          this.IsMobileVerified = false;
          this.showloader=false;
        }

      }
    )
  }

  setParentDetails()
  {
      // let data: any = {};
          // let index= res?.data.length == 1 ? 0 : res?.data.findIndex((x:any)=>x.disableRegistration == 0)
          // index = index == -1? 0:index;
          //data = res?.data[index];
    let data = JSON.parse(localStorage.getItem('studentDetails'));
    this.studParentDetails =[... this.parentDetailResponse];
    this.studParentDetails.forEach(x => {
      x=>x.isClicked = false;
    });
    localStorage.setItem('studParentDetails', JSON.stringify(this.studParentDetails))
    localStorage.setItem('parentDetails', JSON.stringify((this.parentDetailResponse)))



    if (data) {
      this.setFormFields(data)
    } else {
     //let index= this.studParentDetails.findIndex(x=>x.disableRegistration == 0 && !x.transportMessage)
     let index= this.studParentDetails.findIndex(x=>x.isPartial == 1);
     this.disableAddSibling = index == -1 ? false :true
      this.primaryDetails = new FormGroup({
        primaryRelationShip: new FormControl('', Validators.required),
        primaryFname: new FormControl('', Validators.required),
        primaryMname: new FormControl(''),
        primaryLname: new FormControl('', Validators.required),
        primaryMobile: new FormControl(this.phone, [Validators.required]),
        primaryEmail: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]),
        primaryReEmail: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]),
        secondaryRelationShip: new FormControl('', Validators.required),
        secondaryFname: new FormControl('', Validators.required),
        secondaryMname: new FormControl(''),
        secondaryLname: new FormControl('', Validators.required),
        secondaryMobile: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(13)]),
        otherRelationShip: new FormControl(''),
        otherFullName: new FormControl(''),
        otherMobile: new FormControl(''),
        otpValue1: new FormControl(this.otpValue1),
        otpValue2: new FormControl(this.otpValue2),
        otpValue3: new FormControl(this.otpValue3),
        otpValue4: new FormControl(this.otpValue4),
        otpValue5: new FormControl(this.otpValue5),
        otpValue6: new FormControl(this.otpValue6),
      });
      if(this.studParentDetails.length > 0)
      this.editInfoDialog(this.editStudent)
    }

  }

  setFormFields(data:any)
  {
            let other=''
            let fullName=''
            let othermobile=''
            if((data?.stU_PRIMARYCONTACT == "F" && data?.stU_SECONDARY_CONTACT == "M") || (data?.stU_PRIMARYCONTACT == "M" && data?.stU_SECONDARY_CONTACT == "F"))
            {
            other ="G";
            fullName=data?.stS_GFULLNAME
            othermobile=data?.stS_GMOBILE
            }
            else if((data?.stU_PRIMARYCONTACT == "F" && data?.stU_SECONDARY_CONTACT == "G") || (data?.stU_PRIMARYCONTACT == "G" && data?.stU_SECONDARY_CONTACT == "F"))
            {
            other ="M"
            fullName=data?.stS_MFIRSTNAME+' '+data?.stS_MMIDNAME+' '+data?.stS_MLASTNAME
            othermobile=data?.stS_MMOBILE
            }
            else if((data?.stU_PRIMARYCONTACT == "M" && data?.stU_SECONDARY_CONTACT == "G") || (data?.stU_PRIMARYCONTACT == "G" && data?.stU_SECONDARY_CONTACT == "M"))
            {
            other ="F"
            fullName=data?.stS_FFIRSTNAME+' '+data?.stS_FMIDNAME+' '+data?.stS_FLASTNAME
            othermobile=data?.stS_FMOBILE
            }

            let otherName =data?.stS_GFULLNAME?.split(' ')
    this.primaryDetails.patchValue({
      primaryRelationShip: data?.stU_PRIMARYCONTACT,
      primaryFname:data?.stU_PRIMARYCONTACT == "F" ? data?.stS_FFIRSTNAME : data?.stU_PRIMARYCONTACT == "M" ? data?.stS_MFIRSTNAME : otherName[0],
      primaryMname:data?.stU_PRIMARYCONTACT == "F" ? data?.stS_FMIDNAME : data?.stU_PRIMARYCONTACT == "M"?data?.stS_MMIDNAME: otherName.length > 2 ? otherName[1]:'',
      primaryLname:data?.stU_PRIMARYCONTACT == "F" ? data?.stS_FLASTNAME :data?.stU_PRIMARYCONTACT == "M"?data?.stS_MLASTNAME :otherName.length == 2 ? otherName[1]:otherName.length > 2 ? otherName[2]:otherName[0],
      primaryMobile: this.phone,
      primaryEmail: data?.stU_PRIMARYCONTACT == "F" ? data.stS_FEMAIL : data?.stU_PRIMARYCONTACT == "M"? data?.stS_MEMAIL:data?.stS_GEMAIL,
      primaryReEmail:data?.stU_PRIMARYCONTACT == "F" ? data.stS_FEMAIL :data?.stU_PRIMARYCONTACT == "M"? data?.stS_MEMAIL:data?.stS_GEMAIL,
      secondaryRelationShip: data?.stU_SECONDARY_CONTACT,
      secondaryFname: data?.stU_SECONDARY_CONTACT == "M" ?  data?.stS_MFIRSTNAME:data?.stU_SECONDARY_CONTACT == "F"?data?.stS_FFIRSTNAME: otherName[0] ,
      secondaryMname: data?.stU_SECONDARY_CONTACT == "M" ?  data?.stS_MMIDNAME:data?.stU_SECONDARY_CONTACT == "F"?data?.stS_FMIDNAME:otherName.length > 2 ? otherName[1]:'',
      secondaryLname: data?.stU_SECONDARY_CONTACT == "M" ?  data?.stS_MLASTNAME:data?.stU_SECONDARY_CONTACT == "F"?data?.stS_FLASTNAME:otherName.length == 2 ? otherName[1]:otherName.length > 2 ? otherName[2]:otherName[0],
      secondaryMobile:data?.stU_SECONDARY_CONTACT == "M" ? data?.stS_MMOBILE:data?.stU_SECONDARY_CONTACT == "F"?data?.stS_FMOBILE:data?.stS_GMOBILE,
      otherRelationShip: other ,
      otherFullName: fullName,
      otherMobile: othermobile
    })
    this.checkRelationship( this.primaryDetails.value.primaryRelationShip, 'pRel')
    this.checkRelationship( this.primaryDetails.value.secondaryRelationShip, 'sRel')

    if (data?.stS_GFULLNAME == '') {
      this.checked = false;
      this.primaryDetails.value.otherRelationShip = ''

    } else {
      this.checked = true;
      this.isOtherFullName = data?.stS_GFULLNAME == "" ? true : false;
      this.isOtherMobile = data?.stS_GMOBILE == "" ? true : false;
      this.isOtherRelationship = data?.otherRelationShip == "" ? true : false;
      this.checkRelationship(this.primaryDetails.value.otherRelationShip, 'gRel')

    }

  }
  selectStudent(student:any)
  {
    let studentList:any[]=[];
    studentList=this.studParentDetails;
    studentList.forEach(x=>{
      let id= x.stU_ID ? x.stU_ID:x.stU_SCHOOL_ID
      let compareId= x.stU_ID ?student.stU_ID : student.stU_SCHOOL_ID
      if(id==compareId)
      x.isClicked=true;
      else x.isClicked=false;
    })
    this.studParentDetails=[...studentList];
    setTimeout(() => {
      this.studentDialog.close();
    }, 400);
    if(student.disableRegistration == 0 && student.transportMessage)
    {
       this.studentStatus=student.transportMessage;
       this.msgDialog(this.statusMsg);
    }else{
    this.setFormFields(student)
    localStorage.setItem('photopath',student.stU_PHOTOPATH)
    let jsonString=JSON.stringify(student);
    localStorage.setItem("studentDetails",jsonString);
    }
  }
  ok()
  {
    this.statusDialog.close();
    this.router.navigate(['/login']);
  }
  openMessageDialog(msg: string, iseror: boolean, errormessage: string, value?:any,fromVerify=false): void {
    let btnname:any;
    if(value == 'mobile'){
      btnname=this.proceedBtn;
    }
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
        buttonName:btnname
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.goToLogin()
      if(fromVerify)
      this.setParentDetails();
    });
  }
  checkEmail(value: any, type: any) {
    this.IsEmailMatch = false;
    if (type == 1 && this.primaryDetails.get('primaryReEmail').value != '') {
      if (value.toLowerCase() == this.primaryDetails.get('primaryReEmail').value.toLowerCase()) {
        this.IsEmailMatch = false;
      }
      else {
        this.IsEmailMatch = true;
      }
    } else {
      if (this.primaryDetails.get('primaryEmail').value.toLowerCase() == value.toLowerCase()) {
        this.IsEmailMatch = false;
      }
      else {
        this.IsEmailMatch = true;
      }
    }


  }
  saveAsDraft(value: any) {
    // let data = {};
    // data = { "BSU_ID": 0, "ACD_ID": 0, "GRM_ID": 0, "STU_FIRSTNAME": "", "STU_MIDNAME": null, "STU_LASTNAME": "", "STU_DOB": "1994-02-09T00:00:00", "STU_GENDER": "M", "STU_MED_CONDITION": null, "STARTDATE": "2023-12-12T00:00:00", "SBL_ID": 0, "PNT_ID": 0, "SBL_ID_DROPOFF": 0, "PNT_ID_DROPOFFPOINT": 0, "PRIMARYCONTACT": this.primaryDetails.value.primaryRelationShip, "TITLE": null, "PFIRSTNAME": this.primaryDetails.value.primaryFname, "PMIDNAME": this.primaryDetails.value.primaryMname, "PLASTNAME": this.primaryDetails.value.primaryLname, "POBOX": null, "EMIRATE": "", "HOUSE_NO": "", "ADDRESS": "", "OFFPHONE": null, "RESPHONE": null, "MOBILEPHONE": this.phone, "MFIRSTNAME": this.primaryDetails.value.secondaryFname, "MMIDNAME": this.primaryDetails.value.secondaryMname, "MLASTNAME": this.primaryDetails.value.secondaryLname, "BBT_MOTHER_MOBILE": this.primaryDetails.value.secondaryMobile, "EMERGENCY_MOBILE": this.primaryDetails.value.secondaryMobile, "EMAIL": this.primaryDetails.value.primaryEmail, "ALTEMAIL": null, "GFULLNAME": this.primaryDetails.value.otherFullName, "GMOBILE": this.primaryDetails.value.otherMobile, "bSENDEMAIL": 0, "bSMS": 0, "PICKUPLOCATION": "", "DROPOFFLOCATION": "", "REGFROM": "", "OTHERPICKUP": "", "OTHERDROPOFF": "", "IMAGE_LOC": "", "LAT": 0, "LONG": 0, "TPT_PROMO_ID": 0, "STU_SCHOOL_ID": "", "TPT_TYPE": 0, "STUTYPE": "", "SchoolName": "", "PICKUPAREA": "", "PICKUPPOINT": "", "DROPOFFAREA": "", "DROPOFFPOINT": "" }
    //localStorage.setItem('parentDetails', JSON.stringify(data))
    let data: any;
    data = JSON.parse(localStorage.getItem('parentDetails'))
    let OtherName = this.primaryDetails.value.otherFullName?.split(' ');
    let formData: any[] = [];
    if (data?.length > 0) {
      data.forEach(ele => {
        formData.push({
          "stU_ID": 0,
          "stU_FIRSTNAME": '',
          "stU_MIDNAME": '',
          "stU_LASTNAME": '',
          "stU_GENDER": '',
          "stU_GRD_ID": '',
          "stU_GRM_ID": 0,
          "stU_DOB": '',
          "stU_SCHOOL_ID": '',
          "stU_PHOTOPATH": '',
          "stS_MED": 0,
          "stU_MED_CONDITION": '',
          "stS_MED_STAFF_REQUIRED": 0,
          "stS_MED_STAFF_REQUIRED_DESC": '',
          "stU_PRIMARYCONTACT": this.primaryDetails.value.primaryRelationShip,
          "stS_FFIRSTNAME":this.primaryDetails.value.primaryRelationShip=="F"?this.primaryDetails.value.primaryFname:this.primaryDetails.value.secondaryRelationShip=="F" ? this.primaryDetails.value.secondaryFname : OtherName[0],
          "stS_FMIDNAME": this.primaryDetails.value.primaryRelationShip=="F"?this.primaryDetails.value.primaryMname:this.primaryDetails.value.secondaryRelationShip=="F" ?this.primaryDetails.value.secondaryMname :OtherName.length > 2 ? OtherName[1]:'' ,
          "stS_FLASTNAME":this.primaryDetails.value.primaryRelationShip=="F"?this.primaryDetails.value.primaryLname:this.primaryDetails.value.secondaryRelationShip=="F" ?this.primaryDetails.value.secondaryLname :OtherName.length == 2 ? OtherName[1]:OtherName.length > 2 ? OtherName[2]:OtherName[0],
          "stS_FMOBILE": this.primaryDetails.value.primaryRelationShip=="F"?this.phone:this.primaryDetails.value.secondaryRelationShip=="F" ?this.primaryDetails.value.secondaryMobile:this.primaryDetails.value.otherMobile,
          "stS_FEMAIL": this.primaryDetails.value.primaryRelationShip=="F"?this.primaryDetails.value.primaryEmail:'',
          "stS_MFIRSTNAME": this.primaryDetails.value.primaryRelationShip=="M"?this.primaryDetails.value.primaryFname:this.primaryDetails.value.secondaryRelationShip=="M" ?this.primaryDetails.value.secondaryFname:OtherName[0],
          "stS_MMIDNAME": this.primaryDetails.value.primaryRelationShip=="M"?this.primaryDetails.value.primaryMname:this.primaryDetails.value.secondaryRelationShip=="M" ?this.primaryDetails.value.secondaryMname:OtherName.length > 2 ? OtherName[1]:'',
          "stS_MLASTNAME": this.primaryDetails.value.primaryRelationShip=="M"?this.primaryDetails.value.primaryLname:this.primaryDetails.value.secondaryRelationShip=="M" ?this.primaryDetails.value.secondaryLname:OtherName.length == 2 ? OtherName[1]:OtherName.length > 2 ? OtherName[2]:OtherName[0],
          "stS_MMOBILE": this.primaryDetails.value.primaryRelationShip=="M"?this.phone:this.primaryDetails.value.secondaryRelationShip=="M" ?this.primaryDetails.value.secondaryMobile:this.primaryDetails.value.otherMobile,
          "stS_MEMAIL": this.primaryDetails.value.primaryRelationShip=="M"?this.primaryDetails.value.primaryEmail:'',
          "stS_GFULLNAME": this.primaryDetails.value.primaryRelationShip=="G"?this.primaryDetails.value.primaryFname+' '+this.primaryDetails.value.primaryMname+' '+this.primaryDetails.value.primaryLname:this.primaryDetails.value.secondaryRelationShip=="G" ? this.primaryDetails.value.secondaryFname+' '+this.primaryDetails.value.secondaryMname+' '+this.primaryDetails.value.secondaryLname : this.primaryDetails.value.otherFullName,
          "stS_GMOBILE": this.primaryDetails.value.primaryRelationShip=="G"?this.phone:this.primaryDetails.value.secondaryRelationShip=="G" ?this.primaryDetails.value.secondaryMobile:this.primaryDetails.value.otherMobile,
          "stS_GEMAIL": this.primaryDetails.value.primaryRelationShip=="G"?this.primaryDetails.value.primaryEmail:'',
          "stU_SECONDARY_CONTACT":this.primaryDetails.value.secondaryRelationShip,
          "disableRegistration":  0,
          "city": ele?.city ?? '',
          "house": ele?.house ?? '',
          "street": ele?.street ?? '',
          "transportType": ele?.transportType ?? '',
          "pickupArea": ele?.pickupArea ?? '',
          "pickupPoint": ele?.pickupPoint ?? '',
          "pickupPointLandMark": ele?.pickupPointLandMark ?? '',
          "dropoffArea": ele?.dropoffArea ?? '',
          "dropoffPoint": ele?.dropoffPoint ?? '',
          "dropoffPointLandMark": ele?.dropoffPointLandMark ?? '',
          "busServiceRequestedDate": ele?.busServiceRequestedDate ?? '',
          "picklat": ele?.picklat ?? 0,
          "picklog": ele?.picklog ?? 0,
          "droplat": ele?.droplog ?? 0,
          "droplog": ele?.droplog ?? 0,
          "stU_BSU_ID":ele?.stU_BSU_ID?? '',
          "SBL_ID_DROPOFF":ele?.DROPOFFAREA ?? 0,
          "PNT_ID_DROPOFFPOINT":ele?.DROPOFFPOINT?? 0,
        })
      })
    } else {

      formData.push({
        "stU_ID": 0,
        "stU_FIRSTNAME": '',
        "stU_MIDNAME": '',
        "stU_LASTNAME": '',
        "stU_GENDER": '',
        "stU_GRD_ID": '',
        "stU_GRM_ID": 0,
        "stU_DOB": '',
        "stU_SCHOOL_ID": '',
        "stU_PHOTOPATH": '',
        "stS_MED": 0,
        "stU_MED_CONDITION": '',
        "stS_MED_STAFF_REQUIRED": 0,
        "stS_MED_STAFF_REQUIRED_DESC": '',
        "stU_PRIMARYCONTACT": this.primaryDetails.value.primaryRelationShip,
        "stS_FFIRSTNAME":this.primaryDetails.value.primaryRelationShip=="F"?this.primaryDetails.value.primaryFname:this.primaryDetails.value.secondaryRelationShip=="F" ? this.primaryDetails.value.secondaryFname : OtherName[0],
        "stS_FMIDNAME": this.primaryDetails.value.primaryRelationShip=="F"?this.primaryDetails.value.primaryMname:this.primaryDetails.value.secondaryRelationShip=="F" ?this.primaryDetails.value.secondaryMname :OtherName.length > 2 ? OtherName[1]:'' ,
        "stS_FLASTNAME":this.primaryDetails.value.primaryRelationShip=="F"?this.primaryDetails.value.primaryLname:this.primaryDetails.value.secondaryRelationShip=="F" ?this.primaryDetails.value.secondaryLname :OtherName.length == 2 ? OtherName[1]:OtherName.length > 2 ? OtherName[2]:OtherName[0],
        "stS_FMOBILE": this.primaryDetails.value.primaryRelationShip=="F"?this.phone:this.primaryDetails.value.secondaryRelationShip=="F" ?this.primaryDetails.value.secondaryMobile:this.primaryDetails.value.otherMobile,
        "stS_FEMAIL": this.primaryDetails.value.primaryRelationShip=="F"?this.primaryDetails.value.primaryEmail:'',
        "stS_MFIRSTNAME": this.primaryDetails.value.primaryRelationShip=="M"?this.primaryDetails.value.primaryFname:this.primaryDetails.value.secondaryRelationShip=="M" ?this.primaryDetails.value.secondaryFname:OtherName[0],
        "stS_MMIDNAME": this.primaryDetails.value.primaryRelationShip=="M"?this.primaryDetails.value.primaryMname:this.primaryDetails.value.secondaryRelationShip=="M" ?this.primaryDetails.value.secondaryMname:OtherName.length > 2 ? OtherName[1]:'',
        "stS_MLASTNAME": this.primaryDetails.value.primaryRelationShip=="M"?this.primaryDetails.value.primaryLname:this.primaryDetails.value.secondaryRelationShip=="M" ?this.primaryDetails.value.secondaryLname:OtherName.length == 2 ? OtherName[1]:OtherName.length > 2 ? OtherName[2]:OtherName[0],
        "stS_MMOBILE": this.primaryDetails.value.primaryRelationShip=="M"?this.phone:this.primaryDetails.value.secondaryRelationShip=="M" ?this.primaryDetails.value.secondaryMobile:this.primaryDetails.value.otherMobile,
        "stS_MEMAIL": this.primaryDetails.value.primaryRelationShip=="M"?this.primaryDetails.value.primaryEmail:'',
        "stS_GFULLNAME": this.primaryDetails.value.primaryRelationShip=="G"?this.primaryDetails.value.primaryFname+' '+this.primaryDetails.value.primaryMname+' '+this.primaryDetails.value.primaryLname:this.primaryDetails.value.secondaryRelationShip=="G" ? this.primaryDetails.value.secondaryFname+' '+this.primaryDetails.value.secondaryMname+' '+this.primaryDetails.value.secondaryLname : this.primaryDetails.value.otherFullName,
        "stS_GMOBILE": this.primaryDetails.value.primaryRelationShip=="G"?this.phone:this.primaryDetails.value.secondaryRelationShip=="G" ?this.primaryDetails.value.secondaryMobile:this.primaryDetails.value.otherMobile,
        "stS_GEMAIL": this.primaryDetails.value.primaryRelationShip=="G"?this.primaryDetails.value.primaryEmail:'',
        "stU_SECONDARY_CONTACT":this.primaryDetails.value.secondaryRelationShip,
        "disableRegistration": 0,
        "city": '',
        "house": '',
        "street": '',
        "transportType": '',
        "pickupArea": '',
        "pickupPoint": '',
        "pickupPointLandMark": '',
        "dropoffArea": '',
        "dropoffPoint": '',
        "dropoffPointLandMark": '',
        "busServiceRequestedDate": '',
        "picklat": 0,
        "picklog": 0,
        "droplat": 0,
        "droplog": 0,
        "stU_BSU_ID": '',
        "SBL_ID_DROPOFF":0,
        "PNT_ID_DROPOFFPOINT":0,

      })

    }

    let jsonString = JSON.stringify(formData);
    localStorage.setItem("parentDetails", jsonString);
    localStorage.setItem("otherContactChecked", JSON.stringify(this.checked));
    if (value == 'draft') {
      let senddata: any = {};
      senddata = {
        "MobileNumber": this.phone,
        "StuDetails": jsonString
      }
      this.registrationService.studentSaveAsDraft(senddata, this.token).subscribe(
        res => {
          this.openMessageDialog(this.requestSavedSuccessfully, false, "");
        }
      )
    }



  }
  sendStudentDetails(data) {

    let details: any;
    details = JSON.parse(localStorage.getItem('studParentDetails'));
    if (details == null) {
      this.registrationService.sendStudentDetails.next(data);
    } else {
      this.registrationService.sendStudentDetails.next(details);

    }
    this.onTabChange.emit(1);
  }
  onToggle(event) {
    this.checked = event.checked
    if (this.checked == true) {
      // this.isOtherFullName = this.primaryDetails.value.otherFullName ? false : true;
      // this.isOtherMobile = this.primaryDetails.value.otherMobile ? false : true;
      // this.isOtherRelationship = this.primaryDetails.value.otherRelationShip ? false : true;

      this.primaryDetails.controls['otherFullName'].setValidators([Validators.required]);
      this.primaryDetails.controls['otherMobile'].setValidators([Validators.required]);
      this.primaryDetails.controls['otherRelationShip'].setValidators([Validators.required]);


    } else {
      // this.isOtherFullName = false;
      // this.isOtherMobile = false;
      // this.isOtherRelationship = false;
      this.primaryDetails.controls['otherFullName'].clearValidators();
      this.primaryDetails.controls['otherMobile'].clearValidators();
      this.primaryDetails.controls['otherRelationShip'].clearValidators();
      this.primaryDetails.controls['otherFullName'].updateValueAndValidity();
      this.primaryDetails.controls['otherMobile'].updateValueAndValidity();
      this.primaryDetails.controls['otherRelationShip'].updateValueAndValidity();

     }
    console.log(this.primaryDetails.valid);

  }
  checkOthersForm(value) {
    if (this.checked == true) {
      if (this.primaryDetails.value.otherRelationShip == '') {
        this.isOtherRelationship = true;
      } else {
        this.isOtherRelationship = false;
      }
      if (this.primaryDetails.value.otherFullName == '') {
        this.isOtherFullName = true;
      } else {
        this.isOtherFullName = false;
      }
      if (this.primaryDetails.value.otherMobile == '') {
        this.isOtherMobile = true;
      } else {
        this.isOtherMobile = false;
      }
    } else {
      this.primaryDetails.value.otherRelationShip = '';
      this.primaryDetails.value.otherFullName = '';
      this.primaryDetails.value.otherMobile = ''
    }
  }

  gotoPrevious() {
    this.disabledContact = false;
    let schooldata:any;
    schooldata = JSON.parse(localStorage.getItem('schoolDetails'));
    console.log(schooldata);
if(schooldata.country == "UAE")  {
  this.selectedCountries = this.countries[0]
}  else{
  this.selectedCountries = this.countries[1]
}
    let data: any;
    data = JSON.parse(localStorage.getItem('parentDetails'))
    let index:any
          if (!this.hasLoginUser) {
            index = data?.length == 1 ? 0 : data.findIndex((x: any) => x.disableRegistration == 0)
            index = index == -1 ? 0 : index;
          }
          else {
            index = data?.length == 1 ? 0 : data.findIndex((x: any) => x.transportStatus == "Service is already Active")
            index = index == -1 ? 0 : index;
          }
    this.details = data;
    if(this.selectedCountries=="qa" && data[index].stS_MMOBILE)
    {
      if(data[index].stS_MMOBILE.length == 8)
      data[index].stS_MMOBILE = "+974"+data[index].stS_MMOBILE;
      else{
        data[index].stS_MMOBILE= data[index].stS_MMOBILE.substr(-8)
        data[index].stS_MMOBILE = "+974"+data[index].stS_MMOBILE;
      }
    }
    else if(this.selectedCountries=="ae"&& data[index].stS_MMOBILE)
    {
      data[index].stS_MMOBILE = data[index].stS_MMOBILE.includes("+971")?data[index].stS_MMOBILE.replace("+971",''): data[index].stS_MMOBILE;
      if(data[index].stS_MMOBILE.length == 9)
      data[index].stS_MMOBILE = "+971"+data[index].stS_MMOBILE;

      else if(data[index].stS_MMOBILE.length == 8){
        data[index].stS_MMOBILE= data[index].stS_MMOBILE.substr(-8)
        data[index].stS_MMOBILE = "+971"+data[index].stS_MMOBILE;
      }
      else{
        data[index].stS_MMOBILE= data[index].stS_MMOBILE.substr(-9)
        data[index].stS_MMOBILE = "+971"+data[index].stS_MMOBILE;
      }
      // data[index].stS_MMOBILE = data[index].stS_MMOBILE.slice(0, 6) +
      // "-"+ data[index].stS_MMOBILE.slice(6)
    }

    if(this.selectedCountries=="qa" && data[index].stS_FMOBILE)
    {
      if(data[index].stS_FMOBILE.length == 8)
      data[index].stS_FMOBILE = "+974"+data[index].stS_FMOBILE;
      else{
        data[index].stS_FMOBILE= data[index].stS_FMOBILE.substr(-8)
        data[index].stS_FMOBILE = "+974"+data[index].stS_FMOBILE;
      }
    }
    else if(this.selectedCountries=="ae"&& data[index].stS_FMOBILE)
    {
      data[index].stS_FMOBILE = data[index].stS_FMOBILE.includes("+971")?data[index].stS_FMOBILE.replace("+971",''): data[index].stS_FMOBILE;
      if(data[index].stS_FMOBILE.length == 9)
      data[index].stS_FMOBILE = "+971"+data[index].stS_FMOBILE;

      else if(data[index].stS_FMOBILE.length == 8){
        data[index].stS_FMOBILE= data[index].stS_FMOBILE.substr(-8)
        data[index].stS_FMOBILE = "+971"+data[index].stS_FMOBILE;
      }
      else{
        data[index].stS_FMOBILE= data[index].stS_FMOBILE.substr(-9)
        data[index].stS_FMOBILE = "+971"+data[index].stS_FMOBILE;
      }
      // data[index].stS_FMOBILE = data[index].stS_FMOBILE.slice(0, 6) +
      // "-"+ data[index].stS_FMOBILE.slice(6)
    }

    if(this.selectedCountries=="qa" && data[index].stS_GMOBILE)
    {
      if(data[index].stS_GMOBILE.length == 8)
      data[index].stS_GMOBILE = "+974"+data[index].stS_GMOBILE;
      else{
        data[index].stS_GMOBILE= data[index].stS_GMOBILE.substr(-8)
        data[index].stS_GMOBILE = "+974"+data[index].stS_GMOBILE;
      }
    }
    else if(this.selectedCountries=="ae"&& data[index].stS_GMOBILE)
    {
      data[index].stS_GMOBILE = data[index].stS_GMOBILE.includes("+971")?data[index].stS_GMOBILE.replace("+971",''): data[index].stS_GMOBILE;
      if(data[index].stS_GMOBILE.length == 9)
      data[index].stS_GMOBILE = "+971"+data[index].stS_GMOBILE;

      else if(data[index].stS_GMOBILE.length == 8){
        data[index].stS_GMOBILE= data[index].stS_GMOBILE.substr(-8)
        data[index].stS_GMOBILE = "+971"+data[index].stS_GMOBILE;
      }

      else{
        data[index].stS_GMOBILE= data[index].stS_GMOBILE.substr(-9)
        data[index].stS_GMOBILE = "+971"+data[index].stS_GMOBILE;
      }
      // data[index].stS_GMOBILE = data[index].stS_GMOBILE.slice(0, 6) +
      // "-"+ data[index].stS_GMOBILE.slice(6)
    }

    let otherContactChecked = localStorage.getItem("otherContactChecked");

    let other=''
            let fullName=''
            let othermobile=''
            if((data[index]?.stU_PRIMARYCONTACT == "F" && data[index]?.stU_SECONDARY_CONTACT == "M") || (data[index]?.stU_PRIMARYCONTACT == "M" && data[index]?.stU_SECONDARY_CONTACT == "F"))
            {
            other ="G";
            fullName=data[index]?.stS_GFULLNAME
            othermobile=data[index]?.stS_GMOBILE
            }
            else if((data[index]?.stU_PRIMARYCONTACT == "F" && data[index]?.stU_SECONDARY_CONTACT == "G") || (data[index]?.stU_PRIMARYCONTACT == "G" && data[index]?.stU_SECONDARY_CONTACT == "F"))
            {
            other ="M"
            fullName=data[index]?.stS_MFIRSTNAME+' '+data[index]?.stS_MMIDNAME+' '+data[index]?.stS_MLASTNAME
            othermobile=data[index]?.stS_MMOBILE
            }
            else if((data[index]?.stU_PRIMARYCONTACT == "M" && data[index]?.stU_SECONDARY_CONTACT == "G") || (data[index]?.stU_PRIMARYCONTACT == "G" && data[index]?.stU_SECONDARY_CONTACT == "M"))
            {
            other ="F"
            fullName=data[index]?.stS_FFIRSTNAME+' '+data[index]?.stS_FMIDNAME+' '+data[index]?.stS_FLASTNAME
            othermobile=data[index]?.stS_FMOBILE
            }

            let otherName =data[index]?.stS_GFULLNAME?.split(' ')
            this.phone = data[index]?.stU_PRIMARYCONTACT == "F" ? data[index]?.stS_FMOBILE:data[index]?.stU_PRIMARYCONTACT == "M"?data[index]?.stS_MMOBILE:data[index]?.stS_GMOBILE;
    this.primaryDetails.patchValue({
      // primaryRelationShip: "F",
      // primaryFname: data[0]?.stS_FFIRSTNAME,
      // primaryMname: data[0]?.stS_FMIDNAME,
      // primaryLname: data[0]?.stS_FLASTNAME,
      // primaryMobile: data[0]?.stS_FMOBILE,
      // primaryEmail: data[0]?.stS_FEMAIL,
      // primaryReEmail: data[0]?.stS_FEMAIL,
      // secondaryRelationShip: "M",
      // secondaryFname: data[0]?.stS_MFIRSTNAME,
      // secondaryMname: data[0]?.stS_MMIDNAME,
      // secondaryLname: data[0]?.stS_MLASTNAME,
      // secondaryMobile: data[0]?.stS_MMOBILE,
      primaryRelationShip: data[index]?.stU_PRIMARYCONTACT,
      primaryFname:data[index]?.stU_PRIMARYCONTACT == "F" ? data[index]?.stS_FFIRSTNAME : data[index]?.stU_PRIMARYCONTACT == "M" ? data[index]?.stS_MFIRSTNAME : otherName[0],
      primaryMname:data[index]?.stU_PRIMARYCONTACT == "F" ? data[index]?.stS_FMIDNAME : data[index]?.stU_PRIMARYCONTACT == "M"?data[index]?.stS_MMIDNAME: otherName.length > 2 ? otherName[1]:'',
      primaryLname:data[index]?.stU_PRIMARYCONTACT == "F" ? data[index]?.stS_FLASTNAME :data[index]?.stU_PRIMARYCONTACT == "M"?data[index]?.stS_MLASTNAME :otherName.length == 2 ? otherName[1]:otherName.length > 2 ? otherName[2]:otherName[0],
      primaryMobile: this.phone,
      primaryEmail: data[index]?.stU_PRIMARYCONTACT == "F" ? data[index].stS_FEMAIL : data[index]?.stU_PRIMARYCONTACT == "M"? data[index]?.stS_MEMAIL:data[index]?.stS_GEMAIL,
      primaryReEmail:data[index]?.stU_PRIMARYCONTACT == "F" ? data[index].stS_FEMAIL :data[index]?.stU_PRIMARYCONTACT == "M"? data[index]?.stS_MEMAIL:data[index]?.stS_GEMAIL,
      secondaryRelationShip: data[index]?.stU_SECONDARY_CONTACT,
      secondaryFname: data[index]?.stU_SECONDARY_CONTACT == "M" ?  data[index]?.stS_MFIRSTNAME:data[index]?.stU_SECONDARY_CONTACT == "F"?data[index]?.stS_FFIRSTNAME: otherName[0] ,
      secondaryMname: data[index]?.stU_SECONDARY_CONTACT == "M" ?  data[index]?.stS_MMIDNAME:data[index]?.stU_SECONDARY_CONTACT == "F"?data[index]?.stS_FMIDNAME:otherName.length > 2 ? otherName[1]:'',
      secondaryLname: data[index]?.stU_SECONDARY_CONTACT == "M" ?  data[index]?.stS_MLASTNAME:data[index]?.stU_SECONDARY_CONTACT == "F"?data[index]?.stS_FLASTNAME:otherName.length == 2 ? otherName[1]:otherName.length > 2 ? otherName[2]:otherName[0],
      secondaryMobile:data[index]?.stU_SECONDARY_CONTACT == "M" ? data[index]?.stS_MMOBILE:data[index]?.stU_SECONDARY_CONTACT == "F"?data[index]?.stS_FMOBILE:data[index]?.stS_GMOBILE,
      otherRelationShip: otherContactChecked == 'false' ? '' : other,
      otherFullName: otherContactChecked == 'false' ? '' : fullName,
      otherMobile: otherContactChecked == 'false' ? '' : othermobile
    })
    if(this.selectedCountries=='ae')
    {
      this.formatPhoneNumber();
      this.formatsPhoneNumber();
    }

    if (data[index]?.stS_GFULLNAME == null || data[index]?.stS_GFULLNAME == '' || otherContactChecked == 'false') {
      this.checked = false;
      this.isOtherFullName = false;
      this.isOtherMobile = false;
      this.isOtherRelationship = false
      this.primaryDetails.value.otherRelationShip = ''
    } else {
      this.checked = true;
      this.isOtherFullName = data[index]?.stS_GFULLNAME == "" ? true : false;
      this.isOtherMobile = data[index]?.stS_GMOBILE == "" ? true : false;
      this.isOtherRelationship = data[index]?.otherRelationShip == "" ? true : false;
      this.primaryDetails.value.otherRelationShip = 'G';
      this.formatsPhoneNumber1();
      this.checkRelationship(this.primaryDetails.value.primaryRelationShip, 'pRel')
      this.checkRelationship(this.primaryDetails.value.secondaryRelationShip, 'sRel')
      this.checkRelationship(this.primaryDetails.value.otherRelationShip, 'gRel')
    }

  }
  previous() {
    localStorage.removeItem('parentDetails');
    localStorage.removeItem('bsU_ID');
    localStorage.removeItem('studParentDetails');
    this.router.navigateByUrl('/schools-list');
  }
  checkRelationship(value, name) {
    if (name == 'pRel') {
      this.primaryRel = value;
      this.secondaryRel = '';
    }
    if (name == 'sRel') {
      this.secondaryRel = value;

    }
    if (name == 'GRel') {
      this.guardianRel = value;

    }
  }
  formatPrimaryPhoneNumber($event, currentCtrl) {
    let phoneNumber: any = parsePhoneNumberFromString('+' + currentCtrl.selectedCountry.dialCode + $event.srcElement.value);
    if (phoneNumber && phoneNumber.isValid()) {
      var string = phoneNumber;
      console.log(phoneNumber);

      let phone =''
      if(this.selectedCountries == 'ae'){

        phone = [string.nationalNumber.slice(0, 2)+"-"+string.nationalNumber.slice(2,9)].join('');
        this.isUAENoValidPrimary=phone.startsWith('5')? false:true;

      }else{
        phone = phoneNumber.nationalNumber
        this.isUAENoValidPrimary= false;
      }
       this.nationalnumber = phoneNumber.number
      console.log(phone);
      $event.srcElement.value = phone    }
  }
  formatSecondaryPhoneNumber($event, currentCtrl) {
    let phoneNumber: any = parsePhoneNumberFromString('+' + currentCtrl.selectedCountry.dialCode + $event.srcElement.value);
    if (phoneNumber && phoneNumber.isValid()) {
      var string = phoneNumber;

      let phone =''
      if(this.selectedCountries == 'ae'){
        phone = [string.nationalNumber.slice(0, 2)+"-"+string.nationalNumber.slice(2,9)].join('');
        this.isUAENoValidSecondary=phone.startsWith('5')? false:true;
      }else{
        phone = phoneNumber.nationalNumber
        this.isUAENoValidSecondary=false
      }

      console.log(phone);
      this.nationalnumber = phoneNumber.number

      $event.srcElement.value = phone
    }
  }
  formatOtherPhoneNumber($event, currentCtrl) {
    let phoneNumber: any = parsePhoneNumberFromString('+' + currentCtrl.selectedCountry.dialCode + $event.srcElement.value);
    if (phoneNumber && phoneNumber.isValid()) {
      var string = phoneNumber;

      let phone =''
      if(this.selectedCountries == 'ae'){
        phone = [string.nationalNumber.slice(0, 2)+"-"+string.nationalNumber.slice(2,9)].join('');
        this.isUAENoValidOther=phone.startsWith('5')? false:true;
      }else{
        phone = phoneNumber.nationalNumber
        this.isUAENoValidOther=false;
      }

      console.log(phone);
      this.nationalnumber = phoneNumber.number

      $event.srcElement.value = phone
    }
  }
  formatPhoneNumber() {

    const parsedPhoneNumber = this.primaryMobile.phoneNumber;
    this.primaryMobile.phoneNumber= [this.primaryMobile.phoneNumber.slice(0, 2)+"-"+this.primaryMobile.phoneNumber.slice(2,9)].join('');

  }
  formatsPhoneNumber() {

    const parsedPhoneNumber = this.secondaryMobile.phoneNumber;
    this.secondaryMobile.phoneNumber= [this.secondaryMobile.phoneNumber.slice(0, 2)+"-"+this.secondaryMobile.phoneNumber.slice(2,9)].join('');

  }
  formatsPhoneNumber1() {

    const parsedPhoneNumber = this.otherMobile.phoneNumber;
    this.otherMobile.phoneNumber= [this.otherMobile.phoneNumber.slice(0, 2)+"-"+this.otherMobile.phoneNumber.slice(2,9)].join('');

  }
}
