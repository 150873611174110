import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageComponent } from 'src/app/message/message.component';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  checked: boolean=false;
  langTrans: any;

  constructor( private router: Router,public service:DashboardService,private dialog: MatDialog, private translate: TranslateService) { }

  ngOnInit(): void {
    this.getMessageAlerts();

    this.translate.stream('settings').subscribe(async (data: any) => {
      if (data != null && data != undefined) {
        this.langTrans = data.messages;
      }
    });
  }
  navigatetolink(lnk) {
    this.router.navigate([lnk]);
  }
  getMessageAlerts()
  {
    this.service.getMessageAlerts().subscribe((res:any)=>{
      this.checked = res?.data ?? false;
    })
  }
  onToggle(event) {
    this.checked = event.checked;
    this.service.updateMessageAlert(this.checked).subscribe((res:any)=>{
      if(res?.data)
      this.openMessageDialog(this.langTrans?.alertUpdated,false,'')
      else this.openMessageDialog(this.langTrans?.alertUpdateFailed,true,'')
    })
  }
  openPdf(type:any)
  {
    this.service.getTermsAndPolicy(type).subscribe((res:any)=>{
      window.open(res?.data, '_blank');
    })
  }
  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
