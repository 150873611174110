import { AfterViewInit, Component, DoCheck, OnInit, Renderer2 } from '@angular/core';
import { ChatMessage } from 'src/app/models/chatbot.model';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit,DoCheck, AfterViewInit {
  quickLinks: any[]=[];
  response: any[]=[];
  showReRegister:boolean=false;
  isReregisterEnable: boolean=false;
  isLeasedSchool: any;
  openchatBot: any;
  greetings:any;
  userName:any;
  constructor(private dashboardService:DashboardService, private renderer: Renderer2) { }
  ngDoCheck(): void {
    let bsuId=localStorage.getItem('bsU_ID');
   let schoolList = JSON.parse(localStorage.getItem('schoolList'));
   this.isLeasedSchool = localStorage.getItem('leasemodelschool');
   this.isReregisterEnable=schoolList?.find(x=>x.bsU_ID == bsuId)?.isReregisterEnable;

   if(localStorage.getItem('transportChildInfo') != null){
    let studentTransportInfo = JSON.parse(localStorage.getItem('transportChildInfo'));
    let selectedStudentId = localStorage.getItem('selectedstudentid');
    let selectedStudentData = studentTransportInfo?.filter((x:any) => x.studentNo == selectedStudentId);
    let value = selectedStudentData[0]?.reregistered;
    this.showReRegister= value == '1' ? false :true;
   }else{
    let value = localStorage.getItem('isReRegister');
    this.showReRegister= value == '1' ? false :true;
   }

   this.quickLinks=[];
  this.response.forEach(x=>{
    x.key = x.title?.toLowerCase()?.replaceAll(' ', '');

     if(x.title.toLowerCase() == "re-registration" && this.showReRegister && this.isReregisterEnable)
    this.quickLinks.push(x)
    else if(x.title.toLowerCase() != "re-registration")
    this.quickLinks.push(x);
   })

  }

  ngOnInit(): void {
    this.getQuickLinks()
  }

ngAfterViewInit(): void {

}
  getQuickLinks(){
    this.dashboardService.getQuickLinks().subscribe((res:any)=>{
      this.response = res?.data


    })
  }
  openChatBot(){    
    let username=localStorage.getItem("primaryContactName") ?? "";
    this.userName=localStorage.getItem('parent');

    let currentHour=new Date().getHours();
    let greet='';
    if(currentHour<12){
      greet='Good Morning!';
    }
    else if(currentHour<18){
      greet='Good Afternoon!';
    }
    else{
      greet='Good Evening!';
    }
    this.greetings = {
      author: {
        id: 0
      },
      suggestedActions: [
        {
          type: 'reply',
          value: 'Service Availability and Fees'
        },
        {
        type: 'reply',
        value: 'Payment'
      }, {
        type: 'reply',
        value: 'Service Requests'
      }, {
        type: 'reply',
        value: 'Re-activate'
      }, {
        type: 'show-more',
        value: 'More'
      }],
      timestamp: new Date(),
      text: `Hi ${username} ${greet} I\'m Super Driver, your virtual assistant, what would you like to know?`
    };

    this.openchatBot = !this.openchatBot;
  }

  redirectEvent(data:any){

  }

  toggleDirection(direction: string) {
    document.body.dir = direction;
    console.log('Text direction changed to:', direction);
  }
}
