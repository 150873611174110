import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageComponent } from 'src/app/message/message.component';
import { CommonService } from 'src/app/service/common.service';
import { ConfigService } from 'src/app/service/config.service';
import { DashboardService } from 'src/app/service/dashboard.service';
import { FileService } from 'src/app/service/file.service';
import { ReregistrationService } from 'src/app/service/reregistration.service';
import { SupportService } from 'src/app/service/support.service';
import { UserprofileupdateService } from 'src/app/service/userprofileupdate.service';

@Component({
  selector: 'app-rfid',
  templateUrl: './rfid.component.html',
  styleUrls: ['./rfid.component.scss']
})
export class RfidComponent implements OnInit {
  digopen: any;

  bsuCurrency: string = '';
  studentProfilePhoto: string = '';

  file: File;
  base64Conversion: any;
  fileType: any;
  base64String: any;
  fileErr:boolean=false;
  selectedFile: any;

  checked: boolean = false;
  cardTypes: any[] = [];

  idForm = new FormGroup<any>({
    studentName:new FormControl<any>(''),
    schoolId: new FormControl<any>(''),
    studentId:new FormControl<any>(''),
    grade:new FormControl<any>(''),
    section:new FormControl<any>(''),
    type:new FormControl<any>('', [Validators.required])
  });

  isLeasedSchool: any;
  isHybridSchool: any;
  
  PaymentRefNo: any;
  PaymentRedirectURL: any;
  paymentTypeID: any;

  amountNotAvailable: boolean = false;
  langTrans: any;
  constructor(private dialog: MatDialog, private router: Router, private dashboardService: DashboardService, 
    private fileService:FileService, private userprofileimageservice: UserprofileupdateService,
    private supportService: SupportService, private configService: ConfigService, private reRegService: ReregistrationService, 
    private common:CommonService, private translate: TranslateService) { }

  ngOnInit(): void {
    
    this.isLeasedSchool = localStorage.getItem('leasemodelschool');
    this.isHybridSchool = localStorage.getItem('IsHybrid');

    this.getIdCardTypes();

    this.bsuCurrency = localStorage.getItem('bsu_currency');

    let selectedStudent = localStorage.getItem("selectedstudentid");
    let transportChildInfo = JSON.parse(localStorage.getItem("transportChildInfo"));

    let childInfo = transportChildInfo.filter((x:any) => x.studentNo == selectedStudent);

    let schoolId = childInfo[0]?.stU_SCHOOL_ID;
    let studentName = JSON.parse(localStorage.parentChildrens)[0].studentName;
    this.studentProfilePhoto = JSON.parse(localStorage.parentChildrens)[0].photoPath;
    let studentTransportId = JSON.parse(localStorage.parentChildrens)[0].studentId;
    let studentGrade = JSON.parse(localStorage.parentChildrens)[0].grade;
    let studentSection = JSON.parse(localStorage.parentChildrens)[0].section;

    this.idForm.patchValue({
      studentName: studentName,
      studentId: studentTransportId,
      grade: studentGrade,
      section: studentSection,
      schoolId: schoolId
    });

    this.translate.stream('support').subscribe(async (data: any) => {
      if (data != null && data != undefined) {
        this.langTrans = data.messages;
      }
    });
  }

  getIdCardTypes(){
    this.supportService.getIdCardTypes().subscribe((res:any) => {
        this.cardTypes = res?.data;

      this.common.getIPAddress().subscribe(
      (ipRes: any) => {
        localStorage.setItem('IPAddress', ipRes?.ip)
      });
    });
  }

  onChangeType(){
    this.amountNotAvailable = false;
    let amount = this.cardTypes.find((x:any) => x.id == this.idForm.get('type').value)?.amount;

    if(amount <= 0){
      this.amountNotAvailable = true;
    }
  }

  submitRequest() {
    this.idForm.markAllAsTouched();
    if (this.idForm.valid) {
      let payload:any;
      payload = {
        "STU_NO": this.idForm.get('studentId').value,
        "CARD_TYPE": this.idForm.get('type').value,
        "PAYMENT_STATUS": this.amountNotAvailable,
        "PAYMENT_REFNO": "0",
        "SOURCE": "STS_WEB"
      }

      this.supportService.saveIdCardRequest(payload).subscribe((res: any) => {
        if(res.success == "true"){
          let amount = this.cardTypes.find((x:any) => x.id == this.idForm.get('type').value)?.amount;
          if(amount > 0){
            this.postFeeDetails(amount, res.data);
          }else{
            this.openMessageDialog(this.langTrans?.requestSubmittedSuccessfully, false, "");
            //this.router.navigate(['/service-request']);
          }
        }else{
          this.openMessageDialog(res?.message, true, '');
        }
      });
    }
  }

  postFeeDetails(amount:any, referenceNo:any) {
    let data: any[] = [];
    let studentId = this.idForm.get('studentId').value;

    this.supportService.getFeeDetails(studentId).subscribe(
      res => {
        let feedetailsData = res?.data.find((x:any) => x.stU_NO == studentId);

        let obj = {
          headers: {
            "STUID": studentId,
            "SOURCE": this.configService.source,
            "paymentOrigin": this.configService.paymentreenrollOrigin
    
          }
        }
    
        this.supportService.getPaymentType(obj).subscribe((res: any) => {
          this.paymentTypeID = res?.data?.filter(x => x.providerTypeID == '2')[0].paymentTypeID;
    
          let feedetail: any[] = [];
          feedetailsData.feeDetail.forEach(ele1 => {
            feedetail.push({
              "blockPayNow": ele1.blockPayNow,
              "advancePaymentAvailable": ele1.advancePaymentAvailable,
              "advanceDetails": ele1.advanceDetails,
              "discAmount": 0,
              "dueAmount": amount,
              "payAmount": amount,
              "originalAmount": amount,
              "feeDescription": "ID Card Fees",
              "feeID": 49,
              "activityRefID": ele1.activityRefID,
              "activityFeeCollType": ele1.activityFeeCollType
            });
          });

          data.push({
            "stU_NO": studentId,
            "onlinePaymentAllowed": feedetailsData.onlinePaymentAllowed,
            "userMessageforOnlinePaymentBlock": feedetailsData.userMessageforOnlinePaymentBlock,
            "ipAddress": localStorage.getItem('IPAddress'),
            "paymentTypeID": this.paymentTypeID,
            "paymentProcessingCharge": feedetailsData.paymentProcessingCharge,
            "payingAmount": amount,
            "payMode": "",
            "feeDetail": feedetail,
            "discountDetails": []
          });
    
          this.supportService.postFeeDetails(data).subscribe(
          (res: any) => {
            if (res.Response.Success == "true") {
              this.PaymentRefNo = res?.PaymentRefNo;
              localStorage.setItem('idCardRefNo', referenceNo);
              localStorage.setItem('idCardPayment', '1');
              localStorage.setItem("postPaymentRefNo", res.PaymentRefNo);

              this.PaymentRedirectURL = res.PaymentRedirectURL;
              console.log('paymentdet', this.PaymentRedirectURL);
              this.gotoPayment();
            } else {
              this.openMessageDialog(res.Response.Message, true, '');
            }
          });
        });
      }
    );
  }

  gotoPayment() {
    if (this.PaymentRefNo != 0) {
      window.open(this.PaymentRedirectURL, '_self');
    }
  }

  successDialog(templateRef: TemplateRef<any>) {
    this.digopen = this.dialog.open(templateRef, {
      width: '500px',
      panelClass: 'custom-modalbox'
    });
  }

  ok()
  {
    this.digopen.close();
    this.router.navigate(['/home']);
  }

  selectedImgFile(event: any) {
    this.file = event.target.files[0];

    if (this.file) {
      let splits = this.file.name.split(".");
      this.fileType = splits[1];
      if (this.fileType == "png" || this.fileType == "jpeg" || this.fileType == "jpg") {
        this.fileErr = false;
        this.selectedFile = this.file;
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        this.fileService.setFile(this.file);
        reader.onload = (e: any) => {
          this.studentProfilePhoto = e.target.result;
          this.updatePhoto();
        };
      }
      else {
        this.fileErr = true;
        this.studentProfilePhoto = ""
      }
    }
  }

  updatePhoto() {
    let data: any;
    let selectedStudent = JSON.parse(localStorage.parentChildrens)[0];
    data = {
      StudentId: selectedStudent.stuId.toString(),
      BsuId: JSON.parse(localStorage.getItem('currentUser')).schooId
    }
    this.dashboardService.updateProfile(data).subscribe(
      (res: any) => {
        if (res?.success == 'false') {
          this.openProileSuccessDialog(res?.message, true, '');
        } else {
          this.openProileSuccessDialog("Profile updated successfully.", false, '');
          this.userprofileimageservice.updateUserProfileImage(this.studentProfilePhoto);
        }
      }
    )
  }

  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    })

    dialogRef.afterClosed().subscribe(result => {
      this.cancel();
    });
  }

  openProileSuccessDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    })
  }

  cancel() {
    this.router.navigate(['/service-request']);
  }

  selectedValue = "Option 2 - Description for option 2";
}
