import {CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuRoutingModule } from './menu-routing.module';
import { SettingsComponent } from './settings/settings.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AppTermsAndConditionsComponent } from './settings/app-terms-and-conditions/app-terms-and-conditions.component';
import { PrivacyPolicyComponent } from './settings/privacy-policy/privacy-policy.component';
import { ChangePasswordComponent } from './settings/change-password/change-password.component';
import { MatButtonModule } from '@angular/material/button';
import {  TermsConditionsComponent } from './support/terms-conditions/terms-conditions.component';
import { FeedbackComponent } from './support/feedback/feedback.component';
import { FaqComponent } from './support/faq/faq.component';
import { ServiceRequestComponent } from './support/service-request/service-request.component';
import { ContactSdeComponent } from './support/contact-sde/contact-sde.component';
import { AddFeedbackComponent } from './support/feedback/add-feedback/add-feedback.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TCPipe } from './support/tcpipe.pipe';
import { StsGroupComponent } from './sts-group/sts-group.component';
import { RequestAreaChangeComponent } from './request-area-change/request-area-change.component';
import { StsOffersComponent } from './sts-offers/sts-offers.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {MatTabsModule} from '@angular/material/tabs';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import { RequestAreaChangeInfoComponent } from './request-area-change/request-area-change-info/request-area-change-info.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { GoogleMapsModule } from '@angular/google-maps';
import { AgmCoreModule } from '@agm/core';
import {MatIconModule} from '@angular/material/icon';
import { RfidComponent } from './support/service-request/rfid/rfid.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ViewServiceRequestComponent } from './support/service-request/view-service-request/view-service-request.component';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MessageService } from '@progress/kendo-angular-l10n';
import { interval } from 'rxjs';
import { LanguageTranslateService } from 'src/app/service/translatelanguageservice.service';
// register Swiper custom elements
register();
@NgModule({
  declarations: [
    SettingsComponent,
    AppTermsAndConditionsComponent,
    PrivacyPolicyComponent,
    ChangePasswordComponent,
    TermsConditionsComponent,
    FeedbackComponent,
    FaqComponent,
    ServiceRequestComponent,
    ContactSdeComponent,
    AddFeedbackComponent,
    TCPipe,
    StsGroupComponent,
    RequestAreaChangeComponent,
    StsOffersComponent,
    RequestAreaChangeInfoComponent,
    RfidComponent,
    ViewServiceRequestComponent
  ],
  imports: [
    CommonModule,
    MenuRoutingModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    CarouselModule,
    MatTabsModule,
    MatDatepickerModule,
    GoogleMapsModule,
    MatIconModule,
    MatCheckboxModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDdnZODNYHZKvYCN7nQztTyVYdf3BztiEQ',
      libraries: ['places']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, 'assets/i18n/', '.json'),
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    })
  ],
  providers:[TranslateService,MessageService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MenuModule { 
  constructor(private messages: MessageService,private translate:TranslateService){
    
    translate.addLangs(['en', 'ar']);
    interval(1000).subscribe(() => {
      let currentLanguage:any = localStorage.getItem('lang');
      currentLanguage?translate.use(currentLanguage):translate.setDefaultLang('en');
      const svc: any = <LanguageTranslateService>this.messages;
      svc.language = currentLanguage?currentLanguage:'en';
    });
  }
}
