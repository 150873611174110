import { Component, OnInit, TemplateRef, ViewEncapsulation  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageComponent } from 'src/app/message/message.component';
import { PaymentService } from 'src/app/service/payment.service';

@Component({
  selector: 'app-payment-refund',
  templateUrl: './payment-refund.component.html',
  styleUrls: ['./payment-refund.component.scss']
})
export class PaymentRefundComponent implements OnInit {
studentDetail:any;
// refundForm = new FormGroup<any>({
//   studentName:new FormControl<any>('',[Validators.required]),
//   studentId:new FormControl<any>('',[Validators.required]),
//   refundAmt:new FormControl<any>('',[Validators.required]),
//   payeeName:new FormControl<any>(''),
//   grade:new FormControl<any>('',[Validators.required]),
//   section:new FormControl<any>('',[Validators.required]),
//   Iban:new FormControl<any>(''),
//   accName:new FormControl<any>(''),
//   bankName:new FormControl<any>(''),
//   swiftCode:new FormControl<any>(''),
//   reason:new FormControl<any>('',[Validators.required])
// })

refundForm = new FormGroup<any>({
  payeeName:new FormControl<any>(''),
  Iban:new FormControl<any>(''),
  accName:new FormControl<any>(''),
  bankName:new FormControl<any>(''),
  swiftCode:new FormControl<any>(''),
  reason:new FormControl<any>('',[Validators.required]),
  imageName: new FormControl<any>('')
})

isCheque:boolean=true;
isBank:boolean=false
count: any=0;
minDescErr: boolean=true;
excessAmt:any;
  digopen: any;
  referenceNumber:any;
  siblingsRefundDetails: any[] = [];
  bsuCurrency: any;
  totalRefundAmount: number=0;
  acdId: any;
  refundAmtForm: { [key: string]: FormGroup } = {};
  checkboxDisabled: boolean = false;
  invalidFileSize: boolean = false;
  invalidFile: boolean = false;
  attachment: any;
  invalidAmount: boolean = false;
  langTrans: any;
  constructor(public paymentService:PaymentService,private dialog: MatDialog, private router:Router, private formBuilder: FormBuilder, private translate: TranslateService) { }

  ngOnInit(): void {
    this.setValidations();
    // this.paymentService.excessAmt.subscribe(res=>{
    // this.excessAmt=res;
    // })

    this.bsuCurrency = localStorage.getItem('bsu_currency');

    this.populateSiblingDetails();

    let transportInfo =JSON.parse(localStorage.getItem("transportChildInfo"));
    let selectedStudent =localStorage.getItem("selectedstudentid")
    let detail = transportInfo.find(x=>x.studentNo == selectedStudent);
    this.acdId = detail?.acdid;
    this.studentDetail=detail;

    this.translate.stream('payments').subscribe(async (data: any) => {
      if (data != null && data != undefined) {
        this.langTrans = data.messages;
      }
    });

  //   let grade = detail?.grade.split("/");
  //  //detail.section=section[1];
  //   this.refundForm.patchValue({
  //     "studentName":this.studentDetail?.studentName,
  //     "studentId":this.studentDetail?.stU_SCHOOL_ID,
  //     "grade":grade[0],
  //     "section":this.studentDetail?.section
  //   })
  }

  createRefundAmountForm(): FormGroup {
    return this.formBuilder.group({
      refundAmt:new FormControl<any>('',[Validators.required, Validators.maxLength(5)])
    })
  }

  populateSiblingDetails() {
    let transportInfo = JSON.parse(localStorage.getItem("transportChildInfo"));
    let stuIds: any[] = [];
    transportInfo.forEach((x:any) => {
      stuIds.push(x.stU_ID)
    });
    let studentIds = stuIds.join(',');

    this.paymentService.getRefundsData(studentIds).subscribe((res:any)=>{
      if(res?.length > 0){
        transportInfo?.forEach((x: any) => {

          let refund = res.find((y:any) => y.stU_ID == x.stU_ID && y.fee?.toLowerCase() == "total");

          if(refund?.amount >= 100){
            let grade = x.grade.split("/");
            this.siblingsRefundDetails.push({
              "studentName": x.studentName,
              "studentPhoto": x.photoPath,
              "studentId": x.studentNo,
              "stU_ID": x.stU_ID,
              "schoolId": x.stU_SCHOOL_ID,
              "grade": grade[0],
              "section": x.section,
              "refundAmount": 0,
              "allowedRefundAmount": refund?.amount,
              "isChecked": false,
              "disableEdit": false
            });
            this.refundAmtForm[x.studentNo] = this.createRefundAmountForm();
          }
        });

        this.siblingsRefundDetails?.forEach((x:any) => {
          x.isChecked = true;
          this.onChange(true, x);
        });

        if(this.siblingsRefundDetails != null && this.siblingsRefundDetails.length == 1){
          this.checkboxDisabled = true;
        }
      }
    });
  }

  onChangeRadio(isCheque:any,isBank:any)
  {
    this.isCheque=isCheque.checked;
    this.isBank=isBank.checked;
    this.refundForm.patchValue({
      payeeName: '',
      Iban: '',
      accName: '',
      bankName: '',
      swiftCode: '',
      imageName: '',
      reason: ''
    })
    this.count = 0;
    this.minDescErr = false;
    this.setValidations();
  }
  isChecked = true;

  onChange(checked: any, formData: any) {
    formData.disableEdit = checked;

    if(checked){
      this.refundAmtForm[formData.studentId].controls.refundAmt.setValidators([Validators.required, Validators.min(1), Validators.max(formData.allowedRefundAmount), this.maxLengthValidator(5)]);
      this.refundAmtForm[formData.studentId].controls.refundAmt.updateValueAndValidity();

      if(this.refundAmtForm[formData.studentId].invalid){
        this.invalidAmount = true;
      }
    }

    if(!checked){
      formData.refundAmount = 0;

      this.refundAmtForm[formData.studentId].patchValue({
        refundAmt: 0
      });
      this.refundAmtForm[formData.studentId].controls.refundAmt.markAsUntouched();
      this.refundAmtForm[formData.studentId].controls.refundAmt.clearValidators();
      this.refundAmtForm[formData.studentId].controls.refundAmt.updateValueAndValidity();
      this.calculateRefundAmount();
    }
  }

  onChangeRefundAmount(event:any, formData: any){
    const inputElement = event.target.value;

    formData.refundAmount = inputElement;

    this.calculateRefundAmount();
  }

  calculateRefundAmount(){
    this.totalRefundAmount = 0;
    this.invalidAmount = false;
    this.siblingsRefundDetails.forEach((x:any) => {
      if(x.isChecked){
        if(this.refundAmtForm[x.studentId].invalid){
          this.invalidAmount = true;
        }
        this.totalRefundAmount = (+this.totalRefundAmount) + (+x.refundAmount)
      }
    });
  }

  setValidations()
  {
    if(this.isCheque)
    {
    this.refundForm.controls.payeeName.setValidators([Validators.required]);
    this.refundForm.controls.imageName.setValidators([Validators.required]);
    this.refundForm.controls.Iban.markAsUntouched();
    this.refundForm.controls.accName.markAsUntouched();
    this.refundForm.controls.Iban.clearValidators();
    this.refundForm.controls.Iban.updateValueAndValidity();
    this.refundForm.controls.accName.clearValidators();
    this.refundForm.controls.accName.updateValueAndValidity();

    this.refundForm.controls.bankName.markAsUntouched();
    this.refundForm.controls.bankName.clearValidators();
    this.refundForm.controls.bankName.updateValueAndValidity();
    this.refundForm.controls.swiftCode.markAsUntouched();
    this.refundForm.controls.swiftCode.clearValidators();
    this.refundForm.controls.swiftCode.updateValueAndValidity();

    this.refundForm.controls.reason.setErrors(null);
    this.refundForm.controls.reason.markAsUntouched();
    this.refundForm.controls.reason.markAsPristine();
    this.refundForm.controls.reason.updateValueAndValidity();

    }
    else{
      this.refundForm.controls.payeeName.markAsUntouched();
      this.refundForm.controls.payeeName.clearValidators();
      this.refundForm.controls.payeeName.updateValueAndValidity();
      this.refundForm.controls.imageName.markAsUntouched();
      this.refundForm.controls.imageName.clearValidators();
      this.refundForm.controls.imageName.updateValueAndValidity();
      this.refundForm.controls.Iban.setValidators([Validators.required]);
      this.refundForm.controls.accName.setValidators([Validators.required]);
      this.refundForm.controls.bankName.setValidators([Validators.required]);
      this.refundForm.controls.swiftCode.setValidators([Validators.required]);

      this.refundForm.controls.reason.setErrors(null);
      this.refundForm.controls.reason.markAsUntouched();
      this.refundForm.controls.reason.markAsPristine();
      this.refundForm.controls.reason.updateValueAndValidity();
    }
  }
  onDescChange()
  {
    if(this.refundForm.get('reason').value && this.refundForm.get('reason').value.trim() != "")
    {
      this.count=this.refundForm.get('reason').value.length;
      if(this.count <=9)
       this.minDescErr=true;
       else this.minDescErr=false;
    }
    else{
      this.count=0;
      this.minDescErr=false;
      this.refundForm.patchValue({ "reason":this.refundForm.get('reason').value?.trim() == "" ? "" : this.refundForm.get('reason').value})
    }
  }

  submit(template:any)
  {
    // let payload={
    //   "studentno":this.studentDetail?.studentNo,
    //   "RequestAmount":this.refundForm.get('refundAmt').value,
    //   "TransferMode":this.isBank?"bank":"cheqe",
    //   "PayeeName":this.isBank ? null :this.refundForm.get('payeeName').value,
    //   "IBANNumber":this.isBank ? this.refundForm.get('Iban').value :null,
    //   "AccountName":this.isBank ? this.refundForm.get('accName').value :'',
    //   "RequestReason":this.refundForm.get('reason').value
    // }

    let attachment: any = null;

    if(!this.invalidFile && !this.invalidFileSize){
      attachment = this.attachment;
    }

    let stuRefundData: any[] = [];

    this.siblingsRefundDetails.forEach((x:any)=> {
      if(x.isChecked){
        stuRefundData.push({
          "FRD_ID": 0,
          "FRD_FRH_ID": 0,
          "FRD_AMOUNT": x.refundAmount,
          "FRD_FEE_ID": "6",
          "FRD_NARRATION": "Transport Fee",
          "FRD_STU_ID": x.stU_ID,
          "FRD_STU_NO": x.studentId
        });
      }
    });


    let payload: any;
    payload = {
      "FRH_ID": 0,
      "FRH_BSU_ID": localStorage.getItem("bsU_ID"),
      "FRH_STU_BSU_ID": localStorage.getItem("bsU_ID"),
      "FRH_ACD_ID": this.acdId,
      "FRH_STU_TYPE": "S",
      "FRH_STU_ID": this.studentDetail?.stU_ID,
      "FRH_STU_NO": this.studentDetail?.studentNo,
      "FRH_FAR_ID": 0,
      "FRH_DATE": new Date(),
      "FRH_CHQDT": new Date(),
      "FRH_bPosted": false,
      "FRH_bDeleted": false,
      "FRH_NARRATION": this.refundForm.get('reason').value,
      "FRH_VHH_DOCNO": "",
      "FRH_BANK_CASH": this.isBank ? "B" : "C",
      "FRH_ACT_ID": this.isBank ? this.refundForm.get('Iban').value : null,
      "FRH_PAIDTO": this.isBank ? this.refundForm.get('accName').value : this.refundForm.get('payeeName').value,
      "FRH_TOTAL": this.totalRefundAmount,
      "FRH_BANKCHARGE": 0.0,
      "FRH_APD_ID": 0,
      "FRH_SOURCE": "STS_WEB",
      "FRH_FCL_FCL_ID": 0,
      "FRH_CATEGORY": 0,
      "FRH_USER_ID": localStorage.getItem("parent"),
      "FRH_BANK_NAKE": this.isBank ? this.refundForm.get('bankName').value :'',
      "FRH_SWIFT_CODE": this.isBank ? this.refundForm.get('swiftCode').value : null,
      "refundDetails": stuRefundData
    }

    this.paymentService.saveFeeRefund(JSON.stringify(payload), attachment).subscribe((res:any)=>{
      if(res?.success == "true")
      {
        this.referenceNumber=res?.data
        if(this.referenceNumber != "0")
      this.successDialog(template)
      else   this.openMessageDialog(this.langTrans?.requestAlreadyExistsAndNotApproved, true, "");
      }else{
        this.openMessageDialog(res?.message, true, "");
      }
    })
  }
  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {

    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.cancel();
    });
  }
cancel()
{
  this.digopen?.close();
  this.router.navigate(['/statement-of-accounts'], { queryParams: { id: this.studentDetail?.studentNo } });
}

charOnly(event: any): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode === 32) {
    return true;
  }
  return false;
}

validateChar(type:any){
  if (type == 'payee') {
    if (!(this.refundForm.get('payeeName').value && this.refundForm.get('payeeName').value.trim() != "")) {
      this.refundForm.patchValue({ "payeeName": this.refundForm.get('payeeName').value?.trim() == "" ? "" : this.refundForm.get('payeeName').value })
    }
  }

  if (type == 'iban') {
    if (!(this.refundForm.get('Iban').value && this.refundForm.get('Iban').value.trim() != "")) {
      this.refundForm.patchValue({ "Iban": this.refundForm.get('Iban').value?.trim() == "" ? "" : this.refundForm.get('Iban').value })
    }
  }

  if(type == 'account'){
    if (!(this.refundForm.get('accName').value && this.refundForm.get('accName').value.trim() != "")) {
      this.refundForm.patchValue({ "accName": this.refundForm.get('accName').value?.trim() == "" ? "" : this.refundForm.get('accName').value })
    }
  }
  if(type == 'swift'){
    if (!(this.refundForm.get('swiftCode').value && this.refundForm.get('swiftCode').value.trim() != "")) {
      this.refundForm.patchValue({ "swiftCode": this.refundForm.get('swiftCode').value?.trim() == "" ? "" : this.refundForm.get('swiftCode').value })
    }
  }
  if(type == 'bank'){
    if (!(this.refundForm.get('bankName').value && this.refundForm.get('bankName').value.trim() != "")) {
      this.refundForm.patchValue({ "bankName": this.refundForm.get('bankName').value?.trim() == "" ? "" : this.refundForm.get('bankName').value })
    }
  }
}

  successDialog(templateRef: TemplateRef<any>) {
    this.digopen = this.dialog.open(templateRef, {
      width: '550px',
      panelClass: 'custom-modalbox'
    });
  }

  ibanCharOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || (charCode >= 48 && charCode <= 57) || charCode === 32) {
      return true;
    }
    return false;
  }

  handleFileInputChange(files: any) {
    let fileData: any;
    let imageName: any = '';

    fileData = files.target.files[0];
    let type: any = '';
    type = fileData?.type.split('/')[1];
    let extName: any = '';
    extName = fileData?.name.split('.')[1];
    let filesize: any = '';
    filesize = fileData?.size;
    imageName = fileData?.name;
    if ((type == 'png' || type == 'jpg' || type == 'jpeg') || (extName == 'pdf')) {
      this.invalidFile = false;

    }
    else {
      this.invalidFile = true;
      imageName = "";
    }
    if (filesize <= 5242880) {
      this.invalidFileSize = false;
    } else {
      this.invalidFileSize = true;
      imageName = "";
    }

    imageName = fileData?.name;

    this.refundForm.patchValue({
      imageName: imageName
    });

    this.attachment = files.target.files[0];

  }

  maxLengthValidator(maxLength: number) {
    return (control) => {
      if (control.value && control.value.toString().length > maxLength) {
        return { 'maxlength': true };
      }
      return null;
    };
  }

  attachmentValidation(){

    if(this.isCheque){
      let attachment: any = null;

      if(!this.invalidFile && !this.invalidFileSize){
        attachment = this.attachment;
      }

      if(attachment == null){
        return true;
      }
    }

    return false;
  }
}
