import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { HomeService } from 'src/app/service/home.service';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { TokenService } from 'src/app/service/token.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: ['./news-widget.component.scss']
})
export class NewsWidgetComponent implements OnInit, AfterViewInit {
  @ViewChild('owlElement') owlElement:any;
  carouselVisible: boolean = true;
  latestNews: any[] = [];
  defaultToken: any;
  private languageChangeSubscription: Subscription;
  constructor(public service:HomeService,public tokenService:TokenService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.updateCarouselDirection(localStorage.getItem('lang'));

    this.getToken();

    this.languageChangeSubscription = this.translate.onLangChange.subscribe((event) => {
      this.updateCarouselDirection(event.lang);
      this.getLatestNews(event.lang);
    });
  }

  ngOnDestroy(): void {
    if (this.languageChangeSubscription) {
      this.languageChangeSubscription.unsubscribe();
    }
  }

  carouselOptions: OwlOptions;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateCarousel();
    }, 1000);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void{
    this.updateCarousel();
  }
  updateCarousel() {
    if (this.owlElement) {
      this.carouselVisible = false;
      setTimeout(() => {
        this.carouselVisible = true;
      }, 0)
    }
  }

// -------------------------------------------------------------------------------

  getToken() {
    this.tokenService.GetToken(null).subscribe(
      res => {
        this.defaultToken = res?.access_token;
        this.getLatestNews(localStorage.getItem('lang'));
      })
    }
  getLatestNews(lang: string) {
    this.service.getNews(this.defaultToken, lang).subscribe(
      res => {
        if(res.length <=3)
        this.latestNews = res
        else this.latestNews =res.slice(0,3)
      })
  }

  // carouselOffersOptions: OwlOptions = {
  //   stagePadding: 170,
  //   loop:false,
  //   margin:15,
  //   nav:false,
  //   dots: true,
  //   items:2,
  //   responsive: {
  //     0: {
  //       items: 1
  //     },
  //     768: {
  //       items: 2,
  //       stagePadding: 0,
  //       loop: false
  //     },
  //     992: {
  //       items: 2,
  //       stagePadding: 0,
  //       loop: false
  //     },
  //     1024: {
  //       items: 2,
  //       stagePadding: 0,
  //       loop: false
  //     },
  //     1300: {
  //       items: 2,
  //       stagePadding: 170,
  //       loop: false
  //     },
  //   }
  // };

  // customOptions: OwlOptions = {
  //   loop: false,
  //   mouseDrag: true,
  //   touchDrag: true,
  //   pullDrag: true,
  //   dots: true,
  //   margin: 20,
  //   navSpeed: 700,
  //   responsive: {
  //     0: {
  //       items: 1,
  //       mouseDrag: true,
  //       touchDrag: true,
  //       pullDrag: true,
  //     },
  //     400: {
  //       items: 2,
  //       mouseDrag: true,
  //       touchDrag: true,
  //       pullDrag: true,
  //     },
  //     740: {
  //       items: 2,
  //       mouseDrag: true,
  //       touchDrag: true,
  //       pullDrag: true,
  //     },
  //     940: {
  //       items: 2,
  //       mouseDrag: true,
  //       touchDrag: true,
  //       pullDrag: true,
  //     }
  //   },
  //   nav: false
  // }

  redirectUrl(url:any)
  {
    if(url)
    window.open(url, '_blank')
  }

  updateCarouselDirection(lang: string): void {
    const isRTL = lang === 'ar'; 
    
    this.carouselOptions = {
      rtl: isRTL,
      loop: false,
      items: 2,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: true,
      margin: 20,
      navSpeed: 700,
      responsive: {
        0: {
          items: 1,
          mouseDrag: true,
          touchDrag: true,
          pullDrag: true,
        },
        400: {
          items: 2,
          mouseDrag: true,
          touchDrag: true,
          pullDrag: true,
        },
        740: {
          items: 2,
          mouseDrag: true,
          touchDrag: true,
          pullDrag: true,
        },
        940: {
          items: 2,
          mouseDrag: true,
          touchDrag: true,
          pullDrag: true,
        }
      },
      nav: false
    };
  }
}
