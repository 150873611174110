import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PaymentService } from 'src/app/service/payment.service';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-soa-details',
  templateUrl: './soa-details.component.html',
  styleUrls: ['./soa-details.component.scss']
})
export class SoaDetailsComponent implements OnInit {
  displayedColumns = ['date', 'refNo', 'feeDescription', 'narration', 'debit', 'credit'];


  @Input()fromdate: string;
  @Input()todate: string;
  @Output() dates:EventEmitter<any>= new EventEmitter()
  summaryList: any;
  studentId: any;
  dataSource: any;
  isOnChange: boolean=false;
  schoolCurrency: string = '';
  invoiceDialog: any;
  htmlContent: any;
  content: SafeHtml;
  detailsPDFSub: Subscription;
  schoolName: string = '';
  studentName: string = '';
  studentGrade: string = '';
  invoiceNo: any;

  constructor(private datePipe: DatePipe, private paymentService:PaymentService, private router:ActivatedRoute,
    private dialog: MatDialog, private sanitizer: DomSanitizer) {

   }

   ngOnChanges(): void {
    this.isOnChange=true;
    this.router.queryParams.subscribe(params=>{
      this.studentId = params?.id
    })
    this.getPaymentSummaryDetails();

     this.detailsPDFSub = this.paymentService.downloadDetailsPDF.subscribe((res: any) => {
       if (res == "1") {
         this.downloadAsPDF();
       }
     });
   }
  ngOnInit(): void {
    this.schoolName = localStorage.getItem('schoolName');
    this.schoolCurrency= localStorage.getItem('bsu_currency');
    if(!this.isOnChange)
    {
    this.router.queryParams.subscribe(params=>{
      this.studentId = params?.id
    })
    this.getPaymentSummaryDetails()
  }

    let transportChildInfo = JSON.parse(localStorage.getItem("transportChildInfo"));
    let childInfo = transportChildInfo.filter((x: any) => x.studentNo == this.studentId);
    this.studentName = childInfo[0]?.studentName;
    this.studentGrade = childInfo[0]?.grade;
  }

  ngOnDestroy(){
    this.detailsPDFSub?.unsubscribe();
  }

  changeServiceDate(event, value) {
    let serviceDate = event.value._d;
    let data: any;
    if (value == 'fdate') {
      this.fromdate = this.datePipe.transform(serviceDate, 'yyyy-MM-dd');
    } else {
      this.todate = this.datePipe.transform(serviceDate, 'yyyy-MM-dd');
    }
  }

  clear() {
    this.todate = '';
    this.fromdate = '';
  }

  getPaymentSummaryDetails(){
   let data:any;
   data={
    stuid:this.studentId,
    fromDate:this.fromdate,
    toDate:this.todate
   }
   this.dates.emit(data);
    this.paymentService.getPaymentSummaryDetails(data).subscribe(
      res=>{
        this.summaryList = res;
        this.dataSource = this.summaryList;
        console.log('dataSource', this.dataSource)
      }
    )
  }

  downloadAsPDF() {
    let data: any;
    data = {
      stuid: this.studentId,
      fromDate: this.fromdate,
      toDate: this.todate,
      studentName: this.studentName,
      studentGrade: this.studentGrade,
      schoolName: this.schoolName
    }
    this.paymentService.downloadStatementOfAccountsDetailsPdf(data).subscribe(
      (res:any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        saveAs(blob, "Details.pdf");
        this.paymentService.downloadDetailsPDF.next("0");
      }
    )
  }

  getTaxInvoice(refNo:any,template:any)
  {
    let data={
      receiptNo:refNo,
      studNo:this.studentId
    }
    this.invoiceNo = refNo;

    this.paymentService.getReceipt(data).subscribe(res=>{
      this.htmlContent=res?.data
      this.receiptDialog(template);
    });
  }

  get sanitizedPdfHtml(): SafeHtml {
    this.content=this.sanitizer.bypassSecurityTrustHtml(this.htmlContent ?? '');
    return this.content }

  receiptDialog(template: TemplateRef<any>) {
    this.invoiceDialog= this.dialog.open(template, {
       disableClose: true,
       width: '1100px',
       panelClass: 'custom-modalbox'
     });
   }

   convertHtmlToPdf(htmlContent: string, filename: string) {
    // Create a new element to hold the HTML content
    const element = document.createElement('div');
    element.innerHTML = htmlContent;

    // Append the element to the document body
    document.body.appendChild(element);

    // Define options for html2pdf
    const options = {
      margin: [0.5, 0.5, 0.5, 0.5], // margins: top, right, bottom, left
      filename: 'document.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    html2pdf().from(element).set(options).outputPdf('blob').then((blob: Blob) => {
      document.body.removeChild(element);

      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.invoiceNo + ".pdf";
      a.click();
      URL.revokeObjectURL(url);
    });
  }

   downloadInvoiceAsPDF(){
    this.convertHtmlToPdf(this.htmlContent, this.invoiceNo);
   }

   removeMetadata(html: string): string {
    const metadataRegex = /<\s*(meta|title|link|style|script)[^>]*>.*?<\/\s*\1\s*>/gi;
    const nonContentRegex = /<\s*(head|script|style)[^>]*>.*?<\/\s*\1\s*>/gi;

    html = html.replace(metadataRegex, '');
    html = html.replace(nonContentRegex, '');
    return html;
  }
}

