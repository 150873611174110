import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatementOfAccountsComponent } from './statement-of-accounts/statement-of-accounts.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { PaymentsRoutingModule } from './payments-routing.module';
import {MatTabsModule} from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { SoaDetailsComponent } from './statement-of-accounts/soa-details/soa-details.component';
import { SoaSummaryComponent } from './statement-of-accounts/soa-summary/soa-summary.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { PaymentDetailComponent } from './payment-detail/payment-detail.component';
import {MatSliderModule} from '@angular/material/slider';
import { PaymentRefundComponent } from './statement-of-accounts/payment-refund/payment-refund.component';
import { MatTooltipModule} from '@angular/material/tooltip';
import { ReceiptsComponent } from './receipts/receipts.component';
import {MatTableModule} from '@angular/material/table';
import { DateFormatPipe } from 'src/app/models/date-format.pipe';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import { LanguageTranslateService } from 'src/app/service/translatelanguageservice.service';
import { MessageService } from '@progress/kendo-angular-l10n';


@NgModule({
  declarations: [
    StatementOfAccountsComponent,
    PaymentHistoryComponent,
    SoaDetailsComponent,
    SoaSummaryComponent,
    PaymentDetailComponent,
    PaymentRefundComponent,
    ReceiptsComponent,
    DateFormatPipe
  ],
  exports: [PaymentsRoutingModule],
  imports: [
    CommonModule,
    PaymentsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule, MatInputModule, MatDatepickerModule,
    MatTabsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatSliderModule,
    MatTooltipModule,
    MatTableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    })
  ],
  providers:[TranslateService,
    MessageService]
})
export class PaymentsModule {
  constructor(private messages: MessageService,private translate:TranslateService){
    
    translate.addLangs(['en', 'ar']);
    interval(1000).subscribe(() => {
      let currentLanguage:any = localStorage.getItem('lang');
      currentLanguage?translate.use(currentLanguage):translate.setDefaultLang('en');
      const svc: any = <LanguageTranslateService>this.messages;
      svc.language = currentLanguage?currentLanguage:'en';
    });
  }
 }
