import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, timer } from 'rxjs';
import { MessageComponent } from 'src/app/message/message.component';
import { CommonService } from 'src/app/service/common.service';
import { DashboardService } from 'src/app/service/dashboard.service';
import { FileService } from 'src/app/service/file.service';
import { SupportService } from 'src/app/service/support.service';
import { UserprofileupdateService } from 'src/app/service/userprofileupdate.service';
import { TransportserviceService } from 'src/app/transportservice.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile-widget',
  templateUrl: './profile-widget.component.html',
  styleUrls: ['./profile-widget.component.scss']
})
export class ProfileWidgetComponent implements OnInit {

  progress = 0;

  showOverdue: boolean=false;
  todayDate: number;
  showWarning: boolean=false;
  currentMonth: string;
  currentYear: number;
  lastDate: number;
  file: any;
  fileType: any;
  fileFormatErr: boolean;
  selectedFile: any;
  imageUrl: any;
  oldImage: any;
  paymentDueDetails: any;
  paymentDueDate: any;
  isLeasedSchool: any;
  IsHybrid:any;
  isdata:boolean =false
  contactBusDetails: any;
  busContact: string;
  langTrans: any;
  constructor(private transportService:TransportserviceService, private userprofileimageservice: UserprofileupdateService, private commonService: CommonService,private dashboardservice : DashboardService, private fileService:FileService, private dialog:MatDialog,
    private supportService:SupportService, private translate: TranslateService) { }
  studentProfile:any;

  trackingData: any;
  trackingTimer=60000;
  busTracker!: Subscription;
  fromPosition: string = 'school';
  toPosition: string = 'home';
  busNumber: string = '';

  fromTime: string = '';
  toTime: string = '';
  isRtl: boolean = false;
  showBusInProgress: boolean = true;

  ngOnInit(): void {
    this.checkDate()
    this.transportService.getTransportInfoOfChildren().subscribe(res=>{
      localStorage.setItem('leasemodelschool',res?.data[0].leasemodelschool)
      localStorage.setItem('IsHybrid',res?.data[0]?.ishybrid)
      localStorage.setItem('transportChildInfo',JSON.stringify(res?.data))

      let selectedStudentId = localStorage.getItem('selectedstudentid');
      let selectedStudentData = res?.data.filter((x:any) => x.studentNo == selectedStudentId);
      localStorage.setItem('isReRegister',selectedStudentData[0]?.reregistered);

      localStorage.setItem('regClosedMessage',selectedStudentData[0]?.reG_MSG ?? "");

      let selectedStudent=JSON.parse( localStorage.parentChildrens)[0];
      this.isLeasedSchool = res?.data[0]?.leasemodelschool;
      this.IsHybrid= res?.data[0]?.ishybrid;
      let index = res?.data.findIndex((x:any)=>x.studentNo == selectedStudent.studentId)
      if(index != -1)
      {
      res.data[index].grade = res?.data[index]?.grade.replace("/","-");
      //For Pascal Case
      let splits = res?.data[index]?.school?.split(" ");
      let school:any='';
      splits?.forEach((word) => {
        word = word.toLowerCase();
        let tempWord=word;
        let firstLetter = tempWord.charAt(0).toUpperCase();
        word=word.slice(1);
        word=firstLetter+word;
        school=school+word+" ";
      });

      res.data[index].school= school;
      res.data[index].pickuptime=res?.data[index]?.pickuptime?.replace('AM',' AM')
      res.data[index].dropofftime=res?.data[index]?.dropofftime?.replace('PM',' PM')
      this.studentProfile={...res?.data[index]}
      console.log(this.studentProfile);

      this.oldImage=this.studentProfile?.photoPath;
      this.paymentDue()
      this.getBusdetails();
      this.getBusTrackingDetails();
      this.getLiveTrackingDetails();
    }

     // this.studentProfile={...res?.data.filter(s=>s.studentNo==localStorage.selectedstudentid) [0]};

    })
 // }

    this.translate.stream('home').subscribe(async (data: any) => {
      if (data != null && data != undefined) {
        this.langTrans = data;
      }
    });
  }

  // ngOnChange(){
  //   this.isRtl = localStorage.getItem('lang') == 'ar'
  // }


  ngOnDestroy(): void {
    if (this.busTracker) {
      this.busTracker.unsubscribe();
    }
  }


  getBusdetails()
  {
    this.supportService.getBusContact().subscribe((resp:any)=>{
      this.contactBusDetails = resp?.data[0];
      if((this.contactBusDetails.diverMobile.length == 12 && (this.contactBusDetails.diverMobile.slice(0, 3) == "971")) || (this.contactBusDetails.diverMobile.length == 11 && (this.contactBusDetails.diverMobile.slice(0, 3) == "974")))
      {
        this.busContact = "+"+[this.contactBusDetails.diverMobile.slice(0, 3)+" "+this.contactBusDetails.diverMobile.slice(3,5) +" "+this.contactBusDetails.diverMobile.slice(5,8)+" "+this.contactBusDetails.diverMobile.slice(8,12)].join('');
      }
      else{
        this.busContact = this.contactBusDetails.diverMobile
        }
    })
  }
  checkDate(){
    let monthList = ['Jan', 'Feb' ,'Mar','Apr','May','June','July', 'Aug' ,'Sept','Oct','Nov','Dec']
    let today = new Date();
    let month :any=today.getMonth();
    this.currentMonth = monthList[month];
    this.currentYear = today.getFullYear();

    if(today.getMonth() <= 11){
      month = today.getMonth() + 1;
    }
    var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    this.lastDate = lastDay.getDate()
    console.log(today.getDate(),lastDay.getDate() );

    if(today.getDate() > 10){
      if(today.getDate() > (lastDay.getDate() -7) ){
        this.showOverdue = true;
        this.showWarning = false;
        console.log('date is in lastweek')


      }else{
        this.showOverdue = false;
        this.showWarning = true;
        console.log('date is not in lastweek')

      }


    }else{
      this.showOverdue = false;
      this.showWarning = false;

      console.log('not overdue');


    }
let isPaymentMessage = (this.showOverdue && this.showWarning) == false ? false : true;
this.dashboardservice.isPaymentMessage.next(isPaymentMessage);

  }
paymentDue(){
  this.transportService.getStsPaymentDue().subscribe(
    res=>{
      this.paymentDueDetails = res?.data;
       this.isdata=true
      let date = res?.data?.paymentDueDate?.split(' ');
      if (date != null && date != undefined) {
        console.log(date);

        this.paymentDueDate = `${date[1] + ' ' + date[0] + ' ' + date[2]}`
      }
    }
  )
}
  updatePhoto(){
    let data:any;
    let selectedStudent=JSON.parse( localStorage.parentChildrens)[0];
    data={
      StudentId:selectedStudent.stuId.toString(),
      BsuId:JSON.parse(localStorage.getItem('currentUser')).schooId
    }
    this.dashboardservice.updateProfile(data).subscribe(
(res:any)=>{
  if(res?.success == 'false'){
    this.openMessageDialog(res?.message,true,'');
    this.studentProfile.photoPath= this.oldImage
  }else{
    this.openMessageDialog(this.langTrans?.label?.profileUpdatedSuccessfully,false,'');
    this.studentProfile.photoPath= this.imageUrl;
    this.userprofileimageservice.updateUserProfileImage(this.imageUrl);
    // this.dashboardservice.changeMemberPhoto(this.imageUrl);


  }
}
    )
  }
  selectedImgFile(event:any)
  {
     this.file= event.target.files[0];

    // localStorage.setItem('sprofileimg', JSON.stringify(this.file));

    if (this.file) {
      let splits=this.file.name.split(".");
     this.fileType=splits[1];
     if( this.fileType == "png" || this.fileType=="jpeg" || this.fileType=="jpg")
     {
      this.fileFormatErr=false;
      this.selectedFile = this.file;
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      this.fileService.setFile(this.file);
      reader.onload = (e: any) => {


        this.imageUrl = e.target.result;

        this.updatePhoto();
      };
    }
    else {this.fileFormatErr=true;
      this.imageUrl =""}
      this.dashboardservice.fileErrMessage.next(this.fileFormatErr)
  }
  }
  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    })

  }

  formattedTime(val: any){
    if(val!=null && val!=undefined && val !='' ){
      var ts = val;
      var H = +ts.substr(0, 2);
      var h: any = (H % 12) || 12;
      h = (h < 10) ? ("0" + h) : h;  // leading 0 at the left for 1 digit hours
      var ampm = H < 12 ? " AM" : " PM";
      ts = h + ts.substr(2, 3) + ampm;
      return ts;
    }
    return '--:--';
  }

  getBusTrackingDetails(){
    this.transportService.getBusTrackingDetails().subscribe((response:any) => {

      this.trackingData = response?.data[0];

      this.formatTrackingData();
    }, error => {
    });
  }

  getLiveTrackingDetails() {
    this.busTracker = timer(this.trackingTimer, this.trackingTimer).subscribe(subscription => {
      this.transportService.getBusTrackingDetails().subscribe((response:any) => {

        this.trackingData = response?.data[0];

        this.formatTrackingData();
      }, error => {
      });
    });
  }

  private formatTrackingData() {
    this.fromTime = (this.trackingData?.position == "Home" && this.trackingData?.trip == "Morning") || (this.trackingData?.position === 'School' && this.trackingData?.trip == "Afternoon") ? this.formattedTime(this.trackingData?.exp_time_departure) : this.formattedTime(this.trackingData?.act_time_departure);
    this.toTime = (this.trackingData?.position == "School" && this.trackingData?.trip == "Morning") || (this.trackingData?.position === 'Home' && this.trackingData?.trip == "Afternoon") ? this.formattedTime(this.trackingData?.act_time_arrival) : this.formattedTime(this.trackingData?.exp_time_arrival);

    this.busNumber = this.trackingData?.trip === 'Morning' ? this.studentProfile?.pickupBusNo : this.studentProfile?.dropOffBusNo;
    this.fromPosition = ((this.trackingData?.position === 'Home' || this.trackingData?.position === 'Bus' || this.trackingData?.position === 'School') && this.trackingData?.trip === 'Morning') ? "home" : "school";
    this.toPosition = ((this.trackingData?.position === 'Home' || this.trackingData?.position === 'Bus' || this.trackingData?.position === 'School') && this.trackingData?.trip === 'Morning') ? "school" : "home";

    this.progress = this.trackingData?.distance;
    if (this.progress == 100) {
      if (this.busTracker) {
        this.showBusInProgress = false;
        this.busTracker.unsubscribe();
      }
    }
  }
}
