import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SupportService } from 'src/app/service/support.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqDetails: any[]=[];
  private languageChangeSubscription: Subscription;
  constructor(private supportService:SupportService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.faqDetails=[];
    this.getFAQs(localStorage.getItem('lang'));

    this.languageChangeSubscription = this.translate.onLangChange.subscribe((event) => {
      this.getFAQs(event.lang);
    });
  }

  ngOnDestroy(): void {
    if (this.languageChangeSubscription) {
      this.languageChangeSubscription.unsubscribe();
    }
  }

  private getFAQs(lang: string) {
    this.supportService.getFAQs(lang).subscribe(
      (res: any) => {
        this.faqDetails = res?.data;
      }
    );
  }
}
