import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageComponent } from 'src/app/message/message.component';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  isPasswordLength: boolean=true;
  password: any='';
  isAlphabets: boolean=true;
  isNumbers: boolean=true;
  passwordNew:any;
  passwordConfirm:any=''
  isConfirmPasswordRequired: boolean=false;
  ispasswordConfirmed: boolean=false;
  passwordOld:any=''
  @ViewChild("newPassword") newPasswordInput!: MatInput;
  @ViewChild("confirmPassword") confirmPasswordInput!: MatInput;
  @ViewChild("oldPassword") oldPasswordInput!: MatInput;
  isNewPasswordVisible: boolean=false;
  isOldPasswordVisible: boolean=false;
  isConfirmPasswordVisible: boolean=false;
  resErr:boolean=false;
  checkNewOld:boolean=false;
  langTrans: any;
  constructor( private router: Router,public service:DashboardService,private dialog: MatDialog, private translate: TranslateService) { }

  ngOnInit(): void {

    this.translate.stream('settings').subscribe(async (data: any) => {
      if (data != null && data != undefined) {
        this.langTrans = data.messages;
      }
    });
  }
  public changeNewPassword(value: any) {
    if(!this.isNewPasswordVisible)
    this.hidePasswordForNew();
    this.checkNewOld=false;
    this.ispasswordConfirmed = false;
      this.password = value;
      if (value.length < 8 || value.length > 16) {
        this.isPasswordLength = true
      } else {
        this.isPasswordLength = false
      }

      if (this.checkAlphabets(value)) {
        this.isAlphabets = false;
      }
      else {
        this.isAlphabets = true;
      }
      if ((this.checkNumerics(value)) && (this.hasSpecialChars(value))) {
        this.isNumbers = false;
      }
      else {
        this.isNumbers = true;
      }
  }

  public changeOldpassword() {
    this.checkNewOld=false;
    if(!this.isOldPasswordVisible)
    this.hidePasswordForOld();
  }

  public checkconfirmpassword(value: any) {
    if(!this.isConfirmPasswordVisible)
    this.hidePasswordForConfirm();
      if (value == '') {
        this.isConfirmPasswordRequired = true
      } else {
        this.isConfirmPasswordRequired = false
      }
      if (value == this.passwordNew) {
        this.ispasswordConfirmed = false;
      } else {
        this.ispasswordConfirmed = true;

      }
  }

  public checkAlphabets(text: any) {
    return text.match(/(?=.*[a-z])(?=.*[A-Z])/) ? true : false;

  }
  public checkNumerics(text: any) {
    return text.match(/(?=.*[0-9])/) ? true : false;

  }
  public hasSpecialChars(str: any) {
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (format.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  public hidePasswordForNew() {

    if (this.newPasswordInput) {
      this.newPasswordInput.type = "password";
      this.isNewPasswordVisible = false;
    }

  }
  public showPasswordForNew() {

    if (this.newPasswordInput) {
      this.newPasswordInput.type = "text";
      this.isNewPasswordVisible = true;
    }
  }

  public hidePasswordForOld() {

    if (this.oldPasswordInput) {
      this.oldPasswordInput.type = "password";
      this.isOldPasswordVisible = false;
    }

  }
  public showPasswordForOld() {

    if (this.oldPasswordInput) {
      this.oldPasswordInput.type = "text";
      this.isOldPasswordVisible = true;
    }
  }
  public hidePasswordForConfirm() {

    if (this.confirmPasswordInput) {
      this.confirmPasswordInput.type = "password";
      this.isConfirmPasswordVisible = false;
    }

  }
  public showPasswordForConfirm() {

    if (this.confirmPasswordInput) {
      this.confirmPasswordInput.type = "text";
      this.isConfirmPasswordVisible = true;
    }
  }

  cancel()
  {
    this.router.navigate(['/settings']);
  }

  submit()
  {
    this.checkconfirmpassword(this.passwordConfirm);
    if(!this.isConfirmPasswordRequired && !this.ispasswordConfirmed && !this.isAlphabets && !this.isNumbers && !this.isPasswordLength)
    {
      if(this.passwordOld != this.passwordNew)
      {
        this.checkNewOld=false;
    let body={
    old:this.passwordOld,
    new:this.passwordNew,
    confirm:this.passwordConfirm
    }
    this.service.changePassword(body).subscribe((res:any)=>{
    if(res?.bPasswordChanged)
    {
      this.resErr=false;
    this.openMessageDialog(this.langTrans?.passwordUpdatedSuccessfully,false,'')
    }
    else 
    {
      this.resErr=true;
      this.openMessageDialog(this.langTrans?.oldPasswordMismatched,true,'')
    }
    })
  }else this.checkNewOld=true
  }
  }

  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
     if(!this.resErr)
      this.cancel();
    });
  }
}
