import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { DashboardService } from 'src/app/service/dashboard.service';
import { RegistrationService } from 'src/app/service/registration.service';
import * as CryptoJS from 'crypto-js';
import { contactChangeService } from 'src/app/service/contactChange.service';
import { MessageComponent } from 'src/app/message/message.component';
import { CountryCode } from 'ngx-mat-intl-tel-input/lib/data/country-code';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-request-for-contact-change',
  templateUrl: './request-for-contact-change.component.html',
  styleUrls: ['./request-for-contact-change.component.scss']
})
export class RequestForContactChangeComponent implements OnInit {
  panelOpenState = false;
  digopen: any;
  contactDetails:any;
  secondaryToggle:boolean=false;
  otherToggle:boolean=false;
  public countries=['ae', 'qa'];
  public relation=[{value:"Father" , id:1},{value:"Mother",id:2},{value:"Guardian",id:3}]
  isUAENoValidPrimary: boolean=false;
  isUAENoValidOther: boolean=false;
  isUAENoValidSecondary: boolean=false;
  selectedCountries:any;
  @ViewChild('primaryMobile') primaryMobile: NgxMatIntlTelInputComponent;
  @ViewChild('secondaryMobile') secondaryMobile: NgxMatIntlTelInputComponent;
  @ViewChild('otherMobile') otherMobile: NgxMatIntlTelInputComponent;
  primaryForm = new FormGroup({
    primaryFname: new FormControl('', Validators.required),
    primaryMname: new FormControl(''),
    primaryLname: new FormControl('', Validators.required),
    primaryMobile: new FormControl('', [Validators.required]),
    primaryEmail: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]),
    primaryReEmail: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')])
  });

  secondaryForm = new FormGroup({
    secondaryFname: new FormControl('', Validators.required),
    secondaryMname: new FormControl(''),
    secondaryLname: new FormControl('', Validators.required),
    secondaryMobile: new FormControl('', [Validators.required]),
    secondaryEmail: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]),
    secondaryReEmail: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')])
  });

  otherForm = new FormGroup({
    otherRelation: new FormControl('', Validators.required),
    otherFullname: new FormControl('', Validators.required),
    otherMobile: new FormControl('', [Validators.required]),
    otherEmail: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]),
    otherReEmail: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')])
  });
  nationalnumber: any;
  IsPrimaryEmailMatch: boolean=false;
  IsSecondaryEmailMatch: boolean=false;
  IsOtherEmailMatch: boolean=false;
  otpValue1: any='';
  otpValue2: any='';
  otpValue3: any='';
  otpValue4: any='';
  otpValue5: any='';
  otpValue6: any='';
  @ViewChild("otp1") otp1: ElementRef;
  @ViewChild("otp2") otp2: ElementRef;
  @ViewChild("otp3") otp3: ElementRef;
  @ViewChild("otp4") otp4: ElementRef;
  @ViewChild("otp5") otp5: ElementRef;
  @ViewChild("otp6") otp6: ElementRef;
  enableOTP: boolean = false;
  IsMobileVerified:boolean=false;
  isVerifyOTP: boolean=false;
  isResendOtp: boolean = false;
  token: any;
  resendcount: any=0;
  isOtpEntered: boolean = false;
  interval: any;
  countdown: number = 0;
  count:any=0
  encOTP: any;
  enteredOTP: any = '';
  isInvalidOTP: boolean = false;
  showEmail:boolean=false;
  isSendOtp:boolean=false;
  primaryPhnNumber: string;
  secondaryPhnNumber: string;
  otherPhnNumber: string;
  
  dubaiCountryId = '172';
  qatarCountryId = '97';
  successfullyVerifiedMsg: string = "Mobile number successfully verified.";
  constructor(private dialog: MatDialog,public dashboardService:DashboardService,private router:Router,public registrationService:RegistrationService, public contactService:contactChangeService, private translate: TranslateService) { 
    this.getContactDetails();
  }

  ngOnInit(): void {
    this.token=JSON.parse(localStorage.getItem('currentToken'))?.access_token
    let schooldata:any;
    schooldata = JSON.parse(localStorage.getItem('schoolDetails'));

    if (schooldata?.country != null && schooldata?.country != undefined && schooldata?.country != "") {
      if (schooldata.country == "UAE")
        this.selectedCountries = this.countries[0]
      else
        this.selectedCountries = this.countries[1]
    } else {
      let childInfo = JSON.parse(localStorage.getItem('parentChildrens'));
      let schoolCountryId = childInfo[0]?.schoolCountryId?.trim();

      if (schoolCountryId == this.dubaiCountryId) {
        this.selectedCountries = this.countries[0]
      } else {
        this.selectedCountries = this.countries[1]
      }
    }
  //this.getContactDetails();

  this.translate.stream('registration').subscribe(async (data: any) => {
    if (data != null && data != undefined) {
      this.successfullyVerifiedMsg = data.label.successfullyVerifiedMsg;
    }
  });
  }

  getContactDetails()
  {
    this.dashboardService.contactDetails.subscribe(res=>{
      this.contactDetails=res
      if(this.contactDetails){
      if(this.selectedCountries=="qa" && this.contactDetails.primaryContactNumber)
    {
      if(this.contactDetails.primaryContactNumber.length == 8)
      this.contactDetails.primaryContactNumber = "+974"+this.contactDetails.primaryContactNumber;
      else{
        this.contactDetails.primaryContactNumber= this.contactDetails.primaryContactNumber.substr(-8)
        this.contactDetails.primaryContactNumber = "+974"+this.contactDetails.primaryContactNumber;
      }
    }   
    else if(this.selectedCountries=="ae"&& this.contactDetails.primaryContactNumber)
    {
      this.contactDetails.primaryContactNumber = this.contactDetails.primaryContactNumber.includes("+971")?this.contactDetails.primaryContactNumber.replace("+971",''): this.contactDetails.primaryContactNumber;
      if(this.contactDetails.primaryContactNumber.length == 9)
      this.contactDetails.primaryContactNumber = "+971"+this.contactDetails.primaryContactNumber;

      else if(this.contactDetails.primaryContactNumber.length == 8){
        this.contactDetails.primaryContactNumber= this.contactDetails.primaryContactNumber.substr(-8)
        this.contactDetails.primaryContactNumber = "+971"+this.contactDetails.primaryContactNumber;
      }
      else{
        this.contactDetails.primaryContactNumber= this.contactDetails.primaryContactNumber.substr(-9)
        this.contactDetails.primaryContactNumber = "+971"+this.contactDetails.primaryContactNumber;
      }
    }

    if(this.selectedCountries=="qa" && this.contactDetails.secondaryContactNumber)
    {
      if(this.contactDetails.secondaryContactNumber.length == 8)
      this.contactDetails.secondaryContactNumber = "+974"+this.contactDetails.secondaryContactNumber;
      else{
        this.contactDetails.secondaryContactNumber= this.contactDetails.secondaryContactNumber.substr(-8)
        this.contactDetails.secondaryContactNumber = "+974"+this.contactDetails.secondaryContactNumber;
      }
    }   
    else if(this.selectedCountries=="ae"&& this.contactDetails.secondaryContactNumber)
    {
      this.contactDetails.secondaryContactNumber = this.contactDetails.secondaryContactNumber.includes("+971")?this.contactDetails.secondaryContactNumber.replace("+971",''): this.contactDetails.secondaryContactNumber;
      if(this.contactDetails.secondaryContactNumber.length == 9)
      this.contactDetails.secondaryContactNumber = "+971"+this.contactDetails.secondaryContactNumber;

      else if(this.contactDetails.secondaryContactNumber.length == 8){
        this.contactDetails.secondaryContactNumber= this.contactDetails.secondaryContactNumber.substr(-8)
        this.contactDetails.secondaryContactNumber = "+971"+this.contactDetails.secondaryContactNumber;
      }
      else{
        this.contactDetails.secondaryContactNumber= this.contactDetails.secondaryContactNumber.substr(-9)
        this.contactDetails.secondaryContactNumber = "+971"+this.contactDetails.secondaryContactNumber;
      }
    }

    if(this.selectedCountries=="qa" && this.contactDetails.guardianContactNumber)
    {
      if(this.contactDetails.guardianContactNumber.length == 8)
      this.contactDetails.guardianContactNumber = "+974"+this.contactDetails.guardianContactNumber;
      else{
        this.contactDetails.guardianContactNumber= this.contactDetails.guardianContactNumber.substr(-8)
        this.contactDetails.guardianContactNumber = "+974"+this.contactDetails.guardianContactNumber;
      }
    }   
    else if(this.selectedCountries=="ae"&& this.contactDetails.guardianContactNumber)
    {
      this.contactDetails.guardianContactNumber = this.contactDetails.guardianContactNumber.includes("+971")?this.contactDetails.guardianContactNumber.replace("+971",''): this.contactDetails.guardianContactNumber;
      if(this.contactDetails.guardianContactNumber.length == 9)
      this.contactDetails.guardianContactNumber = "+971"+this.contactDetails.guardianContactNumber;
      
      else if(this.contactDetails.guardianContactNumber.length == 8){
        this.contactDetails.guardianContactNumber= this.contactDetails.guardianContactNumber.substr(-8)
        this.contactDetails.guardianContactNumber = "+971"+this.contactDetails.guardianContactNumber;
      }

      else{
        this.contactDetails.guardianContactNumber= this.contactDetails.guardianContactNumber.substr(-9)
        this.contactDetails.guardianContactNumber = "+971"+this.contactDetails.guardianContactNumber;
      }
    }
    let guardian=this.contactDetails?.guardianName.split(' ')
      this.primaryForm.patchValue({
        primaryMobile:this.contactDetails?.primaryContactNumber,
        primaryFname:this.contactDetails?.primaryContactRelation == "Father" ? this.contactDetails?.stS_FFIRSTNAME : this.contactDetails?.primaryContactRelation == "Mother" ? this.contactDetails?.stS_MFIRSTNAME : guardian[0],
        primaryMname:this.contactDetails?.primaryContactRelation == "Father" ? this.contactDetails?.stS_FMIDNAME :  this.contactDetails?.primaryContactRelation == "Mother" ? this.contactDetails?.stS_MMIDNAME : guardian.length > 2 ? guardian[1]:'',
        primaryLname:this.contactDetails?.primaryContactRelation == "Father" ? this.contactDetails?.stS_FLASTNAME : this.contactDetails?.primaryContactRelation == "Mother" ? this.contactDetails?.stS_MLASTNAME : guardian.length == 2 ? guardian[1]:guardian.length > 2 ? guardian[2]:'',
        primaryEmail:this.contactDetails?.primaryContactEmailAddress,
        primaryReEmail: this.contactDetails?.primaryContactEmailAddress
      })

      this.secondaryForm.patchValue({
        secondaryMobile:this.contactDetails?.secondaryContactNumber,
        secondaryFname:this.contactDetails?.secondaryContactRelation == "Mother" ? this.contactDetails?.stS_MFIRSTNAME : this.contactDetails?.secondaryContactRelation == "Father" ? this.contactDetails?.stS_FFIRSTNAME: guardian[0],
        secondaryMname:this.contactDetails?.secondaryContactRelation == "Mother" ? this.contactDetails?.stS_MMIDNAME : this.contactDetails?.secondaryContactRelation == "Father" ? this.contactDetails?.stS_FMIDNAME: guardian.length > 2 ? guardian[1]:'',
        secondaryLname:this.contactDetails?.secondaryContactRelation == "Mother" ? this.contactDetails?.stS_MLASTNAME :this.contactDetails?.secondaryContactRelation == "Father" ? this.contactDetails?.stS_FLASTNAME:  guardian.length == 2 ? guardian[1]:guardian.length > 2 ? guardian[2]:'',
        secondaryEmail:this.contactDetails?.secondaryContactEmailAddress,
        secondaryReEmail: this.contactDetails?.secondaryContactEmailAddress
      })

      if((this.contactDetails?.secondaryContactRelation == "Mother" && this.contactDetails?.primaryContactRelation == "Father") || ((this.contactDetails?.secondaryContactRelation == "Father" && this.contactDetails?.primaryContactRelation == "Mother")))
      {
        this.otherForm.patchValue({
          otherMobile:this.contactDetails?.guardianContactNumber,
          otherRelation:this.contactDetails?.guardianRelation,
          otherFullname:this.contactDetails?.guardianName,
          otherEmail:this.contactDetails?.guardianEmailAddress,
          otherReEmail:this.contactDetails?.guardianEmailAddress,
        })
      }else if((this.contactDetails?.secondaryContactRelation == "Guardian" && this.contactDetails?.primaryContactRelation == "Father") || (this.contactDetails?.secondaryContactRelation == "Father" && this.contactDetails?.primaryContactRelation == "Guardian"))
      {
        this.otherForm.patchValue({
          otherMobile:this.contactDetails?.stS_MMOBILE,
          otherRelation:"Mother",
          otherFullname:this.contactDetails?.stS_MFIRSTNAME+' '+this.contactDetails?.stS_MMIDNAME+' '+this.contactDetails?.stS_MLASTNAME,
          otherEmail:this.contactDetails?.stS_MEMAIL,
          otherReEmail:this.contactDetails?.stS_MEMAIL,
        })
      }else if((this.contactDetails?.secondaryContactRelation == "Guardian" && this.contactDetails?.primaryContactRelation == "Mother") || (this.contactDetails?.secondaryContactRelation == "Mother" && this.contactDetails?.primaryContactRelation == "Guardian"))
      {
        this.otherForm.patchValue({
          otherMobile:this.contactDetails?.stS_FMOBILE,
          otherRelation:"Father",
          otherFullname:this.contactDetails?.stS_FFIRSTNAME+' '+this.contactDetails?.stS_FMIDNAME+' '+this.contactDetails?.stS_FLASTNAME,
          otherEmail:this.contactDetails?.stS_FEMAIL,
          otherReEmail:this.contactDetails?.stS_FEMAIL,
        })
      }
     
      setTimeout(() => {
        if(this.selectedCountries =='ae')
        {
        this.formatPrimaryNumber();
        this.formatSecondaryNumber();
        this.formatOtherNumber();
        }
      }, 200);
     
    }
    })
  }

  sendotp() {
    this.isSendOtp=false;
    if(!this.secondaryToggle && !this.otherToggle)
    {
    if (this.primaryForm.get('primaryMobile').valid && !this.isUAENoValidPrimary) {

      this.verifyMobile(this.primaryPhnNumber, this.token);
    }
  } else if(this.secondaryToggle && !this.otherToggle)
  {
    if (this.secondaryForm.get('secondaryMobile').valid && !this.isUAENoValidSecondary) {

      this.verifyMobile(this.secondaryPhnNumber, this.token);
    }
  } else if(!this.secondaryToggle && this.otherToggle)
  {
    if (this.otherForm.get('otherMobile').valid && !this.isUAENoValidOther) {

      this.verifyMobile(this.otherPhnNumber, this.token);
    }
  }
  }


  reSend(event) {
    this.resendcount++;
    if(this.resendcount > 2){
      this.isResendOtp = false
    }else{
      this.isResendOtp = true

    }
    let mobNo =this.secondaryToggle ? this.secondaryPhnNumber : this.otherToggle ? this.otherPhnNumber:this.primaryPhnNumber
    this.verifyMobile(mobNo, this.token);
    this.isInvalidOTP = false;
    this.countdown = 0;
    clearInterval(this.interval);
   this.countdown = 60;
   this.startCountdown(event);
   this.otpValue1 = this.otp1.nativeElement.value="";
   this.otpValue2 = this.otp2.nativeElement.value="";
   this.otpValue3 = this.otp3.nativeElement.value="";
   this.otpValue4 = this.otp4.nativeElement.value="";
   this.otpValue5 = this.otp5.nativeElement.value="";
   this.otpValue6 = this.otp6.nativeElement.value="";

    this.count = 0;
    this.isVerifyOTP = false;

  }


   startCountdown(event: any) {

    this.isResendOtp = false;
    this.countdown = 60; 
    this.interval = setInterval(() => {
      if (this.countdown === 0) {
        clearInterval(this.interval);
        this.isResendOtp = true;
        this.isVerifyOTP = true;
        if(this.resendcount > 2){
          this.isResendOtp = false
        }else{
          this.isResendOtp = true
    
        }
      } else {
        this.countdown -= 1;
        this.isResendOtp = false;
        if(this.count > 2){
          this.isVerifyOTP = true;
          clearInterval(this.interval);
          this.countdown = 0;
          this.isResendOtp = true;
          if(this.resendcount > 2){
            this.isResendOtp = false
          }else{
            this.isResendOtp = true
      
          }
        }else{
          this.isVerifyOTP = false;

        }
      }
    }, 1000); 
  }

  getEnteredOTP() {

    if (this.otpValue1 != '' && this.otpValue2 != '' && this.otpValue3 != '' && this.otpValue4 != '' && this.otpValue5 != '' && this.otpValue6 != '') {
      this.enteredOTP = this.otpValue1 + this.otpValue2 + this.otpValue3 + this.otpValue4 + this.otpValue5 + this.otpValue6;
      this.isOtpEntered = false;
      this.isInvalidOTP = false;
    } else {
      this.isOtpEntered = true;
      this.isInvalidOTP = false;

    }
  }

  verifyMobile(data: any, token: any) {
    this.registrationService.getOTPfromMobile(data,"CONTACTCHANGE", token).subscribe(
      res => {
        if (res?.success == 'true') {

          this.enableOTP = true;
         clearInterval(this.interval);
         this.isResendOtp = false;
         this.countdown = 60;
          this.startCountdown(data);
         this.encOTP = res?.data[0].otp
        } else {
          this.IsMobileVerified = false;
          this.enableOTP = false;
        }
      }
    )
  }

  keytab(event: any, value: any) {
    this.otpValue1 = this.otp1.nativeElement.value;
    this.otpValue2 = this.otp2.nativeElement.value;
    this.otpValue3 = this.otp3.nativeElement.value;
    this.otpValue4 = this.otp4.nativeElement.value;
    this.otpValue5 = this.otp5.nativeElement.value;
    this.otpValue6 = this.otp6.nativeElement.value;

    if (event.code == 'Backspace' || event.code == undefined || event.code == 'Delete') {
      if (event.srcElement.name == 'otpValue1') {
        this.otp1.nativeElement.focus();
        if (event.code == undefined && this.otpValue1 == "")
          this.otpValue1 = '';
      }
      if (event.srcElement.name == 'otpValue2') {
        this.otp2.nativeElement.focus();
      }
      if (event.srcElement.name == 'otpValue3') {
        this.otp3.nativeElement.focus();
      }
      if (event.srcElement.name == 'otpValue4') {
        this.otp4.nativeElement.focus();
      }
      if (event.srcElement.name == 'otpValue5') {
        this.otp5.nativeElement.focus();
      }
      if (event.srcElement.name == 'otpValue6') {
        this.otp6.nativeElement.focus();
      }
    }
    else {
      if (value == 1) {
        if (this.otpValue1 != '') {
          this.otp2.nativeElement.focus();
        }

      }

      if (value == 2) {
        if (this.otpValue2 != '') {
          this.otp3.nativeElement.focus();
        }
      }

      if (value == 3) {
        if (this.otpValue3 != '') {
          this.otp4.nativeElement.focus();
        }
      }
      if (value == 4) {
        if (this.otpValue4 != '') {
          this.otp5.nativeElement.focus();
        }
      }
      if (value == 5) {
        if (this.otpValue5 != '') {
          this.otp6.nativeElement.focus();
        }
      }
      if (value == 6) {
        if (this.otpValue6 != '') {
          this.otp6.nativeElement.focus();
        }
      }
    }

  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  sha512Enc(val: string) {
    const hash = CryptoJS.SHA512(val);
    return hash.toString(CryptoJS.enc.Hex);
  }

  verifyOTP() {
    let otp: any = '';
      this.getEnteredOTP();
      this.showEmail=true;
      otp = this.sha512Enc(this.enteredOTP);
      if (this.encOTP == otp) {
        this.isInvalidOTP = false;
        let mobNo =this.secondaryToggle ? this.secondaryPhnNumber : this.otherToggle ? this.otherPhnNumber:this.primaryPhnNumber
        localStorage.setItem('primaryMobileNo', mobNo)
        this.getParentDetails();
      } else {
        this.isInvalidOTP = true;
        this.count++;
        if(this.count > 2){
          this.isVerifyOTP = true
        }else{
          this.isVerifyOTP = false
        }
      }
  }

  getParentDetails()
  {
    let mobNo =this.secondaryToggle ? this.secondaryPhnNumber : this.otherToggle ? this.otherPhnNumber:this.primaryPhnNumber
    this.registrationService.getParentsList(mobNo, this.token).subscribe(
      res => {
        if (res?.success == 'true') {
          this.IsMobileVerified = true;
          this.enableOTP = false;
          this.openMessageDialog(this.successfullyVerifiedMsg, false, "",'mobile');
        }else{
          this.IsMobileVerified = false;
        }
      })
  }

  formatPrimaryPhoneNumber($event, currentCtrl) {
    this.isSendOtp=true;
    let phoneNumber: any = parsePhoneNumberFromString('+' + currentCtrl.selectedCountry.dialCode + $event.srcElement.value);
    if (phoneNumber && phoneNumber.isValid()) {
      var string = phoneNumber;
      console.log(phoneNumber);

      let phone =''
      if(this.selectedCountries == 'ae'){
       
        phone = [string.nationalNumber.slice(0, 2)+"-"+string.nationalNumber.slice(2,9)].join('');
        this.isUAENoValidPrimary=phone.startsWith('5')? false:true;

      }else{
        phone = phoneNumber.nationalNumber
        this.isUAENoValidPrimary= false;
      }
       this.nationalnumber = phoneNumber.number
      $event.srcElement.value = phone    
      this.primaryPhnNumber =  this.primaryForm.controls.primaryMobile.value;
    }
  }
  formatSecondaryPhoneNumber($event, currentCtrl) {
    this.isSendOtp=this.secondaryToggle?true:false;
    let phoneNumber: any = parsePhoneNumberFromString('+' + currentCtrl.selectedCountry.dialCode + $event.srcElement.value);
    if (phoneNumber && phoneNumber.isValid()) {
      var string = phoneNumber;

      let phone =''
      if(this.selectedCountries == 'ae'){
        phone = [string.nationalNumber.slice(0, 2)+"-"+string.nationalNumber.slice(2,9)].join('');
        this.isUAENoValidSecondary=phone.startsWith('5')? false:true;
      }else{
        phone = phoneNumber.nationalNumber
        this.isUAENoValidSecondary=false
      }

      this.nationalnumber = phoneNumber.number

      $event.srcElement.value = phone
      this.secondaryPhnNumber =  this.secondaryForm.controls.secondaryMobile.value;
    }
  }
  formatOtherPhoneNumber($event, currentCtrl) {
    this.isSendOtp=this.otherToggle?true:false;
    let phoneNumber: any = parsePhoneNumberFromString('+' + currentCtrl.selectedCountry.dialCode + $event.srcElement.value);
    if (phoneNumber && phoneNumber.isValid()) {
      var string = phoneNumber;

      let phone =''
      if(this.selectedCountries == 'ae'){
        phone = [string.nationalNumber.slice(0, 2)+"-"+string.nationalNumber.slice(2,9)].join('');
        this.isUAENoValidOther=phone.startsWith('5')? false:true;
      }else{
        phone = phoneNumber.nationalNumber
        this.isUAENoValidOther=false;
      }

      this.nationalnumber = phoneNumber.number

      $event.srcElement.value = phone
      this.otherPhnNumber = this.otherForm.controls.otherMobile.value;
    }
  }
  formatPrimaryNumber() {
    if(this.primaryMobile.phoneNumber)
    {
      let countryCode = this.primaryMobile?.phoneNumber.slice(0, 3);
      this.primaryMobile.phoneNumber = countryCode=="971" ? this.primaryMobile?.phoneNumber.slice(3,12): this.primaryMobile?.phoneNumber;
    this.primaryMobile.phoneNumber= [this.primaryMobile?.phoneNumber.slice(0, 2)+"-"+this.primaryMobile?.phoneNumber.slice(2,9)].join('');
    this.primaryForm.patchValue({primaryMobile:this.primaryMobile.phoneNumber})
    this.primaryForm.controls.primaryMobile.setErrors(null)
    let number= this.primaryMobile.phoneNumber.replace('-','')
    this.primaryPhnNumber = "+971"+number;
    }
  }
  formatSecondaryNumber() {
    if(this.secondaryMobile.phoneNumber)
    {
      let countryCode = this.secondaryMobile?.phoneNumber.slice(0, 3);
      this.secondaryMobile.phoneNumber = countryCode=="971" ? this.secondaryMobile?.phoneNumber.slice(3,12): this.secondaryMobile?.phoneNumber;
    this.secondaryMobile.phoneNumber= [this.secondaryMobile?.phoneNumber.slice(0, 2)+"-"+this.secondaryMobile?.phoneNumber.slice(2,9)].join('');
    this.secondaryForm.patchValue({secondaryMobile:this.secondaryMobile.phoneNumber})
    this.secondaryForm.controls.secondaryMobile.setErrors(null)
    let number= this.secondaryMobile.phoneNumber.replace('-','')
    this.secondaryPhnNumber = "+971"+number;
    }
  }

  formatOtherNumber() {
    if(this.otherMobile.phoneNumber)
    {
      let countryCode = this.otherMobile?.phoneNumber.slice(0, 3);
      this.otherMobile.phoneNumber = countryCode=="971" ? this.otherMobile?.phoneNumber.slice(3,12): this.otherMobile?.phoneNumber;
    this.otherMobile.phoneNumber= [this.otherMobile?.phoneNumber.slice(0, 2)+"-"+this.otherMobile?.phoneNumber.slice(2,9)].join('');
    this.otherForm.patchValue({otherMobile:this.otherMobile.phoneNumber})
    this.otherForm.controls.otherMobile.setErrors(null)
    let number= this.otherMobile.phoneNumber.replace('-','')
    this.otherPhnNumber = "+971"+number;
    }
  }

 onToggle(event:any,type:any)
 {
   if(type == "other")
   {
   this.otherToggle = event.checked;
   this.secondaryToggle=!this.otherToggle 
   Object.keys(this.otherForm.controls).forEach(ctrl => {
    const control = this.otherForm.get(ctrl);
    control.markAsUntouched()
  });
   }
   else {
     this.secondaryToggle=event.checked;
     this.otherToggle=!this.secondaryToggle 
    // Object.keys(this.secondaryForm.controls).forEach(ctrl => {
    // const control = this.secondaryForm.get(ctrl);
    // control.markAsUntouched()
 // });
}
  this.otpValue1 = this.otp1=null;
  this.otpValue2 = this.otp2=null;
  this.otpValue3 = this.otp3=null;
  this.otpValue4 = this.otp4=null;
  this.otpValue5 = this.otp5=null;
  this.otpValue6 = this.otp6=null;
  this.enableOTP = false;
  this.IsMobileVerified=false;
  this.isVerifyOTP=false;
  this.isResendOtp = false
  this.resendcount=0;
  this.isOtpEntered = false;
 // this.interval=0;
  this.countdown = 0;
  this.count=0
  this.encOTP='';
  this.enteredOTP = '';
  this.isInvalidOTP= false;
  this.showEmail=false;
  this.isSendOtp=true
 }
 cancel()
 {
   this.router.navigate(["/home"]);
 }

 checkPrimaryEmail(value: any, type: any) {
  this.IsPrimaryEmailMatch = false;
  if (type == 1 && this.primaryForm.get('primaryReEmail').value != '') {
    if (value.toLowerCase() == this.primaryForm.get('primaryReEmail').value.toLowerCase()) {
      this.IsPrimaryEmailMatch = false;
    }
    else {
      this.IsPrimaryEmailMatch = true;
    }
  } else {
    if (this.primaryForm.get('primaryEmail').value.toLowerCase() == value.toLowerCase()) {
      this.IsPrimaryEmailMatch = false;
    }
    else {
      this.IsPrimaryEmailMatch = true;
    }
  }
}

checkSecondaryEmail(value: any, type: any) {
  this.IsSecondaryEmailMatch = false;
  if (type == 1 && this.secondaryForm.get('secondaryReEmail').value != '') {
    if (value.toLowerCase() == this.secondaryForm.get('secondaryReEmail').value.toLowerCase()) {
      this.IsSecondaryEmailMatch = false;
    }
    else {
      this.IsSecondaryEmailMatch = true;
    }
  } else {
    if (this.secondaryForm.get('secondaryEmail').value.toLowerCase() == value.toLowerCase()) {
      this.IsSecondaryEmailMatch = false;
    }
    else {
      this.IsSecondaryEmailMatch = true;
    }
  }
}

checkOtherEmail(value: any, type: any) {
  this.IsOtherEmailMatch = false;
  if (type == 1 && this.otherForm.get('otherReEmail').value != '') {
    if (value.toLowerCase() == this.otherForm.get('otherReEmail').value.toLowerCase()) {
      this.IsOtherEmailMatch = false;
    }
    else {
      this.IsOtherEmailMatch = true;
    }
  } else {
    if (this.otherForm.get('otherEmail').value.toLowerCase() == value.toLowerCase()) {
      this.IsOtherEmailMatch = false;
    }
    else {
      this.IsOtherEmailMatch = true;
    }
  }
}

handlePanelClick(event: Event) {
  event.stopPropagation();
}

postContactChange(template:any)
{
  let primaryContact=this.secondaryToggle ? this.contactDetails?.secondaryContactRelation : this.otherToggle ? this.otherForm?.controls.otherRelation.value:this.contactDetails?.primaryContactRelation
  let secondaryContact=this.secondaryToggle ? this.contactDetails?.primaryContactRelation : this.otherToggle ? this.contactDetails?. primaryContactRelation:this.contactDetails?.secondaryContactRelation
  let primaryEmail=this.secondaryToggle ? this.secondaryForm.get('secondaryEmail').value : this.otherToggle ? this.otherForm.get('otherEmail').value:this.primaryForm.get('primaryEmail').value
 let guardian=this.otherForm.get('otherFullname').value.split(' ')
  let body={
    "StuId":localStorage.getItem("selectedstudentid"),
    "PrimaryContact":primaryContact == "Father" ? "F" : primaryContact == "Mother" ? "M" : "G",
    "PrimaryContactEmail":primaryEmail,
    "SecondaryContact":secondaryContact == "Father" ? "F" : secondaryContact == "Mother" ? "M" : "G",
    "FatherFirstName":this.contactDetails?.primaryContactRelation == "Father"? this.primaryForm.get('primaryFname').value : this.contactDetails?.secondaryContactRelation == "Father"? this.secondaryForm.get('secondaryFname').value : guardian[0] ,
    "FatherLastName":this.contactDetails?.primaryContactRelation == "Father"? this.primaryForm.get('primaryLname').value : this.contactDetails?.secondaryContactRelation == "Father"? this.secondaryForm.get('secondaryLname').value : guardian.length == 2 ? guardian[1]:guardian.length > 2 ? guardian[2]:guardian[0] ,
    "FatherMiddleName":this.contactDetails?.primaryContactRelation == "Father"? this.primaryForm.get('primaryMname').value : this.contactDetails?.secondaryContactRelation == "Father"? this.secondaryForm.get('secondaryMname').value : guardian.length > 2 ? guardian[1]:'' ,
    "MotherFirstName":this.contactDetails?.primaryContactRelation == "Mother"? this.primaryForm.get('primaryFname').value : this.contactDetails?.secondaryContactRelation == "Mother"? this.secondaryForm.get('secondaryFname').value : guardian[0],
    "MotherLastName":this.contactDetails?.primaryContactRelation == "Mother"? this.primaryForm.get('primaryLname').value : this.contactDetails?.secondaryContactRelation == "Mother"? this.secondaryForm.get('secondaryLname').value :  guardian.length == 2 ? guardian[1]:guardian.length > 2 ? guardian[2]:guardian[0],
    "MotherMiddleName":this.contactDetails?.primaryContactRelation == "Mother"? this.primaryForm.get('primaryMname').value : this.contactDetails?.secondaryContactRelation == "Mother"? this.secondaryForm.get('secondaryMname').value : guardian.length > 2 ? guardian[1]:'',
    "GardenName":this.contactDetails?.primaryContactRelation == "Guardian"? this.primaryForm.get('primaryFname').value+' '+this.primaryForm.get('primaryMname').value+' '+this.primaryForm.get('primaryLname').value : this.contactDetails?.secondaryContactRelation == "Guardian"? this.secondaryForm.get('secondaryFname').value+' '+this.secondaryForm.get('secondaryMname').value+' '+this.secondaryForm.get('secondaryLname').value : this.otherForm.get('otherFullname').value,
    "Fmobile":this.contactDetails?.primaryContactRelation == "Father"? (this.primaryPhnNumber ? this.primaryPhnNumber:"") : this.contactDetails?.secondaryContactRelation == "Father"? (this.secondaryPhnNumber?this.secondaryPhnNumber : '') : (this.otherPhnNumber ? this.otherPhnNumber :''),
    "Mmobile":this.contactDetails?.primaryContactRelation == "Mother"? (this.primaryPhnNumber ? this.primaryPhnNumber:"") : this.contactDetails?.secondaryContactRelation == "Mother"?  (this.secondaryPhnNumber?this.secondaryPhnNumber : '') : (this.otherPhnNumber ? this.otherPhnNumber :''),
    "Gmobile":this.contactDetails?.primaryContactRelation == "Guardian"? (this.primaryPhnNumber ? this.primaryPhnNumber:"") : this.contactDetails?.secondaryContactRelation == "Guardian"?  (this.secondaryPhnNumber?this.secondaryPhnNumber : '') :(this.otherPhnNumber ? this.otherPhnNumber :''),
    "Source":"web"
  }
  this.contactService.postContactChange(body,this.token).subscribe((res:any)=>{
   if(res.success="true")
   this.successDialog(template);
  })
}

openMessageDialog(msg: string, iseror: boolean, errormessage: string, value?:any): void {
  let btnname:any;
  if(value == 'mobile'){
    btnname='Proceed'
  }
  const dialogRef = this.dialog.open(MessageComponent, {
    width: '500px',
    panelClass: 'custom-modalbox',
    data: {
      msg: msg,
      isError: iseror,
      errmessage: errormessage,
      buttonName:btnname
    },
  });

  dialogRef.afterClosed().subscribe(result => {
    // this.goToLogin()
  });
}
  successDialog(templateRef: TemplateRef<any>) {
    this.digopen = this.dialog.open(templateRef, {
      width: '550px',
      panelClass: 'custom-modalbox'
    });
  }
}
