import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription, forkJoin } from 'rxjs';
import { HomeService } from 'src/app/service/home.service';
import { TokenService } from 'src/app/service/token.service';

@Component({
  selector: 'app-sts-group',
  templateUrl: './sts-group.component.html',
  styleUrls: ['./sts-group.component.scss']
})
export class StsGroupComponent implements OnInit {
  latestNews: any[] = [];
  awards: any[]=[];
  accreditation: any[]=[];
  partnership: any[]=[];
  vision:any;
  defaultToken: any;
  private languageChangeSubscription: Subscription;
  customOptions: OwlOptions;

  constructor(public service:HomeService,public tokenService:TokenService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.updateCarouselDirection(localStorage.getItem('lang'));
    
    this.getToken();

    this.languageChangeSubscription = this.translate.onLangChange.subscribe((event) => {
      this.updateCarouselDirection(event.lang);
      this.getLatestNews(event.lang);
      this.getAwardSection(event.lang);
    });
  }

  ngOnDestroy(): void {
    if (this.languageChangeSubscription) {
      this.languageChangeSubscription.unsubscribe();
    }
  }

  getToken() {
    this.tokenService.GetToken(null).subscribe(
      res => {
        this.defaultToken = res?.access_token;
        this.getLatestNews(localStorage.getItem('lang'));
        this.getAwardSection(localStorage.getItem('lang'));
      })
    }
  getAwardSection(lang: string)
  {
    let services = [];
    services.push(this.service.getAwards(this.defaultToken, lang))
    services.push(this.service.getAccreditation(this.defaultToken, lang));
    services.push(this.service.getPartnership(this.defaultToken, lang));
    services.push(this.service.getVision(this.defaultToken, lang));
    forkJoin(services).subscribe((res: any) => {
      this.awards = [];
      this.accreditation = [];
      this.partnership = [];
      if (res) {
        if (res[0]?.length > 0) {
          this.awards = res[0];
        }
        if (res[1]?.length > 0) {
          this.accreditation = res[1];
        }
        if (res[2]?.length > 0) {
          this.partnership = res[2];
        }
        if (res[3]?.length > 0) {
          let data=res[3]
          this.vision = data[0].descriprtion.split('\n');
        }
      }
    })
  }
  getLatestNews(lang: string) {
    this.service.getNews(this.defaultToken, lang).subscribe(
      res => {
        if(res.length <=3)
        this.latestNews = res
        else this.latestNews =res.slice(0,3)
      })
  }

// swiper

redirectUrl(url:any)
{
  if(url)
  window.open(url, '_blank')
}

updateCarouselDirection(lang: string): void {
  const isRTL = lang === 'ar'; 
  
  this.customOptions = {
    rtl: isRTL,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    margin: 20,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      400: {
        items: 2,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      740: {
        items: 2,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      940: {
        items: 2,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      }
    },
    nav: false
  };
}

}
