import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TransportserviceService } from 'src/app/transportservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MatDialog
} from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReregistrationService } from 'src/app/service/reregistration.service';
import { RegistrationService } from 'src/app/service/registration.service';
import { DashboardService } from 'src/app/service/dashboard.service';

@Component({
  selector: 'app-transport-information',
  templateUrl: './transport-information.component.html',
  styleUrls: ['./transport-information.component.scss']
})
export class TransportInformationComponent implements OnInit {
  @ViewChild('reregisterTemplate') reregisterTemplate: any;
  @ViewChild('registrationClosedTemplate') registrationClosedTemplate: any;
  digopen: any;
  radiobtn: any = "1";
  noForm = new FormGroup<any>({
    reason: new FormControl<any>('', [Validators.required]),
    remarks: new FormControl<any>('')
  })
  count: number = 0;
  reason: any[] = [];
  currentUser: any;
  selectedStudent: any;
  isOthers: boolean = false;
  isReregisterEnable: boolean = false;
  contactDetails: any;
  token: any;
  regClosedMessage: string = '';
  enableRegiserButton: boolean = true;
  enableReRegisterBuggon: boolean = true;

  constructor(private transportService: TransportserviceService, public date: DatePipe, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, private router: Router, private dialog: MatDialog
    , private reregistrationService: ReregistrationService, public registerService: RegistrationService, public dashboardService: DashboardService, private route: ActivatedRoute) { }
  public responseData: any[] = [];
  ngOnInit(): void {

    this.renderer.addClass(this.document.body, 'rm-body-height');
    var root = document.getElementsByTagName('html')[0];
    this.renderer.addClass(root, 'rm-body-height');
    let bsuId = localStorage.getItem('bsU_ID');
    this.token = JSON.parse(localStorage.getItem('currentToken'))
    let schoolList = JSON.parse(localStorage.getItem('schoolList')).filter(a=>a.isReregisterEnable==true);
    this.isReregisterEnable = schoolList.find(x => x.bsU_ID == bsuId)?.isReregisterEnable;
    localStorage.removeItem('reregisterUsers')
    localStorage.removeItem('residentialInfo')
    localStorage.removeItem('medicalInfo');
    localStorage.removeItem('selectedSiblings');
    localStorage.removeItem('siblingPUTAgreed');
    localStorage.removeItem('termsForm');
    localStorage.removeItem('PUTAgreed');
    localStorage.removeItem('termsAgreed');

    this.transportService.getTransportInfoOfChildren().subscribe(res => {
      res?.data.forEach(element => {
        // if (element.reG_MSG != "") {
        //   this.enableRegiserButton = false;
        // }
        // else {
        //   this.enableRegiserButton = true;
        // }
        // if (element.rE_REG_MSG != "") {
        //   this.enableReRegisterBuggon = false;
        // }
        // else {
        //   this.enableReRegisterBuggon = true;
        // }

        element.studentNo = (element.studentNo == '' || element.studentNo == null) ? "NA" : element.studentNo
        element.servicetype = (element.servicetype == '' || element.servicetype == null) ? "NA" : element.servicetype
        element.serviceyear = (element.serviceyear == '' || element.serviceyear == null) ? "NA" : element.serviceyear
        element.serviceReferenceNo = (element.serviceReferenceNo == '' || element.serviceReferenceNo == null) ? "NA" : element.serviceReferenceNo
        element.dropOffLocation = (element.dropOffLocation == '' || element.dropOffLocation == null) ? "NA" : element.dropOffLocation
        element.dropoffpoint = (element.dropoffpoint == '' || element.dropoffpoint == null) ? "NA" : element.dropoffpoint
        element.dropofftime = (element.dropofftime == '' || element.dropofftime == null) ? "NA" : element.dropofftime
        element.dropOffBusNo = (element.dropOffBusNo == '' || element.dropOffBusNo == null) ? "NA" : element.dropOffBusNo
        element.pickupLocation = (element.pickupLocation == '' || element.pickupLocation == null) ? "NA" : element.pickupLocation
        element.pickuppoint = (element.pickuppoint == '' || element.pickuppoint == null) ? "NA" : element.pickuppoint
        element.pickuptime = (element.pickuptime == '' || element.pickuptime == null) ? "NA" : element.pickuptime
        element.pickupBusNo = (element.pickupBusNo == '' || element.pickupBusNo == null) ? "NA" : element.pickupBusNo
        element.toDate = (element.toDate == '' || element.toDate == null) ? "NA" : element.toDate?.replaceAll('/', " ")
        element.fromDate = (element.fromDate == '' || element.fromDate == null) ? "NA" : element.fromDate?.replaceAll('/', " ")
        element.serviceStatus = (element.serviceStatus == '' || element.serviceStatus == null) ? "NA" : element.serviceStatus
        element.grade = (element.grade == '' || element.grade == null) ? "NA" : element.grade
        element.section = (element.section == '' || element.section == null) ? "NA" : element.section
        element.currentAcademicYear = (element.currentAcademicYear == '' || element.currentAcademicYear == null) ? "NA" : element.currentAcademicYear.split(" ")[0]
        element.school = (element.school == '' || element.school == null) ? "NA" : element.school
        let grade = element.grade.split('/');
        element.grade = grade[0]
      });
      this.selectedStudent = JSON.parse(localStorage.parentChildrens)[0];
      let index = res?.data.findIndex((x: any) => x.studentNo == this.selectedStudent.studentId)
      if (index != -1) {
        this.responseData = [res?.data[index]];
        this.currentUser = res?.data[index];
        localStorage.setItem('transportChildInfo', JSON.stringify(res?.data))
      }
    })
  }
  ngAfterViewInit() {
    // this.route.queryParams.subscribe(params => {
    //   if (params['showReRegister']) {
    //     this.openDialog(this.reregisterTemplate);
    //   }
    // });
  }
  ChangeReason() {
    if (this.noForm.controls.reason.value == 'Others')
      this.isOthers = true;
    else this.isOthers = false
  }
  onDescChange() {
    if (this.noForm.controls.remarks.value && this.noForm.controls.remarks.value.trim() != "") {
      this.count = this.noForm.controls.remarks.value.length;
    }
    else {
      this.count = 0
      this.noForm.patchValue({ description: this.noForm.controls.remarks.value.trim() == "" ? "" : this.noForm.controls.remarks.value })
    }
  }

  proceedOrSubmit(type: any, template: any) {
    console.log(this.noForm)
    this.cancel();
    if (type == 'proceed')
      this.navigatetolink('/re-registration')
    else {
      let seriveDate = this.date.transform(this.currentUser?.fromDate, 'yyyy-MM-dd')
      let data = [{
        "RET_PROVIDER_BSU_ID": "",
        "RET_STU_BSU_ID": this.selectedStudent?.bsuId,
        "RET_STU_ID": this.selectedStudent?.studentId,
        "RET_ACD_ID": this.selectedStudent?.acdId,
        "RET_IS_REENROLL": false,
        "RET_STATUS": "",
        "RET_NARRATION": "",
        "RET_SOURCE": "WEB",
        "RET_OUTSTANDING_AMT": "",
        "RET_REENROLL_AMT": "",
        "RET_CHANGE_PA_ID": 0,
        "RET_CHANGE_PA_PNT_ID": 0,
        "RET_CHANGE_DA_ID": 0,
        "RET_CHANGE_DA_PNT_ID": 0,
        "RET_CHANGE_TPT_TYPE_ID": 0,
        "RET_CHANGE_LAT": "",
        "RET_CHANGE_LONG": "",
        "RET_ADD_INFO": "",
        "RET_MAKANI": "",
        "RET_REASON_REMARKS": this.noForm.controls.reason.value == 'Others' ? this.noForm.controls.remarks.value : this.noForm.controls.reason.value,
        "RET_SERVICE_DATE": seriveDate

      }]
      this.reregistrationService.saveReregistration(data).subscribe(
        (res: any) => {
          this.successDialog(template);
          let payload = {
            RET_PARENT_ID: res?.message,
            RET_STATUS: 'Not Registered',
            SOURCE: 'WEB',
            RET_PAYMENT_REF: ''
          }
          this.reregistrationService.updateStatus(payload).subscribe(res => {
          })
        }

      )
    }
  }

  goToRegistration() {
    this.dashboardService.getContactDetails('').subscribe((res: any) => {
      this.contactDetails = res?.data;
      this.registerService.getParentsList(this.contactDetails?.primaryContactNumber, this.token?.access_token).subscribe(res => {
        let parentDetails = res?.data;

        let studentDetails = parentDetails.find(x => x.stU_ID == this.selectedStudent.stuId)
        localStorage.setItem('photopath', studentDetails?.stU_PHOTOPATH)
        localStorage.setItem('studentDetails', JSON.stringify(studentDetails));
        localStorage.setItem('parentDetails', JSON.stringify([studentDetails]));
        localStorage.setItem('studParentDetails', JSON.stringify([studentDetails]));
        this.registerService.sendStudentDetails.next(parentDetails);
        localStorage.setItem("proceed", "false");
        localStorage.setItem("showSchool", "true")
        this.router.navigate(['/schoolbus-registration'], { queryParams: { isFromDashboard: true, tab: 1, isReactivate: true } });
      })
    })
  }

  successDialog(templateRef: TemplateRef<any>) {
    this.digopen = this.dialog.open(templateRef, {
      width: '500px',
      panelClass: 'custom-modalbox'
    });
    // this.save(templateRef)
  }
  cancel() {
    //console.log(this.noForm)
    this.isOthers = false;
    this.digopen.close();
  }
  openDialog(template: TemplateRef<any>) {
   // if (!(this.currentUser?.rE_REG_MSG != null && this.currentUser?.rE_REG_MSG != "")) {
      this.radiobtn = '1'
      this.noForm.reset();
      this.transportService.getReason().subscribe((res: any) => {
        let index = res?.data.findIndex(x => x.id == 1)
        res?.data.splice(index, 1)
        this.reason = res?.data;
        this.digopen = this.dialog.open(template, {
          disableClose: true,
          width: '550px',
          panelClass: ['custom-modalbox']
        });
      })
  //  } 
    // else {


    //   this.regClosedMessage = this.currentUser?.rE_REG_MSG;
    //   this.digopen = this.dialog.open(this.registrationClosedTemplate, {
    //     disableClose: true,
    //     width: '550px',
    //     panelClass: ['custom-modalbox']
    //   });
    // }

  }

  navigatetolink(lnk) {
    this.router.navigate([lnk]);
  }
  registerPopup(templateRef: TemplateRef<any>) {
    this.digopen = this.dialog.open(templateRef, {
      width: '500px',
      panelClass: 'custom-modalbox'
    });
  }
}
