import { Component, ElementRef, OnInit, ViewChild, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { CommonService } from 'src/app/service';
import { TokenService } from 'src/app/service/token.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MessageComponent } from 'src/app/message/message.component';
import { MatDialog } from '@angular/material/dialog';
import * as CryptoJS from 'crypto-js';
import { ConfigService } from 'src/app/service/config.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  public otpValue1: any = '';
  public otpValue2: any = '';
  public otpValue3: any = '';
  public otpValue4: any = '';
  public otpValue5: any = '';
  public otpValue6: any = '';
  public searchValue: any = '';
  public searchtext: any;

  @ViewChild("otp1") otp1!: ElementRef;
  @ViewChild("otp2") otp2!: ElementRef;
  @ViewChild("otp3") otp3!: ElementRef;
  @ViewChild("otp4") otp4!: ElementRef;
  @ViewChild("otp5") otp5!: ElementRef;
  @ViewChild("otp6") otp6!: ElementRef;
  isSpaceEntered: boolean = false;
  isInvalidUser: boolean = false;
  userdetails: any;
  isEnableOTP: boolean = false;
  @ViewChild("passwordInput") passwordInput!: MatInput;
  @ViewChild("passwordInput1") passwordInput1!: MatInput;
  isSpaceEntered2: boolean = false;
  isPasswordVisible: boolean = false;
  password: string = '';
  isPasswordLength: boolean = false;
  reenterPassword: string = '';
  newPassword: string = '';
  ispasswordConfirmed: boolean = false;
  isSpaceEntered3: boolean = false;
  isPasswordVisible1: boolean = false;
  isConfirmPasswordRequired: boolean = true;
  isAlphabets: boolean = false;
  isNumbers: boolean = false;
  isResendOtp: boolean = false;
  countdown: number = 0;
  interval: any;
  isUsernameEnable: boolean = true;
  isOTPEnable: boolean = false;
  isNewPasswordEnable: boolean = false;
  selectedElement: any;
  defaultToken: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  emailID: any = '';
  mobileNumber: any = '';
  otp: any = '';
  isOtpEntered: boolean = false;
  isInvalidOTP: boolean = false;
  isPasswordRequired: boolean;
  encOtp: any = '';
  showresend: boolean = false;
  resendcount: number=0;
  isSubmitted: boolean = false;
  isEmptyUser: boolean = true;
  constructor(private commonService: CommonService, private tokenService: TokenService, private _snackBar: MatSnackBar, private router: Router,
    private dialog: MatDialog, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document,
    private configService:ConfigService) { }

  ngOnInit(): void {
    let lang = localStorage.getItem("lang") != null ? localStorage.getItem('lang') : 'en';
    let direction = lang == "en" ? 'ltr' : 'rtl';
    document.body.dir = direction;

    this.getToken();
    this.renderer.addClass(this.document.body, 'body-height-100');
    var root = document.getElementsByTagName( 'html' )[0];
    this.renderer.addClass(root, 'body-height-100');
  }
  reSend(event) {
    this.resendcount++;
    if(this.resendcount > 2){
      this.isResendOtp = false
    }else{
      this.isResendOtp = true

    }
    this.getOTP(event);
    this.isInvalidOTP = false;
    this.countdown = 0;
    clearInterval(this.interval);
    this.countdown = this.configService.forgotPasswordOTPExpiry;
    this.startCountdown(event);
    this.otpValue1 = "";
    this.otpValue2 = ""
    this.otpValue3 = "";
    this.otpValue4 = "";
    this.otpValue5 = "";
    this.otpValue6 = ""
  }
  getOTP(event) {

    this.isSubmitted = true;
    this.searchtext = this.searchValue;
    if(this.searchValue!=''){
      this.getSearchValue(event, this.searchValue)

    }
     setTimeout(() => {
                
             
    if (this.userdetails?.isValidUser== true) {
      this.commonService.sendOTPToUser(this.searchtext, this.defaultToken).subscribe(
        (res: any) => {

          if (res?.success == "true") {

            this.isUsernameEnable = false;
            this.isOTPEnable = true;
            this.isSubmitted = false;
            this.isNewPasswordEnable = false;
            this.emailID = this.replaceMaskingEmail(res.data[0].contactValue);
            this.mobileNumber = this.replaceMasking(res.data[0].contactValue);
            event.preventDefault();
            this.countdown = 0;
            clearInterval(this.interval);

            this.startCountdown(event);


            this.encOtp = res.data[0].otp;
            // let otp:any =this.sha512Enc(res.data[0].otp);
            // alert(otp);
          } else {
            this.isUsernameEnable = true;
            this.isOTPEnable = false;
            this.isNewPasswordEnable = false;
            this.isSubmitted = false;
            this.openMessageDialog(res?.message, true, "");
          }

        }
      )
    }
  }, 3000);
  }
  sha512Enc(val: string) {
    const hash = CryptoJS.SHA512(val);
    return hash.toString(CryptoJS.enc.Hex);
  }
  replaceMasking(value) {
    let firstSubStr = '';
    let lastSubStr = '';
    firstSubStr = value.slice(0, 3)
    lastSubStr = value.slice(value.length - 3, value.length);
    return firstSubStr + ' ' + '*'.repeat(value.length - 6) + lastSubStr
  }
  replaceMaskingEmail(value) {
    return value;
    // let firstSubStr = '';
    // let lastSubStr = '';
    // let midSubStr = '';
    // firstSubStr = value.slice(0, 3);
    // lastSubStr = value.split('@')[1];
    // midSubStr = value.split('@')[0];
    // midSubStr = midSubStr.slice(midSubStr.length - 2, midSubStr.length);
    // return firstSubStr + ' ' + 'x'.repeat(value.length - (lastSubStr.length + firstSubStr.length) - 1) + midSubStr + '@' + lastSubStr
  }

  validateOTP() {

    if(this.countdown!=0 && !this.isInvalidOTP){
    let data: any = {};
    this.getEnteredOTP();
    if (!this.isOtpEntered) {
      data = {
        username: this.searchValue,
        otp: this.otp,
        source: this.configService.source,
        deviceid: this.configService.deviceId,
      }


      let testval = this.sha512Enc(this.otp);
      if (this.encOtp == testval) {
        this.commonService.validateOTP(data, this.defaultToken).subscribe(
          (res: any) => {

            if (res?.isValidUser == true) {
              this.isInvalidOTP = false;

              this.isUsernameEnable = false;
              this.isOTPEnable = false;
              this.isNewPasswordEnable = true;
              this.isAlphabets = false;
              this.isNumbers = false;
              this.isPasswordLength = false;
              if(this.isNewPasswordEnable === true){

              }
            } else {
              this.isInvalidOTP = true;
              // setTimeout(() => {
              //   this.isInvalidOTP = false;
              // }, 3000);
              // this.otpValue1 = '';
              // this.otpValue2 = '';
              // this.otpValue3 = '';
              // this.otpValue4 = '';
              // this.otpValue5 = '';
              // this.otpValue6 = '';

            }

          }, (error) => {
            this.isInvalidOTP = true;
            // setTimeout(() => {
            //   this.isInvalidOTP = false;
            // }, 3000);
            // this.otpValue1 = '';
            // this.otpValue2 = '';
            // this.otpValue3 = '';
            // this.otpValue4 = '';
            // this.otpValue5 = '';
            // this.otpValue6 = '';

          }
        )
      }
      else {
        // this.openErrorMessageDialog("Invalid OTP",true,"Please enter valid OTP.")
        this.isInvalidOTP = true;
        // setTimeout(() => {
        //   this.isInvalidOTP = false;
        // }, 3000);
        // this.otpValue1 = '';
        //       this.otpValue2 = '';
        //       this.otpValue3 = '';
        //       this.otpValue4 = '';
        //       this.otpValue5 = '';
        //       this.otpValue6 = '';
      }
    }

  }
  }
  getEnteredOTP() {

    if (this.otpValue1 != '' && this.otpValue2 != '' && this.otpValue3 != '' && this.otpValue4 != '' && this.otpValue5 != '' && this.otpValue6 != '') {
      this.otp = this.otpValue1 + this.otpValue2 + this.otpValue3 + this.otpValue4 + this.otpValue5 + this.otpValue6;
      this.isOtpEntered = false;
      this.isInvalidOTP = false;
    } else {
      this.isOtpEntered = true;
      this.isInvalidOTP = false;

    }
  }
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  keytab(event: any, value: any) {
    //this.isInvalidOTP=false;
    if (event.code == 'Backspace' || event.code == undefined || event.code == 'Delete') {
      if (event.srcElement.name == 'otpValue1') {
        this.otp1.nativeElement.focus();
        // if(this.otpValue1 == "_")
        // this.otpValue1="";
        if (event.code == undefined && this.otpValue1 == "")
          this.otpValue1 = '';
      }
      if (event.srcElement.name == 'otpValue2') {
        this.otp2.nativeElement.focus();
        // if(this.otpValue2 == "_")
        // this.otpValue2="";
      }
      if (event.srcElement.name == 'otpValue3') {
        this.otp3.nativeElement.focus();
        // if(this.otpValue3 == "_")
        // this.otpValue3="";
      }
      if (event.srcElement.name == 'otpValue4') {
        this.otp4.nativeElement.focus();
        // if(this.otpValue4 == "_")
        // this.otpValue4="";
      }
      if (event.srcElement.name == 'otpValue5') {
        this.otp5.nativeElement.focus();
        // if(this.otpValue5 == "_")
        // this.otpValue5="";
      }
      if (event.srcElement.name == 'otpValue6') {
        this.otp6.nativeElement.focus();
        // if(this.otpValue6 == "_")
        // this.otpValue6="";
      }
    }
    else {
      if (value == 1) {
        if (this.otpValue1 != '') {
          this.otp2.nativeElement.focus();
          // if(this.otpValue2 == "_")
          // this.otpValue2="";
        }

      }

      if (value == 2) {
        if (this.otpValue2 != '') {
          this.otp3.nativeElement.focus();
          // if(this.otpValue3 == "_")
          // this.otpValue3="";
        }
      }

      if (value == 3) {
        if (this.otpValue3 != '') {
          this.otp4.nativeElement.focus();
          // if(this.otpValue4 == "_")
          // this.otpValue4="";
        }
      }
      if (value == 4) {
        if (this.otpValue4 != '') {
          this.otp5.nativeElement.focus();
          // if(this.otpValue5 == "_")
          // this.otpValue5="";
        }
      }
      if (value == 5) {
        if (this.otpValue5 != '') {
          this.otp6.nativeElement.focus();
          // if(this.otpValue6 == "_")
          // this.otpValue6="";
        }
      }
      if (value == 6) {
        if (this.otpValue6 != '') {
          this.otp6.nativeElement.focus();
          // if(this.otpValue6 == "_")
          // this.otpValue6="";
        }
      }
    }

  }

  checkValidatedTab(event: any) {

    let nextInput = event.srcElement.nextElementSibling; // get the sibling element

    var target = event.target || event.srcElement;
    var id = target.id

    if (nextInput == null)  // check the maxLength from here
      return;
    else
      nextInput.focus();
    // focus if not null
  }
  hasWhiteSpace(s: any) {
    return (/\s/).test(s);
  }
  getSearchValue(value: any, search: any) {

    if (this.hasWhiteSpace(search)) {
      this.isSpaceEntered = true
    } else {
      this.isSpaceEntered = false;
    }
    if (!this.hasWhiteSpace(search)) {
      this.commonService.validateUserName(this.searchValue, this.defaultToken).subscribe(
        res => {
          this.isSubmitted = false;
          this.userdetails = res;
          if (res?.isValidUser==true) {
            this.isInvalidUser = false;

            this.isEnableOTP = false;
          } else {
            this.userdetails={}
            this.isInvalidUser = true;
            this.isEnableOTP = true;
          }

        },
        (error) => {
          this.isSubmitted = false;
          this.isInvalidUser = true;
          this.isEnableOTP = true;
        }
      )
    }


  }

  public changeNewPassword(value: any) {
    if (this.hasWhiteSpace(value)) {
      this.isSpaceEntered2 = true
    } else {
      this.isSpaceEntered2 = false;
    }
    if (!this.isSpaceEntered2) {
      if (this.isPasswordVisible == false) {
        this.hidePassword();
      }

      this.password = value;
      if (value.length < 8 || value.length > 16) {
        this.isPasswordLength = true
      } else {
        this.isPasswordLength = false
      }
      if (this.reenterPassword != '') {
        if (this.newPassword == this.reenterPassword) {
          this.ispasswordConfirmed = false;
        } else {
          this.ispasswordConfirmed = true;
        }
      } else this.ispasswordConfirmed = false;
      if (this.checkAlphabets(value)) {
        this.isAlphabets = false;
      }
      else {
        this.isAlphabets = true;
      }
      if ((this.checkNumerics(value)) && (this.hasSpecialChars(value))) {
        this.isNumbers = false;
      }
      else {
        this.isNumbers = true;
      }

      if (value.length > 0) {
        this.renderer.addClass(this.document.body, 'rm-height');
        var root = document.getElementsByTagName('html')[0];
        this.renderer.addClass(root, 'rm-height');
        this.renderer.removeClass(this.document.body, 'body-height-100');
        var root = document.getElementsByTagName('html')[0];
        this.renderer.removeClass(root, 'body-height-100');
        this.renderer.removeClass(this.document.body, 'body-height-100vh');
        var root = document.getElementsByTagName('html')[0];
        this.renderer.removeClass(root, 'body-height-100vh');
      }
      if (value.length == 0) {
        this.renderer.removeClass(this.document.body, 'rm-height');
        var root = document.getElementsByTagName('html')[0];
        this.renderer.removeClass(root, 'rm-height');
        this.renderer.addClass(this.document.body, 'body-height-100');
        var root = document.getElementsByTagName('html')[0];
        this.renderer.addClass(root, 'body-height-100');
      }

    }

  }

  public checkAlphabets(text: any) {
    return text.match(/(?=.*[a-z])(?=.*[A-Z])/) ? true : false;

  }
  public checkNumerics(text: any) {
    return text.match(/(?=.*[0-9])/) ? true : false;

  }
  public hasSpecialChars(str: any) {
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (format.test(str)) {
      return true;
    } else {
      return false;
    }
  }
  public checkconfirmpassword(value: any) {

    if (this.hasWhiteSpace(value)) {
      this.isSpaceEntered3 = true
    } else {
      this.isSpaceEntered3 = false;
    }
    if (!this.isSpaceEntered3) {
      if (this.isPasswordVisible1 == false) {
        this.hidePassword1()
      }
      if (value == '') {
        this.isConfirmPasswordRequired = true
      } else {
        this.isConfirmPasswordRequired = false
      }
      if (value == this.newPassword) {
        this.ispasswordConfirmed = false;
      } else {
        this.ispasswordConfirmed = true;

      }
    }
  }
  public hidePassword() {
    if (this.passwordInput) {
      this.passwordInput.type = "password";
      this.isPasswordVisible = false

    }

  }
  public showPassword() {

    if (this.passwordInput) {
      this.passwordInput.type = "text";
      this.isPasswordVisible = true;
    }

  }

  public hidePassword1() {

    if (this.passwordInput1) {
      this.passwordInput1.type = "password";
      this.isPasswordVisible1 = false;
    }

  }
  public showPassword1() {

    if (this.passwordInput1) {
      this.passwordInput1.type = "text";
      this.isPasswordVisible1 = true;
    }
  }
  changePassword() {
    if (this.newPassword == '' || this.newPassword.length == 0 || this.newPassword == null) {
      this.isPasswordRequired = true;
    } else {
      this.isPasswordRequired = false;
    }
    if (this.reenterPassword == '') {
      this.isConfirmPasswordRequired = true
    } else {
      this.isConfirmPasswordRequired = false

    }
    let data: any = {};
    if (this.ispasswordConfirmed == false && this.isPasswordLength != true && !this.isAlphabets && !this.isNumbers && this.isPasswordRequired == false && this.reenterPassword != '') {


      data = {
        username: this.searchValue,
        source: this.configService.source,
        deviceid: this.configService.deviceId,
        OldPassword: this.newPassword,
        NewPassword: this.reenterPassword
      }
      this.commonService.resetPassword(data, this.defaultToken).subscribe(
        (res: any) => {
          // this._snackBar.open(res?.Message, '', {
          //   horizontalPosition: this.horizontalPosition,
          //   verticalPosition: this.verticalPosition,
          //   duration: 2000
          // });
          if (res.Success)
            this.openMessageDialog(res?.Message, false, "");
          else this.openMessageDialog(res?.Message, false, "");
          //this.router.navigate(['/login']);
          // setTimeout(() => {
          //   window.open(window.location.origin,'_self')
          // }, 2000);
        }, (error) => {

        }
      )
    }
  }
  private startCountdown(event: any) {

    this.isResendOtp = true;
    this.countdown = this.configService.forgotPasswordOTPExpiry; // 60 seconds
    this.interval = setInterval(() => {
      if (this.countdown === 0) {
        clearInterval(this.interval);

        if(this.resendcount > 2){
          this.isResendOtp = false
        }else{
          this.isResendOtp = true
    
        }

      } else {
        this.countdown -= 1;
        
        if(this.resendcount > 2){
          this.isResendOtp = false
        }else{
          this.isResendOtp = true
    
        }
        event.preventDefault();
      }
    }, 1000); // Update every second
  }

  getToken() {
    this.tokenService.GetToken(null).subscribe(
      res => {
        this.defaultToken = res?.access_token
      }
    )
  }
  cancel() {
    this.goToLogin();
  }
  goToLogin() {
    this.router.navigate(['/login']);
  }
  openErrorMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

  }

  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.goToLogin()
    });
  }
  goToLanding() {
    this.router.navigate(['/']);
  }

  onUserNameChange() {
    if(this.searchValue != null && this.searchValue.length > 0){
      this.isInvalidUser = false;
      this.isEmptyUser = false;
    }else{
      this.isEmptyUser = true;
    }
  }
}
