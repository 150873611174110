import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileService } from './file.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  sendStudentDetails = new BehaviorSubject(null);
  appUrl: any;
  constructor(private http: HttpClient,private fileService: FileService,private configService:ConfigService ) {
    this.appUrl=this.configService.appUrl;
   }

  public getSchoolList(token,location:any=''): Observable<any> {
    const header = {Authorization:`Bearer ${token}`,schoolName:'',location:location};
    return this.http.get(`${this.configService.baseUrl}/api/transport/GET_SCHOOL_TRANSPORTAION_INFO`,{headers:header});
  }
  public getOTPfromMobile(data,otptype, token): Observable<any> {

    const header = {Authorization:`Bearer ${token}`,mobilenumber:data, source:this.configService.source, deviceid:this.configService.deviceId,otptype:otptype,schoolid:localStorage.getItem("bsU_ID")};

    return this.http.post(`${this.configService.baseUrl}/api/Transport/SendSTSRegistrationOTP`,{},{headers:header});
  }
  public getParentsList(data, token): Observable<any> {
    const header = {Authorization:`Bearer ${token}`, mobilenumber:data,source:this.configService.source, deviceid:this.configService.deviceId };
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetSTSRegistrationDetails`,{headers:header});
  }
  public getGrades(token, preLoginData:any=null): Observable<any> {
    let bsuId:any;
    let acdId:any;
    if(preLoginData == null)
    {
      bsuId=localStorage.getItem("bsU_ID");
      acdId=localStorage.getItem("currentAcademicYearId")
    }
    else{
      bsuId=preLoginData?.bsuId;
      acdId=preLoginData?.acdyr;
    }
     //let bsuId ="121013"
    const header = {Authorization:`Bearer ${token}`,master: 'GRADE_M',acdid: acdId,bsuid: bsuId};
    return this.http.get(`${this.configService.baseUrl}/api/masters/masters`,{headers:header});
  }
  save(data: any, token: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      source: this.configService.source,
      deviceid: this.configService.deviceId,
    });
    const storedFile = this.fileService.getFile();
    const formData = new FormData();
    formData.append('StudentProfile', storedFile);
    formData.append('data', JSON.stringify(data));
    // Append other data as needed
    // Object.keys(data).forEach(key => {
    //   formData.append(key, data[key]);
    // });
  
    const requestOptions = {
      headers: headers,
    };
    if (storedFile) {
      // Perform your request or operation using the storedFile
      // ...
      // Clear the file after use if needed
      this.fileService.clearFile();
    }
    return this.http.post(`${this.configService.baseUrl}/api/Transport/SaveTransportRegistration`, formData, requestOptions);
  }
  
  // save(data: any, token: string): Observable<any> {
  //   const headers = new HttpHeaders({
  //     Authorization: `Bearer ${token}`,
  //     source: this.configService.source,
  //     deviceid: this.configService.deviceId,
  //   });

  //   const formData = new FormData();
  //   formData.append('StudentProfile', localStorage.getItem('sprofileimg'));

  //   // Append other data as needed
  //   Object.keys(data).forEach(key => {
  //     formData.append(key, data[key]);
  //   });

  //   const requestOptions = {
  //     headers: headers,
  //   };

  //   return this.http.post(`${this.configService.baseUrl}/api/Transport/SaveTransportRegistration`, formData, requestOptions);
  // }
//   public save(data, token){
// //api/Transport/SaveTransportRegistration
// const header = {Authorization:`Bearer ${token}`, source:this.configService.source, deviceid:this.configService.deviceId};
// const formData = new FormData();
// formData.append('StudentProfile',  localStorage.getItem["sprofileimg"]);
// const requestOptions = {
//   headers: header,
//   profile:formData,
//   contentType: undefined,
// };
//     return this.http.post(`${this.configService.baseUrl}/api/Transport/SaveTransportRegistration`,data,requestOptions);
  
//   }
  public studentSaveAsDraft(data, token, file?: File) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      source: this.configService.source,
      deviceid: this.configService.applicationSource,
    });
    const storedFile = this.fileService.getFile();
    const formData = new FormData();
    formData.append('StudentProfile', storedFile);
    formData.append('data', JSON.stringify(data));
    const requestOptions = {
      headers: headers,
    };
    if (storedFile) {
      // Perform your request or operation using the storedFile
      // ...
      // Clear the file after use if needed
      //this.fileService.clearFile();
    }
    // const formData = new FormData();
    // formData.append('StudentProfile', file);
    // const headers = {
    //   Authorization: `Bearer ${token}`,
    //   source: this.configService.source,
    //   deviceid: this.configService.deviceId,
    // };
    // const requestOptions = {
    //   headers: headers,
    //   profile:formData,
    //   contentType: undefined,
    // };
    return this.http.post(
      `${this.configService.baseUrl}/api/Transport/SaveStudentDraftDetails`,
      formData,
      requestOptions
    );
  }
  public studentAvailabilityCheck(token,id): Observable<any> {
    let bsuId=localStorage.getItem("bsU_ID");
    const header = {Authorization:`Bearer ${token}`,StudentId:id,bsuid:bsuId};
    return this.http.get(`${this.configService.baseUrl}/api/Transport/GetStudentAvailabilityCheck`,{headers:header});
  }

  public getAcademicYrs(token,serviceDate:any,bsuIdFromOffers:any=null,hideSpinner=false): Observable<any> {
    let bsuId:any
    if(!bsuIdFromOffers)
    bsuId=localStorage.getItem("bsU_ID");
    else  bsuId =bsuIdFromOffers;
    //let bsuId ="121013"

   let header:any = {Authorization:`Bearer ${token}`,master: 'TPT_SERVICE_DATE',bsuid: bsuId,serviceDate:serviceDate};
   if(hideSpinner){
    header ={...header,HideSpinner:'true'};
   }
   return this.http.get(`${this.configService.baseUrl}/api/masters/masters`,{headers:header});
 }
 public getGeoLocation(token, data): Observable<any> {
  //    localStorage.removeItem('currentAcademicYear')

  let bsuId=localStorage.getItem("bsU_ID");
 const header = {"Authorization":`Bearer ${token}`,"BSUID": bsuId,  
 "SBLID":data[0].SBLID,
 };
 return this.http.get(`${this.configService.baseUrl}/api/Transport/GetGEOLocations`,{headers:header});
}
public getFeeAmounts(token, data:any): Observable<any> {
  let bsuId=localStorage.getItem("bsU_ID");
  let acdid = localStorage.getItem("currentAcademicYearId");
  const header = {
   "Authorization": `Bearer ${token}`,
      "BSU_ID": bsuId.toString(),
      "ACD_ID": acdid.toString(),
      "TRIPTYPE": data[0].TRIPTYPE.toString(),
      "SBL_ID": data[0].SBLID.toString(),
      "DSBLID": data[0].DSBLID.toString(),
      "STU_ID":data[0].STU_ID.toString(),
      "PROVIDER_BSU_ID":data[0].PROVIDER_BSU_ID.toString(),
      "TRM_STARTDT":data[0].TRM_STARTDT,
      "SSV_STARTDT":data[0].SSV_STARTDT
    }
 
 
 
 return this.http.get(`${this.configService.baseUrl}/api/Transport/GetSTSFeeAmounts`,{headers:header});
}
public getTermsandConditions(token:any): Observable<any> {
  let bsuId=localStorage.getItem("bsU_ID");
  let bsu_city=JSON.parse(localStorage.getItem("schoolDetails"))?.bsu_city;
  let provider =JSON.parse(localStorage.getItem("schoolDetails"))?.provider;
  let GRD_ID=JSON.parse(localStorage.studentDetails).stU_GRD_ID.toString();
 const header = {Authorization:`Bearer ${token}`,bsu_city:bsu_city,bsu_id: bsuId,provider:provider,GRD_ID:GRD_ID.toString()};
 return this.http.get(`${this.configService.baseUrl}/api/Transport/GetTransportTermsConditions`,{headers:header});
}
// public DownloadPDF(token:any,pdfUrl:string): Observable<any> {
  
//  const header = {Authorization:`Bearer ${token}`};
//  return this.http.get(`${this.configService.baseUrl}/api/Transport/DownloadPdf?pdfUrl=`+pdfUrl,{headers:header});
// }
DownloadPDF(token: any, pdfUrl: string): Observable<any> {
  const headers = { Authorization: `Bearer ${token}` };

  let res= this.http.get(`${this.configService.baseUrl}/api/Transport/DownloadPdf?pdfUrl=` + pdfUrl, {
    headers,
    responseType: 'blob', // Important to specify responseType as blob
  });
  return res;
}

getParentUndertakingData(token:any,data:any)
{
  let oluName = data?.oluName;
  let stuNo = data?.stuNo;
  let mobileNumber = data?.mobileNumber;

  let header = new HttpHeaders({
    Authorization: `Bearer ${token}`,
    oluName: oluName,
    stuNo: stuNo
  });

  if (mobileNumber) {
    header = header.append('mobileNumber', mobileNumber);
  }
 
 return this.http.get(`${this.configService.baseUrl}/api/Transport/GetParentUnderTakingData`,{headers:header});
}
 saveParentUndertakingData(token:any,data:any)
 {
   const header = {Authorization:`Bearer ${token}`};
   return this.http.post(`${this.configService.baseUrl}/api/Transport/SaveParentUnderTakingData`,data,{headers:header});
  }

  saveReactivateRequest(data: any, token: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      source: this.configService.source,
      deviceid: this.configService.deviceId,
    });
    // const formData = new FormData();
    // formData.append('data', JSON.stringify(data));
    
    const requestOptions = {
      headers: headers,
    };
    return this.http.post(`${this.configService.baseUrl}/api/Transport/SAVE_REACTIVATE_SERVICE_REQUEST`, data, requestOptions);
  }
}