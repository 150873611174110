import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  isError:boolean=false;
  constructor(private router: Router,@Inject(MAT_DIALOG_DATA) public data: any,private translate:TranslateService) { }

  ngOnInit(): void {
    this.translate.stream('general').subscribe(res=>{
      if(this.data.buttonName==undefined || this.data.buttonName==null){
        this.data.buttonName=res?.label?.ok ?? 'OK';
      }
    });
  }
  dilogclose()
  {
    
    this.router.navigate([this.data.redirectpage]);
  }
}
