import { Injectable, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '.';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfigService } from './config.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class sessionTimeoutService {
idleState = 'Not started.';
timedOut = false;
lastPing?: Date = null;
isShowButtons:boolean=true;
langTrans: any;

dataSubject = new Subject<boolean>();
    constructor(private http: HttpClient,private idle: Idle,
        private keepalive: Keepalive,private dialog: MatDialog
        ,private router:Router,private authenticationService: AuthenticationService,
        private configService:ConfigService, private translate: TranslateService) { 
          this.translate.stream('general').subscribe(async (data: any) => {
            if (data != null && data != undefined) {
              this.langTrans = data
            }
          });       
        }

 checkSessionTimeout()
 {

	// sets an idle timeout of 5 seconds, for testing purposes.
  //alert(this.configService.idleTimeout);
    this.idle.setIdle(this.configService.idleTimeout);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(this.configService.timeoutPeriod);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
     // this.idleState = 'No longer idle.'
      //console.log(this.idleState);
       this.dialog.closeAll();
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      //console.log(this.idleState);
      //this.digopen.close();
      this.idleState=this.langTrans?.label?.sessionExpired;
      this.isShowButtons=false;
     // this.router.navigate(['/login']);
    });

    this.idle.onIdleStart.subscribe(() => {
      this.isShowButtons=true;
        this.idleState = 'You\'ve gone idle!'
       // console.log(this.idleState);
        //this.childModal.show();
        this.dataSubject.next(true);
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = this.langTrans?.label?.sessionWillExpireIn + countdown + this.langTrans?.label?.expirySeconds
      //console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());


    if (localStorage.getItem('currentToken') && this.authenticationService.isLoginSuccess) {
      this.idle.watch()
      this.timedOut = false;
    } else {
      this.idle.stop();
    }
 }

 reset() {

    this.idle.watch();
   // this.idleState = 'Started.';
    this.timedOut = false;
  }
}