import { AfterViewInit, Component, DoCheck, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, fromEvent } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models/User';
import { environment } from 'src/environments/environment';
import {
  AuthenticationService,
  CommonService,
} from 'src/app/service';
import { ConfigService } from 'src/app/service/config.service';
import { NotificationService } from 'src/app/service/notification.service';
import { UserprofileupdateService } from 'src/app/service/userprofileupdate.service';
import { DashboardService } from 'src/app/service/dashboard.service';
import { RegistrationService } from 'src/app/service/registration.service';
import { LocalizationService, MessageService } from '@progress/kendo-angular-l10n';
import { LanguageTranslateService } from 'src/app/service/translatelanguageservice.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'layout-top-nav',
  templateUrl: './layout-top-nav.component.html',
  styleUrls: ['./layout-top-nav.component.scss'],
})
export class LayoutTopNavComponent implements OnInit, AfterViewInit,DoCheck {
  selectedLanguage: string = 'EN';
  direction: string = 'ltr';
  digopen:any;
  contactDetails: any;
  token: any;
  logoimage: string = this.configService.logoimageUrl;
  private loggedInUserSubject = new BehaviorSubject<User>(null);
  private selectedChildSubject = new BehaviorSubject<any>(undefined);
  public notificationCount: number = 0;
  public menuItems$ = new Observable<any>(null);
  public children$ = new Observable<any>(null);
  public menuItems1$ = new Observable<any>(null);
  public selectedstudent:any[];
  resultmenu: any = {};
  public notificationdetails: any = [];
  public oluName: string;
  public oluMenus: any[];
  public childerns:any[];
  public selectedstudentid:any;
  count: any=0;isLeasedSchool: any;
  private subscription: Subscription;
  private studentSubscription:Subscription;
  isHybridSchool: any;
  @ViewChild('modalTemp') modalTemp1!: TemplateRef<any>;
  studentNames: string = '';
  multipleChild: boolean = false;
  previousStudentId:any;
  constructor(
    private localizationService: LocalizationService,
    private configService:ConfigService,
    private auth: AuthenticationService,
    private router: Router, private route: ActivatedRoute,
    private commonService: CommonService,
    private userprofileimage:UserprofileupdateService,
    private dashboardService: DashboardService,
    public registerService: RegistrationService,
    private elem: ElementRef,private dialog: MatDialog, private notificationService:NotificationService, private messages:MessageService,
    public translate: TranslateService) {
    this.oluName = this.auth.currentUser.userDisplayName;
    this.loggedInUserSubject.next(this.auth.currentUser);

  }
  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    this.subscription.unsubscribe();
    if(this.studentSubscription){
      this.studentSubscription.unsubscribe();
    }
  }
  ngDoCheck(): void {
    let data:any = localStorage.getItem('leasemodelschool')
    this.isLeasedSchool = data;
    let isHybrid:any = localStorage.getItem('IsHybrid')
    this.isHybridSchool = isHybrid;
    //this.oluMenus=[];

  }

  public get loggedInUserName$(): Observable<string> {
    return this.loggedInUserSubject.pipe(
      map((x) => x.userDisplayName)
    );
  }

  public get selectedChildName$(): Observable<any> {
    return this.selectedChildSubject.pipe(
      filter((x) => !!x),
      map((x: any[]) => x[0]),
      map((x) => x.studentName)
    );
  }

  public get selectedChildPhoto$(): Observable<any> {
    return this.selectedChildSubject.pipe(
      filter((x) => !!x),
      map((x: any[]) => x[0]),
      map((x) => x.photoPath)
    );
  }

  private menuItems = (nodes, parentId = '#') => {
    if (nodes) {
      return nodes.reduce((result, fromData) => {
        const obj = Object.assign({}, fromData);
        if (parentId === fromData.parentMenuCode) {
          const children = this.menuItems(nodes, fromData.menuCode);
          if (children.length) {
            obj.children = children;
          } else {
            obj.children = [];
          }
          result.push(obj);
        }
        return result;
      }, []);
    }
  }

  ngOnInit(): void {

    this.selectedLanguage = localStorage.getItem('lang') != null ? localStorage.getItem('lang') : "en";
    this.onLanguageChange(this.selectedLanguage);

    setTimeout(() => {
      this.checkAutoPromotion();
    }, 500);
    this.studentSubscription=fromEvent<CustomEvent>(window, 'app-switch-student').subscribe((res) => {
      if(res.detail){

        localStorage.setItem("selectedstudentid", res.detail.studentId);
        this.selectedstudentid=res.detail.studentId;

        localStorage.removeItem("selectedSiblings");
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          if(res.detail.redirectUrl.includes('?')){
            this.router.navigateByUrl(res.detail.redirectUrl);
          }
          else{
            this.router.navigate([res.detail.redirectUrl]);
          }
        });
        this.selectedstudentid=localStorage.selectedstudentid;

      }
    });
  }

  getMenus()
  {

    this.oluMenus=[];
    this.commonService.reloadNotificationCount.subscribe(res=>{
      if(res)
      this.getNotificationDetails();
    })
    this.commonService.getChildrenByParentId().subscribe(child => {
      let alreadySelectedStudent:any
      child?.data.forEach((x) => {
        x.photoPath = x.photoPath === '' || x.photoPath==null ? "assets/img/no-avatar.svg" : x.photoPath;
        let selectedStudent = localStorage.getItem("selectedstudentid")
        if(selectedStudent && (selectedStudent == x.studentId))
        alreadySelectedStudent=x;

        return x;
      })

      localStorage.setItem("allChildren", JSON.stringify(child?.data));
     if(alreadySelectedStudent)
     localStorage.setItem("parentChildrens", JSON.stringify([alreadySelectedStudent]));
     else localStorage.setItem("parentChildrens", JSON.stringify([child?.data[0]]));

      this.childerns =child?.data;
      //alert(JSON.stringify(this.childerns));
      this.selectedChildSubject.next(child?.data.filter((x: { selected: boolean }) => x.selected));

          this.selectedstudent=child?.data;


        if(localStorage.selectedstudentid)
        {
        this.selectedstudentid=localStorage.selectedstudentid;

        }
        else
        {
          this.selectedstudentid=this.selectedstudent[0].studentId;
          localStorage.selectedstudentid=this.selectedstudentid;
        }

        this.selectedstudent=child?.data.filter(s=>s.studentId==this.selectedstudentid);
        this.userprofileimage.updateUserProfileImage(this.selectedstudent[0].photoPath);
        this.subscription = this.userprofileimage.getProfileImageUrl().subscribe(imageUrl => {
          //alert(imageUrl);
           this.selectedstudent[0].photoPath=imageUrl;
           //alert(this.selectedstudent);
         });

    });
    // setTimeout(() => {                           //<<<---using ()=> syntax
    //   this.commonService.getMenusforParentPortal().subscribe(res=>
    //     {
    //       this.oluMenus=res.data;
    //       //alert(JSON.stringify( this.oluMenus));
    //     })
    // }, 3000);


    setTimeout(() => {                           //<<<---using ()=> syntax
      this.commonService.getMenusforParentPortal().subscribe(res => {
        // this.oluMenus = res.data;

         let data: any = [];
         let menu: any = []
         data = res.data;
         data?.forEach((ele, index) => {
           if (ele.parentMenuCode == '#') {
             menu.push(ele);
           }
         })
         menu.forEach(ele => {
           ele.submenu = []
         })

         menu.forEach((ele1: any, index) => {
           data.forEach(ele => {

             if (ele.parentMenuCode == ele1.menuCode) {

               menu[index].submenu.push(ele)
             }
           })
         });
         this.oluMenus = menu;
        //  console.log(this.oluMenus);
        //  alert(JSON.stringify( this.oluMenus)  );


     // this.reloadPage();
       })
    }, 1500);

    this.notificationService.getCount().subscribe(
      (res:any)=>{
        this.count = res?.data?.count
      }
    );

  }
  ngAfterViewInit(): void {
    this.getMenus();
    let nodeList = this.elem.nativeElement.querySelectorAll('.main-nav');
    console.log(nodeList);
    if (nodeList.length > 0) {

    }
    this.getNotificationcount();
    this.getNotificationDetails();
  }
  Logout1() {
    this.auth.logout();
    localStorage.clear();
    // setTimeout(() => {                           //<<<---using ()=> syntax
    //   this.router.navigate(['/login']);
    // }, 3000);
    this.auth.logout();
    localStorage.clear();
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
    // setTimeout(() => {                           //<<<---using ()=> syntax
    //   this.router.navigate(['/']);
    // }, 2000);
    // this.router.navigate(['/index'])
    //   .then(() => {
    //     window.location.reload();
    //   });
  }
  public selectChild(event:any)
  {
    this.previousStudentId = localStorage.selectedstudentid;

    this.getSelectedStudent(event.studentId);
    //alert(event.studentId);
    //this.reloadCurrentRoute();
  }
  public logOut($event): void {
    $event.stopPropagation();
    this.auth.logout();

    let lang = localStorage.getItem("lang") != null ? localStorage.getItem('lang') : 'en';

    localStorage.clear();

    setTimeout(() => {                           //<<<---using ()=> syntax
      localStorage.setItem("lang", lang);

      let direction = lang == "en" ? 'ltr' : 'rtl';
      document.body.dir = direction;
      this.router.navigate(['/login']);
    }, 1000);
    this.dialog.closeAll()
    // this.router.navigate(['/'])
    //   .then(() => {
    //     window.location.reload();
    //   });
  }
  gotomakepayment() {
    this.router.navigate(["/payments"]);
  }
  goToMyaccount(){
    this.router.navigate(["/my-account"]);
  }
  GoToPage(path: string, e: Event) {

    if (path.indexOf("logout") != -1) {
      this.logOut(e);
    }
    else
      if (path.indexOf("javascript") == -1) {
        this.router.navigate([path]);
      }

  }
  Changepassword() {
    this.router.navigate(["/changepassword"]);
  }
  getSelectedStudent(stuid: string) {

    localStorage.setItem("selectedstudentid", stuid);
    this.selectedstudentid=stuid;
    //alert(this.selectedstudentid);

    localStorage.removeItem("selectedSiblings");

    this.reloadCurrentRoute();
  }
  getNotificationcount() {

    this.commonService.getNotificationCount().subscribe(res => {
      if (res.success == 'true') {
        this.notificationCount = res.data['count'];
      }
    });

  }
  getNotificationDetails() {

    this.commonService.getNotificationDetails().subscribe(res => {
      if (res.success == 'true') {
        this.notificationdetails = res.data;
      }
    })
    this.notificationService.getCount().subscribe(
      (res:any)=>{
        this.count = res?.data?.count
      }
    )
  }
  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;

    if(currentUrl.includes('?')){
      currentUrl = currentUrl.replace(this.previousStudentId, localStorage.selectedstudentid);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl(currentUrl);
      });
    }else{
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
      //localStorage.setItem("selectedstudentid", stuid);
      this.selectedstudentid=localStorage.selectedstudentid;
      //alert(this.selectedstudentid);
    }
    
  }

  public handleMissingImage(event: Event) {
    // (event.target as HTMLImageElement).style.display = 'none';
    (event.target as HTMLImageElement).src = '../../../assets/img/no-avatar.svg';
  }

  navigatetolink(lnk) {

    this.router.navigate([lnk]);
    this.dialog.closeAll()
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
  });
  }
  onImageError(){
    return "assets/images/social-icons/twitter.svg"
  }
  goToHome(){
    this.router.navigate(['/home']);
  }
  registerNow() {
    this.token = JSON.parse(localStorage.getItem('currentToken'))
    this.dashboardService.getContactDetails('').subscribe((res: any) => {
      this.contactDetails = res?.data;
      this.registerService.getParentsList(this.contactDetails?.primaryContactNumber, this.token).subscribe(res => {
        let parentDetails = res?.data;
        localStorage.removeItem('photopath')
        localStorage.removeItem('studentDetails');
        localStorage.setItem('parentDetails', JSON.stringify(parentDetails));
        localStorage.setItem('studParentDetails', JSON.stringify(parentDetails));
        this.registerService.sendStudentDetails.next(parentDetails);
        localStorage.setItem("proceed", "false");
        localStorage.setItem("showSchool", "true")
        this.router.navigate(['/schoolbus-registration'], { queryParams: { isFromDashboard: true, tab: 1 } });
      })
    })
  }
  activeItem: string;

  setActiveItem(page: string) {
    this.activeItem = page;
    //console.log(this.activeItem);
  }
  moreMenuDialog(template: TemplateRef<any>) {
    this.digopen = this.dialog.open(template, {
      disableClose: true,
      width: '500px',
      panelClass: 'mobile-menu-modalbox'
    });
    if (this.digopen['_overlayRef'].overlayElement) {
      this.digopen['_overlayRef'].overlayElement.parentElement.className += ' dialog-wrapper';
    }
  }
  close(){
    this.dialog.closeAll()
  }

  openPopup() {
    this.dialog.open(this.modalTemp1,{
      width: '500px',
      disableClose: true,
    });
  }
  checkAutoPromotion(){
    this.dashboardService.getAutoPromotionDetails().subscribe((res:any)=>{
      if(res?.data?.length > 0){
        let studentNameArr: any[] = [];

        let studentIds = res?.data?.map((y: any) => y.stU_NO);
        let autoPromotedChilds = this.childerns.filter((x:any) => studentIds.includes(x.studentId));
        autoPromotedChilds?.forEach((x:any) => {
          studentNameArr.push(x.studentName)
        });

        if(studentNameArr.length > 1){
          this.multipleChild = true;
        }

        this.studentNames = this.getStudentNames(studentNameArr);
        this.openPopup();
      }
    });
  }

  getStudentNames(students: string[]): string {
    const length = students.length;

    if (length === 0) return '';
    if (length === 1) return students[0];
    if (length === 2) return `${students[0]} and ${students[1]}`;

    const last = students.slice(0, -1).join(', ');
    const lastName = students[students.length - 1];
    return `${last}, and ${lastName}`;
  }

  navigatetoAutoPromotion(link) {
    this.dialog.closeAll();
    this.router.navigate([link]);
  }
  @Output() directionChange = new EventEmitter<string>();
  toggleDirection(direction: string) {
    direction === 'ltr' ? 'rtl' : 'ltr';
    // document.body = this.direction as any;
    this.directionChange.emit(direction);
  }

  // onLanguageChange(language: string){
  //   if (language === 'AR') {
  //     this.toggleDirection('rtl');
  // } else {
  //     this.toggleDirection('ltr');
  // }
  // }

  public languages: Array<{ title: string; value: string, iconName: string, className: string }> = [
    { title: 'English', value: 'en', iconName: 'en.svg', className: 'lang-eng' },
    { title: 'عربي', value: 'ar', iconName: 'ar.svg', className: 'lang-ar' },
];
language:any;

  public onLanguageChange(lang: any) {
    const svc: any = <LanguageTranslateService>this.messages;
    svc.language = lang;
    this.translate.use(lang);
    this.language = lang;
    localStorage.setItem("lang", lang);

    if (lang === 'ar') {
      this.toggleDirection('rtl');
    } else {
      this.toggleDirection('ltr');
    }
  }
}

