import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutTopNavComponent } from './layout-top-nav.component';
import { MatIconModule } from '@angular/material/icon';
import { FooterComponent } from '../footer/footer.component';
import {MatMenuModule} from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { interval } from 'rxjs';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '@progress/kendo-angular-l10n';
import { LanguageTranslateService } from 'src/app/service/translatelanguageservice.service';
@NgModule({
  declarations: [LayoutTopNavComponent, FooterComponent],
  exports: [LayoutTopNavComponent, FooterComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, 'assets/i18n/', '.json'),
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    })
  ],
  providers:[
    TranslateService,
    MessageService
  ]
})
export class LayoutTopNavModule { 
  constructor(private messages: MessageService,private translate:TranslateService){
    
    translate.addLangs(['en', 'ar']);
    interval(1000).subscribe(() => {
      let currentLanguage:any = localStorage.getItem('lang');
      currentLanguage?translate.use(currentLanguage):translate.setDefaultLang('en');
      const svc: any = <LanguageTranslateService>this.messages;
      svc.language = currentLanguage?currentLanguage:'en';
    });
    // setInterval(()=>{
    //   let rtlDirection:any = this.translate.instant('language.rtl');
    //   if(rtlDirection == 'true'){
    //       this.messages.notify(true);
    //   }else if(rtlDirection=='false'){
    //       this.messages.notify(false);
    //   }
    // },100);
  }
}
