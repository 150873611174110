import { HttpErrorResponse,HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { isValidNumber } from 'libphonenumber-js';
import { fromEvent, Subscription } from 'rxjs';
import { MessageComponent } from 'src/app/message/message.component';
import { RegistrationService } from 'src/app/service/registration.service';
import { TokenService } from 'src/app/service/token.service';
import { ConfigService } from 'src/app/service/config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  defaultToken: any;
  pdfContent: any;
  pdfHtml: string;
  contentTitle: string;
  studentGrade: string;
  isGrade: boolean;
  schoolDetails: string;
  carouselVisible: boolean = true;
  @ViewChild('owlElement') owlElement:any;
  digopen: any;

  constructor(private dialog: MatDialog, private router: Router, public regService: RegistrationService, private tokenService: TokenService,
    private sanitizer: DomSanitizer,public activatedRoute:ActivatedRoute,private configServe : ConfigService, private translate: TranslateService
    ,private http: HttpClient) { }
  checkTwo: boolean = false;
  checkThree: boolean = false;
  checkFour:boolean=false;
  city: any;
  checkYes: boolean = true;
  checkNo: boolean = false;
  successDialog: any;
  thankyouDialog: any;
  disableSave: boolean = true;
  tandcfilteredDatapdf:any;
  BusBehaviourCodedatapdf:any;
  parentUndertakingPdf:any;
  contentViewer:any;
  uaePasscontentViewer:any
  pdfs: any[] = [];
  referenceNumber:any;
  isTerms:boolean=false;
  isParentTaking:boolean=false;
  isBusBehavior:boolean=false;
  showParentUndertaking:boolean=false;
  @ViewChild('thankyouPopupTemplate') thankpop: TemplateRef<any>;
  //@ViewChild('contentViewer') contentViewer: ElementRef;
  //@Output() tabYes:EventEmitter<any>=new EventEmitter<any>();
  @Output() onTabChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() isAgree: boolean = false;
  disableAgree:boolean=false;
  @Input() isReactivate: boolean = false;
  isStudentReactivate: boolean = false;
  docdownloadurl:any;
  hasdownloadurl:boolean=false;
  langTrans: any;
  ngOnInit(): void {
   this.docdownloadurl =localStorage.getItem("docdownloadurl");

   if(this.docdownloadurl != undefined && this.docdownloadurl?.length>0)
   {
     this.hasdownloadurl=true;
   }
   else
   {
     this.hasdownloadurl=false;
   }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
  });
  this.activatedRoute.queryParams.subscribe(params=>{
    if(params)
    {
      let checksData = JSON.parse(localStorage.getItem("termsConditions"));
      if(checksData)
      {
      let data = {
        isAgree:params?.isAgreed=="true" ? true :false,
        checkTwo: checksData.checkTwo,
        checkThree: checksData.checkThree,
        checkFour :checksData.checkFour
      }

      let json = JSON.stringify(data)
      localStorage.setItem("termsConditions", json)
    }else{
      let data = {
        isAgree:params?.isAgreed=="true" ? true :false,
        checkTwo: false,
        checkThree: false,
        checkFour :false
      }

      let json = JSON.stringify(data)
      localStorage.setItem("termsConditions", json)
    }
    }
  })
    this.city = JSON.parse(localStorage.getItem("schoolDetails"))?.city;
    this.studentGrade=localStorage.getItem("studentGrade");
    let bsuId=localStorage.getItem("bsU_ID");
    let schoolList=JSON.parse(localStorage.getItem("schoolList"))
    this.schoolDetails=schoolList.find(x=>x.bsU_ID == bsuId);
    //this.isGrade= this.studentGrade.includes('01') || this.studentGrade.includes('02') || this.studentGrade.includes('03') || this.studentGrade.includes('KG') ? true : false
    //this.showParentUndertaking=(this.studentGrade.includes('01') && !this.studentGrade.includes('10') && !this.studentGrade.includes('11') && !this.studentGrade.includes('12') ) || (this.studentGrade.includes('2') && !this.studentGrade.includes('12')) || this.studentGrade.includes('3') || this.studentGrade.includes('4')|| this.studentGrade.includes('5')||this.studentGrade.includes('6')|| this.studentGrade.includes('Year 7')|| this.studentGrade.includes('KG')? true : false
    this.getToken();

    this.translate.stream('registration').subscribe(async (data: any) => {
      if (data != null && data != undefined) {
        this.langTrans = data.label;
      }
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateCarousel();
    }, 1000);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void{
    this.updateCarousel();
  }
  updateCarousel() {
    if (this.owlElement) {
      this.carouselVisible = false;
      setTimeout(() => {
        this.carouselVisible = true;
      }, 0)
    }
  }
  getToken() {
    this.tokenService.GetToken(null).subscribe(
      res => {
        this.defaultToken = res?.access_token;
        let checksData = JSON.parse(localStorage.getItem("termsConditions"));
        setTimeout(() => {
          this.getTermsConditions();
        }, 200);

        if (checksData != '' && checksData != null) {
          this.isAgree=checksData.isAgree;
          this.checkTwo = checksData.checkTwo;
          this.checkThree = checksData.checkThree;
          this.checkFour= checksData.checkFour;
          this.disableAgree=this.isAgree? true:false;
          if ((this.isAgree && this.checkTwo && this.checkThree) || (!this.isAgree && !this.isTerms && !this.isBusBehavior && this.checkTwo &&  this.checkThree))
          {
            if(!this.checkFour && this.city == 'Abu Dhabi' && this.isParentTaking && this.showParentUndertaking)
            this.disableSave = true;
            else this.disableSave = false;
          }
          else this.disableSave = true;
        }
      })
  }
  checked(isChecked: boolean, type: any) {
    localStorage.removeItem("termsConditions");
    if (type == 'one') {
      this.isAgree=isChecked;
      if (isChecked)
        setTimeout(() => {
          this.goToTc('one');
          this.disableAgree=true;
        }, 200);
    }
    else if(type == 'two')
    this.checkTwo=isChecked;
    else if(type == 'three')
    this.checkThree=isChecked;
    else {
      this.checkFour=isChecked;
      if(this.checkFour)
      setTimeout(() => {
        this.goToTc('four');
      }, 200);
    }

    if ((this.isAgree && this.checkTwo && this.checkThree) || (!this.isAgree && !this.isTerms && !this.isBusBehavior && this.checkTwo &&  this.checkThree))
          {
            if(!this.checkFour && this.city == 'Abu Dhabi' && this.isParentTaking && this.showParentUndertaking)
            this.disableSave = true;
            else this.disableSave = false;
          }
    else this.disableSave = true;
    let data = {
      isAgree:this.isAgree,
      checkTwo: this.checkTwo,
      checkThree: this.checkThree,
      checkFour :this.checkFour
    }
    let json = JSON.stringify(data)
    localStorage.setItem("termsConditions", json)
  }
  goToTc(type:any) {
    if(type=='one')
    this.router.navigate(['/terms-and-conditions'],{queryParams:{type:"All",isReactivate:this.isReactivate}});
    else this.router.navigate(['/terms-and-conditions'],{queryParams:{type:"PUT",isReactivate:this.isReactivate}});
  }
  proceed() {
    this.successDialog.close();
    if (this.checkYes) {
      //this.tabYes.emit(true);
      localStorage.setItem("proceed", "true");
      localStorage.setItem("showSchool", "true")
      let data = {
        isAgree:false,
        checkTwo: false,
        checkThree: false,
        checkFour :false
      }
      let json = JSON.stringify(data)
      localStorage.setItem("termsConditions", json)
      // this.goToTc()
      // this.onTabChange.emit(1)
      this.router.navigate(['/schoolbus-registration'],{queryParams:{tab:1,isAgreed:false,isReactivate:this.isReactivate}})
    }
    //else
  }
  radiobtn(event: any) {
    if (event?.value == '1') {
      this.checkYes = true;
      this.checkNo = false;
    }
    else {
      this.checkNo = true;
      this.checkYes = false;
      setTimeout(() => {
        this.successDialog.close();
        this.thankPopup(this.thankpop);
      }, 200);
    }
  }
  successPopup(template: TemplateRef<any>) {
    this.successDialog = this.dialog.open(template, {
      disableClose: true,
      width: '700px',
      panelClass: 'custom-modalbox'
    });
  }
  thankPopup(template: TemplateRef<any>) {
    this.thankyouDialog = this.dialog.open(template, {
      disableClose: true,
      width: '550px',
      panelClass: 'custom-modalbox'
    });
  }
  goToLanding() {
    this.thankyouDialog.close();
    let isLogin=localStorage.getItem("currentToken")
    if(!isLogin){
      let lang = localStorage.getItem("lang") != null ? localStorage.getItem('lang') : 'en';
      localStorage.clear();
      localStorage.setItem("lang", lang);
      let direction = lang == "en" ? 'ltr' : 'rtl';
      document.body.dir = direction;
    }
    this.router.navigate(['/home']);
  }

  gotoHomePage(){
    this.successDialog.close();
    this.router.navigate(['/home']);
  }

  cancel() {
    this.isAgree = false;
    this.checkTwo = false;
    this.checkThree = false;
    this.checkFour=false;
    this.disableAgree=false;
    let data = {
      isAgree:false,
      checkTwo: false,
      checkThree: false,
      checkFour :false
    }
    let json = JSON.stringify(data)
    localStorage.setItem("termsConditions", json)
  }
  openMessageDialog(msg: string, iseror: boolean, errormessage: string): void {
    const dialogRef = this.dialog.open(MessageComponent, {
      width: '500px',
      panelClass: 'custom-modalbox',
      data: {
        msg: msg,
        isError: iseror,
        errmessage: errormessage,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.goToLogin()
    });
  }

  cancelPopup() {
    this.router.navigate['/schools-list']
  }
  getTermsConditions() {

    this.regService.getTermsandConditions(this.defaultToken).subscribe(res => {

      this.pdfs = res?.data
      this.pdfs.forEach(x => x.isAgree = false);
      // Specify the value to filter by


      // Filter the array based on the specified property and value
   var vtermsac   = this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "TNC");
   if(vtermsac.length > 0)
     { this.tandcfilteredDatapdf =vtermsac.map(item => item.ttcul_Url_Path);
      this.isTerms=true
     }else this.isTerms=false

     var vbusb   =this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "BBC");
    if(vbusb.length >0)
     { this.BusBehaviourCodedatapdf = vbusb.map(item => item.ttcul_Url_Path);
      this.isBusBehavior=true
     }else this.isBusBehavior=false

      var parentUndertaking   =this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "PUT");
      var tpttype= JSON.parse( localStorage.parentDetails)[0].transportType
      this.isGrade=parentUndertaking[0]?.ttcuL_bShowSiblingInput;
    if(parentUndertaking.length>0 && tpttype!="1")
      {this.parentUndertakingPdf = parentUndertaking?.map(item => item?.ttcul_Url_Path);

        this.isParentTaking=true;
        this.showParentUndertaking=true;
      }else
      {
        this.isParentTaking=false;
        this.showParentUndertaking=false;
      }
    })
  }

  get sanitizedPdfHtml(): SafeHtml {
    this.pdfContent=this.sanitizer.bypassSecurityTrustHtml(this.pdfHtml ?? '');
    return this.pdfContent }
  openContent(type:any,template:any)
  {
    if(type == 't&c')
    {
      this.pdfHtml=this.pdfs.find(item => item.ttcuL_DOC_TYPE === "TNC").ttcuL_PDF_HTML;
      this.contentTitle= "Terms and Conditions"
    }
    else if(type == 'busbehaviour')
    {
    this.pdfHtml=this.pdfs.find(item => item.ttcuL_DOC_TYPE === "BBC").ttcuL_PDF_HTML;
    this.contentTitle= "Bus Behavior Code"
    }
    else if(type == 'parentUndertaking')
    {
      let parentUndertaking = this.pdfs.filter(item => item.ttcuL_DOC_TYPE === "PUT")
      let parentpdf:any
      if(parentUndertaking.length>1)
      {
      if(this.isGrade)
      {
        parentpdf=parentUndertaking?.find(x=> { return (x?.ttcuL_TYPE?.includes('01') ) || (x?.ttcuL_TYPE?.includes('02') ) || x?.ttcuL_TYPE?.includes('03') || x?.ttcuL_TYPE?.includes('KG')})
      }
      else{
        parentpdf=parentUndertaking.find(x=> { return !((x?.ttcuL_TYPE?.includes('01') ) || (x?.ttcuL_TYPE?.includes('02')) || x?.ttcuL_TYPE?.includes('03') || x?.ttcuL_TYPE?.includes('KG'))})
      }
    }else parentpdf=parentUndertaking[0];
    //this.pdfHtml=this.pdfs.find(item => item.ttcuL_DOC_TYPE === "Parent Undertaking").ttcuL_PDF_HTML;
    this.pdfHtml = parentpdf?.ttcuL_PDF_HTML;
   
    this.contentTitle= "Parent Undertaking"
    }
    this.contentViewer = this.dialog.open(template, {
      disableClose: true,
      width: '850px',

      height: '500px',
      panelClass: ['custom-modalbox', 'rm-padding']
    });
  }
  closeContent()
  {
    this.contentViewer?.close();
  }
  closeuaePasscontentViewer()
  {
    this.uaePasscontentViewer?.close();
  }
  saveRegistrationConfirm(template:any,stemplate:any)
  {
    this.saveRegistration(stemplate);
  //   if(!this.hasdownloadurl)
  //   {
  //  this.successPopup(template);
  //   }
  //   else
  //   {
  //     this.saveRegistration(stemplate);
  //   }
  }
  closesaveRegistrationConfirm()
  {
    this.successDialog.close();

  }
  saveRegistration(template: any){
    debugger;
   // this.successDialog.close();
    let isReactivate = JSON.parse(this.isReactivate.toString());
    if(isReactivate){
      this.isStudentReactivate = isReactivate;
      let parentData = JSON.parse(localStorage.getItem("parentDetails"));
      let studentData = JSON.parse(localStorage.getItem("studentDetails"));
      let selectedStudentId = JSON.parse(localStorage.getItem("selectedstudentid"));
      let index= parentData?.length == 1 ? 0 : parentData.findIndex((x:any)=>x.disableRegistration == 0)
      index = index == -1? 0:index;
      let formData = {
        "STU_NUMBER": selectedStudentId,
        "SBL_ID_PICKUP": parentData[index].pickupArea == "" ? 0 : parentData[index].pickupArea,
        "PICKUP": parentData[index].pickupPoint == "" ? 0 : parentData[index].pickupPoint,
        "SBL_ID_DROPOFF": parentData[index].SBL_ID_DROPOFF == "" ? 0 : parentData[index].SBL_ID_DROPOFF,
        "DROPOFF": parentData[index].PNT_ID_DROPOFFPOINT == "" ? 0 : parentData[index].PNT_ID_DROPOFFPOINT,
        "FROMDATE": studentData.busServiceRequestedDate,
        "TRD_ID_PICKUP": 0,
        "TRD_ID_DROPOFF": 0,
        "ROUNDTRIP": parentData[index].transportType,
        "RET_ADD_INFO": "",
        "DEVICE_SOURCE": 'STS_WEB',
        "SNR_PICKLAT": parentData[index].picklat,
        "SNR_PICKLONG": parentData[index].picklog,
        "SNR_DROPOFFLAT": parentData[index].droplat,
        "SNR_DROPOFFLONG": parentData[index].droplog,
        "SNR_EMIRATE": parentData[index].city,
        "SNR_HOUSENO": parentData[index].house,
        "SNR_ADDRESS": parentData[index].street,
        "SNR_PICKUP_LANDMARK": parentData[index].pickupPointLandMark,
        "SNR_DROPOFF_LANDMARK": parentData[index].dropoffPointLandMark
      }
      this.regService.saveReactivateRequest(formData, this.defaultToken).subscribe((res: any) => {
        if (res?.data != '') {
          this.referenceNumber=res?.data
          this.successPopup(template);
        }
        else {
          this.openMessageDialog(this.langTrans?.dataSavedFailed, true, "");
        }
      })
    }else{
      this.save(template);
    }
  }

  save(template: any) {
    debugger;
    this.disableSave = true;
    let parentData = JSON.parse(localStorage.getItem("parentDetails"));
    let studentData = JSON.parse(localStorage.getItem("studentDetails"));
    let acdId = localStorage.getItem("currentAcademicYearId")
    let bsuId = localStorage.getItem("bsU_ID");
    let schoolName = localStorage.getItem("schoolName");
    let mobile = localStorage.getItem("primaryMobileNo");
    let index= parentData?.length == 1 ? 0 : parentData.findIndex((x:any)=>x.disableRegistration == 0)
    index = index == -1? 0:index;
    let formData = {
      "BSU_ID": bsuId,
      "ACD_ID": acdId,
      "GRM_ID": studentData.stU_GRD_ID,
      "STU_FIRSTNAME": studentData.stU_FIRSTNAME,
      "STU_MIDNAME": studentData.stU_MIDNAME,
      "STU_LASTNAME": studentData.stU_LASTNAME,
      "STU_DOB": studentData.stU_DOB,
      "STU_GENDER": studentData.stU_GENDER,
      "STS_MED": studentData.STS_MED,
      "STU_MED_CONDITION": studentData.STU_MED_CONDITION,
      "STS_MED_STAFF_REQUIRED": studentData.STS_MED_STAFF_REQUIRED,
      "STS_MED_STAFF_REQUIRED_DESC": studentData.STS_MED_STAFF_REQUIRED_DESC,
      "STARTDATE": studentData.busServiceRequestedDate,
      "SBL_ID": parentData[index].pickupArea == "" ? 0 : parentData[index].pickupArea,
      "PNT_ID": parentData[index].pickupPoint == "" ? 0 : parentData[index].pickupPoint,
      "SBL_ID_DROPOFF": parentData[index].SBL_ID_DROPOFF == "" ? 0 : parentData[index].SBL_ID_DROPOFF,
      "PNT_ID_DROPOFFPOINT": parentData[index].PNT_ID_DROPOFFPOINT == "" ? 0 : parentData[index].PNT_ID_DROPOFFPOINT,
      "PRIMARYCONTACT": parentData[index].stU_PRIMARYCONTACT,
      "STU_SECONDARY_CONTACT": parentData[index].stU_SECONDARY_CONTACT,
      "TITLE": null,
      "PFIRSTNAME": parentData[index].stS_FFIRSTNAME,
      "PMIDNAME": parentData[index].stS_FMIDNAME,
      "PLASTNAME": parentData[index].stS_FLASTNAME,
      "POBOX": null,
      "EMIRATE": parentData[index].city,
      "HOUSE_NO": parentData[index].house,
      "ADDRESS": parentData[index].street,
      "OFFPHONE": null,
      "RESPHONE": null,
      "MOBILEPHONE": parentData[index].stS_FMOBILE,
      "MFIRSTNAME": parentData[index].stS_MFIRSTNAME,
      "MMIDNAME": parentData[index].stS_MMIDNAME,
      "MLASTNAME": parentData[index].stS_MLASTNAME,
      "BBT_MOTHER_MOBILE": parentData[index].stS_MMOBILE,
      "EMERGENCY_MOBILE": parentData[index].stU_SECONDARY_CONTACT=="F"?parentData[index].stS_FMOBILE:parentData[index].stU_SECONDARY_CONTACT=="M"?parentData[index].stS_MMOBILE : parentData[index].stS_GMOBILE,
      "EMAIL": parentData[index].stU_PRIMARYCONTACT=="F"?parentData[index].stS_FEMAIL: parentData[index].stU_PRIMARYCONTACT=="M"?parentData[index].stS_MEMAIL:parentData[index].stS_GEMAIL,
      "ALTEMAIL": null,
      "GFULLNAME": parentData[index].stS_GFULLNAME,
      "GMOBILE": parentData[index].stS_GMOBILE,
      "bSENDEMAIL": 0,
      "bSMS": 0,
      "PICKUPLOCATION": "",
      "DROPOFFLOCATION": "",
      "REGFROM": "",
      "OTHERPICKUP": parentData[index].pickupPointLandMark,
      "OTHERDROPOFF": parentData[index].dropoffPointLandMark,
      "IMAGE_LOC": studentData.stU_PHOTOPATH,
      "LAT": parentData[index].picklat + '||' + parentData[index].droplat,
      "LONG": parentData[index].picklog + '||' + parentData[index].droplog,
      "TPT_PROMO_ID": 0,
      "STU_SCHOOL_ID": studentData.stU_SCHOOL_ID,
      "TPT_TYPE": parentData[index].transportType,
      "STUTYPE": "",
      "SchoolName": schoolName,
      "PICKUPAREA": parentData[index].pickupArea,
      "PICKUPPOINT": parentData[index].pickupPoint,
      "DROPOFFAREA": parentData[index].dropoffArea,
      "DROPOFFPOINT": parentData[index].dropoffPoint,
      "Source":'STS_WEB',
      "BBT_LOGIN_USER":localStorage.getItem("loginuser")?localStorage.getItem("loginuser"):''
    }
    this.regService.save(formData, this.defaultToken).subscribe((res: any) => {
      this.disableSave = false;
      if (res != '0' && !isNaN(res)) {
        //   this.regService.getParentsList(mobile, this.defaultToken).subscribe(res=>{
        //   let parentDetails= res?.data;
        // localStorage.setItem('parentDetails',JSON.stringify(parentDetails));
        // localStorage.setItem('studParentDetails',JSON.stringify(parentDetails));
        // this.regService.sendStudentDetails.next(parentDetails);
        // this.successPopup(template);
        // })
        this.referenceNumber=res
        let parentInfo = {
          "stU_ID": 0,
          "stU_FIRSTNAME": '',
          "stU_MIDNAME": '',
          "stU_LASTNAME": '',
          "stU_GENDER": '',
          "stU_GRD_ID": '',
          "stU_DOB": '',
          "stU_SCHOOL_ID": '',
          "stU_PHOTOPATH": '',
          "stS_MED": 0,
          "stU_MED_CONDITION": '',
          "stS_MED_STAFF_REQUIRED": 0,
          "stS_MED_STAFF_REQUIRED_DESC": '',
          "stU_PRIMARYCONTACT": parentData[index].stU_PRIMARYCONTACT,
          "stS_FFIRSTNAME": parentData[index].stS_FFIRSTNAME,
          "stS_FMIDNAME": parentData[index].stS_FMIDNAME,
          "stS_FLASTNAME": parentData[index].stS_FLASTNAME,
          "stS_FMOBILE": parentData[index].stS_FMOBILE,
          "stS_FEMAIL": parentData[index].stS_FEMAIL,
          "stS_MFIRSTNAME": parentData[index].stS_MFIRSTNAME,
          "stS_MMIDNAME": parentData[index].stS_MMIDNAME,
          "stS_MLASTNAME": parentData[index].stS_MLASTNAME,
          "stS_MMOBILE": parentData[index].stS_MMOBILE,
          "stS_MEMAIL": parentData[index].stS_MEMAIL,
          "stS_GFULLNAME": parentData[index].stS_GFULLNAME,
          "stS_GMOBILE": parentData[index].stS_GMOBILE,
          "stS_GEMAIL": parentData[index].stS_GEMAIL,
          "stU_SECONDARY_CONTACT":parentData[index]?.stU_SECONDARY_CONTACT,
          "disableRegistration": 0,
          "city":  parentData[index]?.city ?? '',
          "house": parentData[index]?.house ?? '',
          "street": parentData[index]?.street ?? '',
          "transportType": parentData[index]?.transportType ?? '',
          "pickupArea": parentData[index]?.pickupArea ?? '',
          "pickupPoint": parentData[index]?.pickupPoint ?? '',
          "pickupPointLandMark": parentData[index]?.pickupPointLandMark ?? '',
          "dropoffArea": parentData[index]?.dropoffArea ?? '',
          "dropoffPoint": parentData[index]?.dropoffPoint ?? '',
          "dropoffPointLandMark": parentData[index]?.dropoffPointLandMark ?? '',
          "busServiceRequestedDate": 0,
          "picklat":parentData[index]?.picklat ?? 0,
          "picklog":parentData[index]?.picklog ?? 0,
          "droplat":parentData[index]?.droplog ?? 0,
          "droplog":parentData[index]?.droplog ?? 0,
          "stU_BSU_ID":bsuId,
          "SBL_ID_DROPOFF":parentData[index]?.DROPOFFAREA ?? 0,
          "PNT_ID_DROPOFFPOINT":parentData[index]?.DROPOFFPOINT?? 0,
        }
        localStorage.setItem('parentDetails', JSON.stringify([parentInfo]));
        localStorage.setItem('studParentDetails', JSON.stringify([parentInfo]));
        localStorage.removeItem('studentDetails');
        localStorage.removeItem("addSiblingFromParent")
        this.regService.sendStudentDetails.next([parentInfo]);
        this.successPopup(template);
        // this.regService.sendStudentDetails.next(parentData) // this line wil be replaced once after getting save details

      }
      else {
        if(res=="" || res=="0")
        {
        this.openMessageDialog(this.langTrans?.dataSavedFailed, true, "");
        }
        else{
          this.openMessageDialog(res, true, "");
        }
      }
      //localStorage.clear();
    },(err:HttpErrorResponse) => {
      this.disableSave = false;
      this.openMessageDialog(this.langTrans?.dataSavedFailed, true, "");
    })
  }
  signinwithuaepass()
  {
    localStorage.removeItem("fromReregister");

    window.location.href=this.configServe.uaepass_authorize_uri;
  }
  downloadsigneddocuments()
  {
    window.location.href=this.docdownloadurl;
  }

  openuaeDialog(template: TemplateRef<any>, value: any) {
   this.uaePasscontentViewer= this.dialog.open(template, {
      disableClose: true,
      width: '1040px',
      panelClass: ['custom-modalbox','uae-popup']
    });
  }

  downloadZipFile() {
    // Ensure the URL uses https
    let zipUrl = this.docdownloadurl;
  
    // Replace 'http' with 'https' if necessary
    if (zipUrl.startsWith('http://')) {
      zipUrl = zipUrl.replace('http://', 'https://');
    }
  
    // Fetch the file as a blob
    this.http.get(zipUrl, { responseType: 'blob' }).subscribe((blob) => {
      // Create a link element
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      
      // Set the href to the blob URL
      a.href = objectUrl;
      a.download = 'signedDocuments.zip';  // Name of the file to be downloaded
      a.click();
  
      // Clean up
      URL.revokeObjectURL(objectUrl);
    }, error => {
      console.error('Error downloading the file', error);
    });
  }
  
  

  carouselOptions: OwlOptions = {
    loop: false,
    items: 3,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    margin: 20,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      400: {
        items: 1,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      740: {
        items: 2,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      940: {
        items: 3,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      }
    },
    nav: false
  };
}
